export  function getImageStory(dbUser, story){

    return new Promise(async (resolve, reject) => {

        const bg = dbUser.userInfo.sex == 2 ? require('../img/bg_blue.png') : require('../img/bg_red.png');

        const logo = require('../img/logo.png');
        const down = require('../img/down.png');
        const icon = require('../img/' + story + '.png');

        const imagesURL = [
            {key: "icon", src: icon},
            {key: "bg", src: bg},
            {key: "logo", src: logo},
            {key: "down", src: down},
        ];

        let imagesTotal = []
        if (dbUser.images.other && dbUser.images.other.length > 0) {
            imagesTotal = Array.from(dbUser.images.other.filter(item => item), item => item.image_uri)
        }
        const imagesUser = [...Array(dbUser.images.avatar.main), ...imagesTotal]

        const images = Object();
        let imageCount = 0;

        const scheme = document.body.getAttribute('scheme');
        const bgColor = scheme == 'bright_light' ? '#fff' : '#000';
        const textBg =  scheme == 'bright_light' ? '#fff' : '#28292B';
        const textColor = scheme == 'bright_light' ? '#767676' : '#fff';
        const userColor = dbUser.userInfo.sex == 2 ? '#528bcc' : '#f32e62';

        let text = '';
        switch (story) {
            case 'relation':
                text = 'серьёзных отношений'
                break;
            case 'communication':
                text = 'пообщаться'
                break;
            case 'car':
                text = 'прокатиться сегодня!'
                break;
            case 'come':
                text = 'прогуляться сегодня!'
                break;
            case 'film':
                text = 'сходить в кино!'
                break;
            case 'restaurant':
                text = 'сходить в заведение'
                break;
            default:
                text = ''
        }

        const toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))

        await toDataURL(dbUser.images.avatar.main)
            .then(dataUrl => {
                imagesURL.push(
                    {key: "ava", src: dataUrl}
                )
            })


        const canvasElem = document.createElement('canvas')

        canvasElem.width = 1080;
        canvasElem.height = 1920;

        imagesURL.forEach((img) => {  // for each image url
            let image = new Image();
            image.src = img.src;
            image.crossOrigin = '*';

            image.onload = () => {
                imageCount += 1;

                images[img.key] = image; // add loading image to images array
                if (imageCount === imagesURL.length) { // have all loaded????
                    if (canvasElem.getContext) {
                        let ctx = canvasElem.getContext("2d");

                        //фончик
                        ctx.fillStyle = bgColor;
                        ctx.fillRect(0, 0, 1080, 1920);

                        ctx.drawImage(images.bg, 0, 0); //bg

                        if (!ctx.constructor.prototype.fillRoundedRect) {
                            ctx.constructor.prototype.fillRoundedRect =
                                function (xx, yy, ww, hh, rad, fill, stroke) {
                                    if (typeof (rad) == "undefined") rad = 5;
                                    this.beginPath();
                                    this.moveTo(xx + rad, yy);
                                    this.arcTo(xx + ww, yy, xx + ww, yy + hh, rad);
                                    this.arcTo(xx + ww, yy + hh, xx, yy + hh, rad);
                                    this.arcTo(xx, yy + hh, xx, yy, rad);
                                    this.arcTo(xx, yy, xx + ww, yy, rad);
                                    if (stroke) this.stroke();  // Default to no stroke
                                    if (fill || typeof (fill) == "undefined") this.fill();  // Default to fill
                                    this.closePath();
                                    return this;
                                };
                        }

                        //Прямоугольник сверху
                        ctx.beginPath();
                        ctx.fillStyle = textBg;
                        ctx.shadowOffsetX = 0;
                        ctx.shadowOffsetY = 0;
                        ctx.shadowBlur = 30;
                        ctx.shadowColor = "gray";
                        ctx.strokeStyle = textBg;
                        ctx.fillRoundedRect(150, 150, 830, 200, 35);
                        ctx.stroke();

                        //Надпись
                        ctx.shadowColor = "transparent";
                        ctx.fillStyle = userColor;
                        ctx.font = 'bold 46px sans-serif';

                        ctx.textBaseline = "bottom";

                        ctx.textAlign = "center";
                        ctx.fillText("Хочу", (canvasElem.width / 2) + 150, 250);

                        ctx.textBaseline = "top";
                        ctx.fillText(text, (canvasElem.width / 2) + 150, 250);

                        //круг слева
                        ctx.beginPath();
                        ctx.shadowColor = "gray";
                        ctx.arc(250, 250, 150, 0, 2 * Math.PI, false);
                        ctx.fillStyle = userColor;
                        ctx.fill();
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = userColor;
                        ctx.stroke();

                        //Изображения
                        ctx.drawImage(images.icon, 200, 200, 100, 100);

                        //обрезаем фото
                            let width = images.ava.width;
                            let height = images.ava.height;

                            ctx.beginPath();
                            ctx.shadowColor = "gray";
                            ctx.shadowBlur = 30;
                            ctx.arc((canvasElem.width / 2), (canvasElem.height / 2) - 100, 300, 0, 2 * Math.PI, false);
                            ctx.fillStyle = 'gray';
                            ctx.fill();
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = userColor;
                            ctx.stroke();

                            ctx.save();
                            ctx.clip();

                            let scale = Math.max(600 / width, 600 / height);
                            ctx.drawImage(images.ava, (canvasElem.width / 2) - 300, (canvasElem.height / 2) - 400, width * scale, height * scale);
                            ctx.restore();
                        //конец обрезки

                        //Прямоугольник снизу
                        let positionRec = (canvasElem.height / 2) + 400
                        ctx.beginPath();
                        ctx.strokeStyle = textBg; //цвет линии
                        ctx.fillStyle = textBg;
                        ctx.shadowOffsetX = 0;
                        ctx.shadowOffsetY = 0;
                        ctx.shadowBlur = 30;
                        ctx.shadowColor = "gray";
                        ctx.fillRoundedRect(100, positionRec, canvasElem.width - 200, 500, 35);
                        ctx.stroke();

                        //надписи серые
                        ctx.shadowColor = "transparent";
                        ctx.fillStyle = textColor;
                        ctx.font = 'bold 36px sans-serif';

                        ctx.textBaseline = "top";
                        ctx.textAlign = "left";

                        let cityText = dbUser.userInfo.city_title;
                        const widthCity = ctx.measureText(cityText).width
                        const maxWidth = (canvasElem.width - 375) / 2
                        //проверим ширину города если больше половины то поставим точки
                        if(widthCity > maxWidth){
                            let charWidth = widthCity / cityText.length
                            let charTotal = parseInt(maxWidth / charWidth) + 3
                            cityText = cityText.slice(0,-(cityText.length - charTotal)) + '...'
                        }

                        ctx.fillText(cityText, 115, positionRec + 20);

                        let textRight = canvasElem.width - 115

                        ctx.textAlign = "right";
                        ctx.fillText(dbUser.userInfo.condition_title, textRight, positionRec + 20);

                        ctx.font = 'bold 46px sans-serif';
                        ctx.fillText("Ищу " + (dbUser.userInfo.find_sex === 2 ? 'парня' : 'девушку') + " от " + dbUser.userInfo.age_start + " до " + dbUser.userInfo.age_end, textRight, positionRec + 175);


                        ctx.textAlign = "left";
                        ctx.fillText(imagesUser.length + " фото", 115, positionRec + 175);

                        ctx.fillStyle = userColor;

                        ctx.drawImage(images.logo, 115, positionRec + 250, 105, 91);

                        ctx.textAlign = "right";
                        ctx.fillText("Пишите мне, перейдя по ссылке", textRight, positionRec + 280);

                        ctx.textAlign = "left";
                        ctx.font = 'bold 62px sans-serif';
                        ctx.fillText(dbUser.userInfo.first_name + ', ' + dbUser.userInfo.age, 115, positionRec + 90);

                        ctx.textAlign = "center";
                        ctx.drawImage(images.down, (canvasElem.width / 2) - 85, canvasElem.height - 175, 117, 103);

                        resolve(canvasElem.toDataURL("image/png"))
                    }
                }
            }
        })
    })
}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
  }));

export  const getImageStoryOpinion = (dbUser, text) => {
    return new Promise(async (resolve, reject) => {
        const images = Object();
        const canvasElem = document.createElement('canvas')
        const gradientColor = {
            start: parseInt(dbUser.userInfo.sex) === 2 ? '#7593D0' : '#D37678',
            finish: parseInt(dbUser.userInfo.sex) === 2 ? '#0A3371' : '#730909',
        }

        const src = dbUser.userInfo.image;
        const like_image = require('../img/story/like.svg');
        const down_image = require('../img/story/down.svg');

        const avatar = await toDataURL(src)
        const imagesURL = [
            { key: "like_image", src: like_image },
            { key: "down_image", src: down_image },
            { key: "avatar_image", src: avatar },
        ];

        let imageCount = 0;

        canvasElem.width = 1080;
        canvasElem.height = 1920;

        const centerX = canvasElem.width / 2;
        const centerY = canvasElem.height / 2;

        imagesURL.forEach((img) => {
            let image = new Image();
            image.src = img.src;
            image.crossOrigin = '*';

            image.onload = () => {
                imageCount += 1;
                images[img.key] = image;
                if (imageCount === imagesURL.length) {
                    if (canvasElem.getContext) {

                        let ctx = canvasElem.getContext("2d");

                        if (!ctx.constructor.prototype.fillRoundedRect) {
                            ctx.constructor.prototype.fillRoundedRect =
                              function (xx, yy, ww, hh, rad, fill, stroke) {
                                  if (typeof (rad) == "undefined") rad = 5;
                                  this.beginPath();
                                  this.moveTo(xx + rad, yy);
                                  this.arcTo(xx + ww, yy, xx + ww, yy + hh, rad);
                                  this.arcTo(xx + ww, yy + hh, xx, yy + hh, rad);
                                  this.arcTo(xx, yy + hh, xx, yy, rad);
                                  this.arcTo(xx, yy, xx + ww, yy, rad);
                                  if (stroke) this.stroke();  // Default to no stroke
                                  if (fill || typeof (fill) == "undefined") this.fill();  // Default to fill
                                  this.closePath();
                                  return this;
                              };
                        }

                        let gradient = ctx.createLinearGradient(0, 0, 1080, 1920);
                        gradient.addColorStop(0, gradientColor.start);
                        gradient.addColorStop(1, gradientColor.finish);
                        ctx.fillStyle = gradient;
                        ctx.fillRect(0, 0, 1080, 1920);

                        ctx.fillStyle = '#FFFFFF';
                        ctx.font = 'bold 68px amavickfont';
                        ctx.textAlign = 'center';

                        ctx.textBaseline = "bottom";
                        ctx.fillText(text.title_top, centerX, 250);
                        ctx.textBaseline = "top";
                        ctx.fillText(text.title_bottom, centerX, 250);

                        ctx.textBaseline = "bottom";

                        ctx.font = 'bold 54px amavickfont';
                        ctx.textAlign = 'center';
                        ctx.fillText('Отправь', centerX, 1515);
                        ctx.fillText(text.description, centerX, 1575);

                        ctx.drawImage(images.like_image, centerX - 400, 1450, 150, 150);
                        ctx.drawImage(images.down_image, centerX + 200, 1480, 150, 150);

                        //обрезаем фото аватарку
                        let width = images.avatar_image.width;
                        let height = images.avatar_image.height;
                        let scale = 800 / Math.min(width, height);

                        ctx.beginPath();
                        ctx.shadowColor = "#ffffff";
                        ctx.shadowBlur = 30;
                        ctx.arc(centerX, centerY - 100, 400, 0, 2 * Math.PI, false);
                        ctx.fillStyle = '#ffffff';
                        ctx.fill();
                        ctx.lineWidth = 20;
                        ctx.strokeStyle = '#ffffff';
                        ctx.stroke();
                        ctx.save();
                        ctx.clip();

                        ctx.drawImage(images.avatar_image, centerX - ((width * scale) / 2), centerY - ((height * scale) / 2) - 100, width * scale, height * scale);
                        ctx.restore();
                        //конец обрезки

                        resolve(canvasElem.toDataURL("image/png"));
                    }
                }
            }
        });
    });
}
