import { setActivePage, setEditData, setUserData } from "../../actions/actions";
import {
	Button,
	Div,
	FormItem,
	FormLayoutGroup,
	Group,
	Input,
	Radio,
	Select,
	SelectMimicry,
	Title,
} from "@vkontakte/vkui";
import InputMask from "react-input-mask";
import { ServiceConstants } from "../../constants/ServiceConstants";
import RangeSlider from "../RangeSlider";
import Icon24UserAdd from "@vkontakte/icons/dist/24/user_add";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHandBooks } from "../RequestApi";
import bridge from "@vkontakte/vk-bridge";
import ImagesUserListRegister from "../ImagesUserListRegister";

const ValidatorMain = require("jsonschema").Validator;
const validator = new ValidatorMain();
const editScheme = {
	type: "object",
	required: ["age", "condition_id", "city_id", "country_id"],
	properties: {
		height: {
			anyOf: [
				{
					type: "null",
				},
				{
					type: "integer",
					minimum: 50,
					maximum: 250,
				},
			],
		},
		condition_id: {
			anyOf: [
				{
					not: {
						type: "null",
					},
				},
				{
					type: "integer",
					minimum: 1,
				},
			],
		},
		age: {
			type: "integer",
			minimum: 18,
			maximum: 90,
		},
		city_id: {
			type: "integer",
			minimum: 1,
		},
		country_id: {
			type: "integer",
			minimum: 1,
		},
	},
};

export const RegisterForm = ({
																	setAlertDefault,
																	setAlertImagesError,
																	sendData,
																}) => {
	const dispatch = useDispatch();
	const { editData, fetchedUser } = useSelector((state) => state);
	const [count, setCount] = useState(0);

	const [formValid, setFormValid] = useState({
		first_name: "default",
		age:
			editData.userInfo.age &&
			editData.userInfo.age > 17 &&
			editData.userInfo.age < 91
				? "valid"
				: "error",
		sex: "default",
		city: {
			id: fetchedUser.city.id !== 0 ? "valid" : "default",
		},
		condition_id: editData && editData.userInfo.condition_id ? "valid" : "error",
		height: "default",
	});
	const [handbooks, setHandbooks] = useState(null);

	const changeFindAge = useCallback(
		(e) => {
			const start = e[0];
			const end = e[1];
			dispatch(
				setEditData({
					...editData,
					userInfo: {
						...editData.userInfo,
						find_age_start: start,
						find_age_end: end,
					},
				})
			);
		},
		[editData]
	);

	const validate = useCallback(
		async (input = false) => {
			let dataInput = null;
			let dataValid = {};
			if (input) {
				dataInput = {
					[input.name]: input.value,
				};
				dataValid[input.name] = "valid";
			} else {
				dataInput = editData.userInfo;
			}
			const valid = validator.validate(dataInput, editScheme);

			await valid.errors.some((item) => {
				let name = null;
				if (item.property.indexOf("instance.") !== -1) {
					name = item.property.replace("instance.", "");
				}
				if (item.name === "required" && !input) {
					name = item.argument;
				}
				if (name) {
					let last;
					let array = name.split(".");
					let obj = array.reduce((o, val) => {
						if (array.length > 1) {
							if (typeof last == "object") last = last[val] = "error";
							else last = o[val] = {};
						} else {
							last = o[val] = "error";
						}
						return o;
					}, {});
					dataValid = Object.assign(dataValid, obj);
				}
			});
			setFormValid((formValid) => Object.assign(formValid, dataValid));
			setCount((count) => count + 1);
			return valid.valid;
		},
		[formValid, editData]
	);

	const getImagesList = useCallback(
		async (index, isMain) => {
			bridge
				.send("VKWebAppGetAuthToken", {
					app_id: parseInt(process.env.REACT_APP_APP_ID),
					scope: "photos",
				})
				.then((result) => {
					if (result.access_token) {
						dispatch(
							setUserData({
								...fetchedUser,
								access_token: result.access_token,
								imagesEditData: {
									index: index,
									isMain: isMain,
									isEdit: true,
									prevPageData: {
										historyDelete: true,
										activeView: 'viewRegister',
										activePanel: 'register'
									},
								},
							})
						);
						dispatch(
							setActivePage({
								historyAdd: true,
								activeView: "viewImagesList",
								activePanel: "imagesList",
							})
						);
					}
				})
				.catch((er) => {
					console.log(er);
				});
		},
		[fetchedUser]
	);

	const changeText = useCallback(
		(e) => {
			const name = e.currentTarget.name;
			const value = e.currentTarget.value.replace(
				/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
				""
			)
				? parseInt(e.currentTarget.value)
				: null;
			validate({
				name: name,
				value: value,
			});
			dispatch(
				setEditData({
					...editData,
					userInfo: {
						...editData.userInfo,
						[name]: value,
					},
				})
			);
		},
		[editData, formValid]
	);

	const addCondition = useCallback(
		(e) => {
			dispatch(
				setEditData({
					...editData,
					userInfo: {
						...editData.userInfo,
						[e.group]: e,
					},
				})
			);
			validate({
				name: e.group,
				value: e,
			});
		},
		[editData]
	);

	const getCountryModal = () => {
		dispatch(
			setActivePage({
				historyAdd: true,
				activeView: "viewCountry",
				activePanel: "county",
			})
		);
	};

	const getCityModal = () => {
		dispatch(
			setActivePage({
				historyAdd: true,
				activeView: "viewCountry",
				activePanel: "GetCityUserEdit",
			})
		);
	};

	const validateImages = useCallback(async () => {
		return await bridge
			.send("VKWebAppGetAuthToken", {
				app_id: parseInt(process.env.REACT_APP_APP_ID),
				scope: "photos",
			})
			.then((result) => {
				if (result.access_token) {
					dispatch(
						setUserData({
							...fetchedUser,
							access_token: result.access_token,
						})
					);
					return bridge
						.send("VKWebAppCallAPIMethod", {
							method: "photos.get",
							params: {
								album_id: "profile",
								owner_id: fetchedUser.id,
								count: 20,
								v: ServiceConstants.VERSION_API,
								rev: 1,
								access_token: result.access_token,
							},
						})
						.then((resultImages) => {
							if (
								resultImages.response.items &&
								resultImages.response.count > 0
							) {
								if (
									editData.images.avatar.main &&
									editData.images.avatar.preview &&
									editData.images.avatar.image_id
								) {
									return result.access_token;
								} else {
									setAlertDefault({
										alertTitle: "Форма содержит ошибки!",
										alertText: "Для продолжения загрузите главное фото!",
									});
								}
							} else {
								setAlertImagesError();
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			})
			.catch((er) => {
				console.log(er);
			});
	}, [fetchedUser, editData]);

	const saveData = async () => {
		const validateImageToken = await validateImages();
		if (validateImageToken) {
			const valid = await validate();
			if (valid) {
				sendData();
			} else {
				setAlertDefault({
					alertTitle: "Форма содержит ошибки!",
					alertText: "Проверьте заполненные поля.",
					buttonTitle: "Закрыть",
				});
			}
		}
	};

	useEffect(() => {
		getHandBooks(fetchedUser.id)
			.then((result) => {
				setHandbooks(result);
			})
			.catch((err) => console.log(err));
	}, []);

	if (!editData || !handbooks) {
		return "";
	}
	return (
		<Group>
			<ImagesUserListRegister
				images={editData.images}
				getImagesList={(index, isMain) => getImagesList(index, isMain)}
				setUserImages={(data) => {
					dispatch(
						setEditData({
							...editData,
							images: data,
						})
					);
				}}
			/>
			<FormItem
				top="Ваше имя"
				status={
					editData.userInfo.first_name || formValid.first_name
						? "valid"
						: "error"
				}
				bottom={
					editData.userInfo.first_name || formValid.first_name
						? ""
						: "Заполните обязательное поле"
				}
			>
				<Input
					type="text"
					name="first_name"
					disabled={editData.userInfo.first_name && true}
					value={editData.userInfo.first_name}
					onChange={(e) => changeText(e)}
				/>
			</FormItem>
			<FormItem
				top="Ваш возраст (18 - 90)"
				status={formValid.age}
				bottom={formValid.age === "error" ? "Некорректный возраст" : ""}
			>
				<InputMask
					name="age"
					mask="99"
					value={editData.userInfo.age || null}
					onChange={(e) => changeText(e)}
				>
					{(inputProps) => <Input {...inputProps} />}
				</InputMask>
			</FormItem>
			<FormItem
				top="Выберите страну"
				bottom={editData.userInfo.country_id === 0 ? "Выберите страну" : ""}
				status={editData.userInfo.country_id === 0 ? "error" : "success"}
			>
				<SelectMimicry
					placeholder="Не выбрана"
					onClick={() => getCountryModal()}
				>
					{editData.userInfo.country_title || editData.userInfo.country_title}
				</SelectMimicry>
			</FormItem>
			<FormItem
				bottom={editData.userInfo.city_id === 0 ? "Выберите город" : ""}
				status={editData.userInfo.city_id === 0 ? "error" : "success"}
				top="Выберите город"
			>
				<SelectMimicry placeholder="Не выбран" onClick={() => getCityModal()}>
					{editData.userInfo.city_title || editData.userInfo.city_title}
				</SelectMimicry>
			</FormItem>
			{handbooks && handbooks.conditions && (
				<FormItem
					top="Цель знакомства"
					bottom={formValid.condition_id === "error" ? "Выберите цель!" : ""}
					status={formValid.condition_id}
				>
					<Select
						placeholder="Выберите"
						name="condition_id"
						onChange={(e) => changeText(e)}
						value={editData.userInfo.condition_id}
						options={handbooks.conditions.map((item) => ({
							value: item.id,
							label: item.title,
						}))}
					/>
				</FormItem>
			)}

			<div
				style={{
					padding: "1rem",
					margin: "1em",
					borderRadius: "1rem",
					background: "var(--panel_tab_active_background)",
				}}
			>
				<Title>Кого хотите найти ?</Title>

				<FormLayoutGroup mode="vertical">
					<FormItem top="Пол" status={formValid.find_sex}>
						<Select
							name="find_sex"
							value={editData.userInfo.find_sex || 1}
							onChange={(e) => changeText(e)}
							options={ServiceConstants.SEX_OPTIONS}
						/>
					</FormItem>

					<FormItem top="Выберите возраст">
						<RangeSlider
							userAge={[
								editData.userInfo.find_age_start || 18,
								editData.userInfo.find_age_end || 32,
							]}
							setAge={(e) => changeFindAge(e.data)}
						/>
					</FormItem>
				</FormLayoutGroup>
			</div>

			<FormItem top="Ваш пол" status={fetchedUser.sex}>
				<Radio
					defaultChecked={editData.userInfo.sex === 2}
					disabled={editData.userInfo.sex ? true : false}
					value={2}
					name="sex"
				>
					Муж.
				</Radio>
				<Radio
					defaultChecked={editData.userInfo.sex === 1}
					disabled={editData.userInfo.sex ? true : false}
					value={1}
					name="sex"
				>
					Жен.
				</Radio>
			</FormItem>
			<Div>
				<Button
					stretched
					size="l"
					onClick={saveData}
					before={<Icon24UserAdd />}
				>
					Зарегистрироваться
				</Button>
			</Div>
		</Group>
	);
};
