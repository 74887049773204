import React, { useCallback, useEffect, useState } from "react";
import {
	PanelHeader,
	Header,
	SimpleCell,
	Avatar,
	Panel,
	PanelHeaderBack,
	Snackbar,
	PanelSpinner,
	Group, List
} from "@vkontakte/vkui";
import { useDispatch, useSelector } from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import { setGroupBoot, updateGroupBoot } from "./RequestApi";

import Icon20Info from '@vkontakte/icons/dist/20/info';
import Icon20CheckBoxOn from '@vkontakte/icons/dist/20/check_box_on';
import { setUserGroups } from "../actions/actions";
import { ServiceConstants } from "../constants/ServiceConstants";

const UIDGenerator = require('uid-generator');
const uidGen = new UIDGenerator();

export const BootGroupsList = ({id, go}) => {
	const { userGroups, fetchedUser } = useSelector(state => state);
	const [snackbar,setSnackbar] = useState(null);
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const getGroups = useCallback(() => {
		bridge.send('VKWebAppGetAuthToken',{"app_id": parseInt(process.env.REACT_APP_APP_ID), "scope": "groups"})
			.then(async result => {
				if(result.access_token){
					await bridge.send('VKWebAppCallAPIMethod', {
						"method": "groups.get",
						"params":
							{
								"user_id": fetchedUser.id,
								"v": ServiceConstants.VERSION_API,
								"extended" : 1,
								"filter": 'admin',
								"access_token": result.access_token
							}
					}).then(async (result) => {
						if(result.response.count > 0){
							if(userGroups.length > 0){
								const groupsData = result.response.items.filter((item) => {
										let search = userGroups.some((itemUser) =>  itemUser.group_id === item.id)
										return !search;
									}
								);
								setGroups(groupsData);
							} else {
								setGroups(result.response.items);
							}
						}
					})
				}
			})
			.catch(() => {
				openSnack({
					type: 'danger',
					text: 'Произошла ошибка'
				});
			});
	},[userGroups, groups]);

	const openSnack = useCallback((snack) => {
		if(snackbar)return;
		setSnackbar(
			<Snackbar
				layout="vertical"
				duration={2000}
				onClose={() => setSnackbar(null )}
				before={snack.type === 'danger' ? <Icon20Info fill="var(--like_text_tint)" />  : <Icon20CheckBoxOn fill="var(--field_valid_border)" />}
			>
				{snack.text}
			</Snackbar>
		);
	},[snackbar, loading]);

	const addGroupLoading = async (group) => {
		setLoading(true);
		await addGroup(group);
		setLoading(false);
	}

	const addGroup = async (group) => {
		const token =  await bridge.send("VKWebAppGetCommunityToken",
			{"app_id": parseInt(process.env.REACT_APP_APP_ID), "group_id": group.id, "scope": "messages,manage,photos"})
			.catch(() => {
				openSnack({text: "Произошел сбой, попробуйте еще раз.", type: 'danger'});
				return false;
			});

		if(token.access_token){
			const access_token = token.access_token;
			const uid = await uidGen.generate();

			const members = await bridge.send('VKWebAppCallAPIMethod', {
				"method": "groups.getMembers",
				"params":
					{
						"group_id": group.id,
						"v": ServiceConstants.VERSION_API,
						"access_token": access_token
					}
			})

			const code = await bridge.send('VKWebAppCallAPIMethod', {
				"method": "groups.getCallbackConfirmationCode",
				"params":
					{
						"group_id": group.id,
						"v": ServiceConstants.VERSION_API,
						"access_token": access_token
					}
			})

			if(code.response && code.response.code){
				const data = {
					user_id: fetchedUser.id,
					group_id: group.id,
					secret_key: uid,
					access_token: access_token,
					status: 'await',
					total_users: members.response.count,
					code_answer: code.response.code
				}
				await setGroupBoot(data)
					.then(async (r) => {
						if(r.insertId)
						{
							const server = await bridge.send('VKWebAppCallAPIMethod', {
								"method": "groups.addCallbackServer",
								"params":
									{
										"group_id": group.id,
										"v": ServiceConstants.VERSION_API,
										"url": process.env.REACT_APP_URL_BOOT,
										"secret_key": uid,
										"title": "Chat-boot_v1",
										"access_token": access_token
									}
							})
								.catch((error) => {
									console.log('CServer', error);
								});

							if(server.response && server.response.server_id)
							{

								const serverInfo = await bridge.send('VKWebAppCallAPIMethod', {
									"method": "groups.getCallbackServers",
									"params":
										{
											"group_id": group.id,
											"v": ServiceConstants.VERSION_API,
											"server_ids": server.response.server_id,
											"access_token": access_token
										}
								}).catch((error) => {
									console.log('Cservers', error);
								});

								if(serverInfo.response.items)
								{
									const validate = await serverInfo.response.items.some((item) => item.id === server.response.server_id);
									if(validate){
										const editServerSettings = await bridge.send('VKWebAppCallAPIMethod', {
											"method": "groups.setCallbackSettings",
											"params":
												{
													"group_id": group.id,
													"api_version": ServiceConstants.VERSION_API,
													"v": ServiceConstants.VERSION_API,
													"server_id": server.response.server_id,
													"message_new": 1,
													"access_token": access_token
												}
										}).catch((error) => {
											console.log('CSettings', error);
										});

										const editGroupSettings = await bridge.send('VKWebAppCallAPIMethod', {
											"method": "groups.setSettings",
											"params":
												{
													"group_id": group.id,
													"messages": 1,
													"bots_capabilities": 1,
													"bots_start_button": 1,
													"access_token": access_token,
													"v": ServiceConstants.VERSION_API,
												}
										}).catch((error) => {
											console.log('Set Settings', error);
										});

										if(editServerSettings.response == 1 && editGroupSettings.response == 1){
											await updateGroupBoot({server_id: server.response.server_id, status: 'success'}, r.insertId)
												.then((resultUpdate) => {
													if(resultUpdate){
														openSnack({text: "Чат-бот добавлен в группу."})

														data.id = r.insertId;
														data.server_id = server.response.server_id;
														data.status = 'success';

														dispatch(setUserGroups(userGroups.filter((item) => item.id !== group.id)));
														setGroups(groups.filter((item) => item.id != group.id));
													}
												}).catch((error) => {
													console.log('UP:', error);
												});
										}
									}
								}
							}
						} else if(r.find){
							openSnack({text: "Чат-бот уже добавлен в группу.", type: 'danger'})
						}
					})
			}
		}
	}

	useEffect(() => {
		getGroups();
		return () => {
			setGroups([]);
		}
	},[]);

	return(
		<Panel id={id}>
			<PanelHeader
				left={<PanelHeaderBack onClick={!loading && go} data-prev={true} />}>
				Сообщества
			</PanelHeader>
			{
				loading ?
					<PanelSpinner height={150} size="medium" />
					:
					(
						<React.Fragment>
							{
								groups.length > 0 &&
								<Group>
									<Header mode="secondary">Список сообществ</Header>
									<List>
										{
											groups.map((item,i) => (
												<SimpleCell
													className="text-left"
													key={i}
													onClick={() => addGroupLoading(item)}
													before={<Avatar size={48} src={item.photo_100} />}
													description={item.screen_name ? `https://vk.com/${item.screen_name}` : ''}>
													{item.name}
												</SimpleCell>
											))
										}
									</List>
								</Group>
							}
							{snackbar}
						</React.Fragment>
					)
			}
		</Panel>
	)
}
