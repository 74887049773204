import React, { useEffect, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import FsLightbox from "fslightbox-react";
import { Swipeable } from "react-swipeable";
import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Group,
  List,
  Avatar,
  Snackbar,
  PanelHeaderContext,
  Header,
  CardGrid,
  Card,
  Radio,
  Spinner,
  Placeholder,
  Gradient,
  Title,
  IconButton,
  InfoRow,
  MiniInfoCell,
  SimpleCell,
  FormItem, Link
} from "@vkontakte/vkui";
import Icon56InfoOutline from "@vkontakte/icons/dist/56/info_outline";
import Icon16Like from "@vkontakte/icons/dist/16/like";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import Icon28InboxOutline from "@vkontakte/icons/dist/28/inbox_outline";
import Icon28ArrowUpOutline from "@vkontakte/icons/dist/28/arrow_up_outline";
import Icon28LogoVk from "@vkontakte/icons/dist/28/logo_vk";
import Icon56HideOutline from "@vkontakte/icons/dist/56/hide_outline";
import { setActiveRoom, setActivePage } from "../actions/actions";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import {
  getUser,
  getUserHref,
  sendGuest,
  sendLike,
  sendNotification,
  setComplaint
} from "../components/RequestApi";
import { vipConstants } from "../constants/vipConstants";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import { Icon28AdvertisingOutline } from "@vkontakte/icons";
import { Icon36Like } from "@vkontakte/icons";
import { Icon28Message } from "@vkontakte/icons";
import { Icon20AccessibilityOutline } from "@vkontakte/icons";
import { Icon20ErrorCircleOutline } from "@vkontakte/icons";
import { Icon20HomeOutline } from "@vkontakte/icons";
import { Icon20WaterDropOutline } from "@vkontakte/icons";
import { Icon24HorseToyOutline } from "@vkontakte/icons";

const mapStateToProps = (state = {}) => {
  return { ...state };
};

const Friend = ({
  id,
  go,
  fetchedUser,
  friendId,
  dispatch,
  activePage,
  socket,
  dbUser,
  setModalMessage,
}) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const [snackbar, setSnackbar] = useState(null);
  const [complaintOpened, setComplaintOpened] = useState(false);
  const [complaint, setComplaintText] = useState("Оскорбление");
  const [loading, setLoading] = useState(true);
  const [isNotFind, setIsNotFind] = useState(false);
  const [userHref, setUserHref] = useState('');

  const { loadingInner } = useSelector((state) => state);

  const openLightboxOnSlide = useCallback(
    (number) => {
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number,
      });
    },
    [lightboxController]
  );

  const [friendInfo, setFriendInfo] = useState({});

  const setGuest = () => {
    sendGuest({ fetchedUserId: fetchedUser.id, UserId: friendId })
      .then((result) => {
        if (result) {
          sendNotification({
            userId: friendId,
            type: "NEW_GUEST",
          }).then();
          socket.emit("send notice", { guest: true, userId: friendId });
        }
      })
      .catch((err) => console.log(err));
  };

  const getFriendInfo = useCallback( () => {
    getUser({ userId: friendId })
      .then((result) => {
        if (result) {
          setFriendInfo({
            ...result,
            images: Array(result.userInfo.image).concat(
              Array.from(
                result.images.other.filter((item) => item),
                (item) => item.image_uri
              )
            ),
          });
        }
      })
      .catch((err) => {
        setIsNotFind(true);
      });
  }, [friendId]);

  useEffect(() => {
    getFriendInfo(friendId);
  }, [friendId, getFriendInfo]);

  useEffect(() => {
    if (friendInfo) {
      if (dbUser.userInfo.vip) {
        getUserHref(friendId)
          .then((result) => {
            if (result.vk_id) {
              setUserHref(`https://vk.com/id${result.vk_id}`);
            }
          })
      }
      setGuest(friendInfo.userInfo);
    }
    setLoading(false);
  }, []);

  const likeUser = useCallback(() => {
    sendLike({ fetchedUserId: dbUser.userInfo.id, userInfoId: friendId })
      .then((result) => {
        if (result.save) {
          if (friendInfo.userInfo.online === 0)
            sendNotification({
              userId: friendId,
              type: "sympathies",
            }).then();
          if (result.mutual)
            sendNotification({
              userId: friendId,
              type: "mutual_sympathies",
            }).then();
          openSnack({ text: "Симпатия отправлена!", type: "success" });
          socket.emit("send notice", { sympathy: true, userId: friendId });
        } else {
          openSnack({ text: "Вы уже отправляли симпатию!", type: "danger" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [friendId, friendInfo]);

  const openRoom = useCallback(async () => {
    await dispatch(
      setActiveRoom({
        toggleRoom: {
          userId: dbUser.userInfo.id,
          friendId: friendId,
        },
      })
    );
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewChatRoom",
        activePanel: "chatRoom",
      })
    );
  }, [friendId]);

  const openSnack = useCallback(
    (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={
            snack.type === "danger" ? (
              <Icon16Clear fill="var(--like_text_tint)" />
            ) : snack.type === "warning" ? (
              <Icon16CheckCircle fill="var(--field_valid_border)" />
            ) : (
              <Icon16Like fill="var(--dynamic_green)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      );
    },
    [snackbar]
  );

  async function postStories(e) {
    return await bridge.send("VKWebAppShowStoryBox", {
      background_type: "image",
      url: fetchedUser.images.main,
    });
  }

  const postWall = useCallback(() => {
    const image =
      friendInfo.userInfo.sex === 2
        ? "160113110_457239090"
        : "160113110_457239089";
    const href = `photo-${image},https://vk.com/app${process.env.REACT_APP_APP_ID}#user_id=${friendInfo.userInfo.id}`;
    //отправим на стенку
    bridge
      .send("VKWebAppShowWallPostBox", {
        message: "Знакомься, общайся, действуй!",
        attachments: href,
      })
      .then((result) => {
        console.log(result);
      })
      .catch((err) => console.log(err));
  }, [friendInfo]);

  const sendComplaint = useCallback(() => {
    setComplaint({
      fetchedUserId: dbUser.userInfo.id,
      UserId: friendId,
      complaint: complaint,
    })
      .then((result) => {
        setComplaintOpened(false);
        if (result) {
          openSnack({ text: "Жалоба отправлена!", type: "warning" });
        }
        if (result.save === false) {
          openSnack({ text: "Вы уже отправляли жалобу!", type: "danger" });
        }
      })
      .catch((err) => console.log(err));
  }, [complaint]);

  if (isNotFind) {
    return (
      <Panel id={id}>
        <Placeholder
          icon={<Icon56InfoOutline />}
          header="Внимание!"
          action={
            <Button
              size="l"
              onClick={() => {
                dispatch(
                  setActivePage({
                    ...activePage,
                    prevView: "viewHome",
                    prevPanel: "home",
                    activeView: "viewHome",
                    activePanel: "home",
                  })
                );
              }}
            >
              Перейти на главную
            </Button>
          }
        >
          Пользователь не найден или удален.
        </Placeholder>
      </Panel>
    );
  }

  const setModalVip = () => {
      setModalMessage({
        title: vipConstants.FRIEND_PAGE,
        description: vipConstants.GET_SUBSCRIBE,
        icon: <Icon56HideOutline />,
        modalId: "AlertVip",
      });
  };

  return (
    <Panel separator={false} id={id}>
      <PanelHeader
        left={
          !loadingInner && <PanelHeaderBack onClick={go} data-prev={true} />
        }
      >
        Профиль
      </PanelHeader>
      {friendInfo.userInfo && !loading && !loadingInner ? (
        <>
          <PanelHeaderContext
            opened={complaintOpened}
            onClose={() => setComplaintOpened(false)}
          >
            <List>
              <FormItem top="Выберите причину">
                <Radio
                  defaultChecked={true}
                  onChange={(e) => setComplaintText(e.currentTarget.value)}
                  value="Оскорбление"
                  name="complaint"
                >
                  <span style={{ fontSize: 14 }}>Оскорбление</span>
                </Radio>
                <Radio
                  onChange={(e) => setComplaintText(e.currentTarget.value)}
                  value="Спам"
                  name="complaint"
                >
                  <span style={{ fontSize: 14 }}>Спам</span>
                </Radio>
                <Radio
                  onChange={(e) => setComplaintText(e.currentTarget.value)}
                  value="Реклама интим-услуг"
                  name="complaint"
                >
                  <span style={{ fontSize: 14 }}>Реклама интим-услуг</span>
                </Radio>
                <Radio
                  onChange={(e) => setComplaintText(e.currentTarget.value)}
                  value="Распространение наркотиков"
                  name="complaint"
                >
                  <span style={{ fontSize: 14 }}>
                    Распространение наркотиков
                  </span>
                </Radio>
                <Radio
                  onChange={(e) => setComplaintText(e.currentTarget.value)}
                  value="Другое"
                  name="complaint"
                >
                  <span style={{ fontSize: 14 }}>Другое</span>
                </Radio>
              </FormItem>
              <FormItem>
                <Button
                  stretched
                  size="l"
                  mode="destructive"
                  onClick={() => sendComplaint()}
                >
                  Отправить
                </Button>
              </FormItem>
            </List>
          </PanelHeaderContext>
          <Group>
            <Gradient
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: 32,
              }}
            >
              <div
                style={{
                  width: 172,
                  height: 172,
                  borderRadius: "50%",
                  margin: "0.5em",
                  position: "relative",
                  background: `url(${friendInfo.userInfo.image}) center center / cover no-repeat`,
                }}
              />
              <Title
                style={{ marginBottom: 8, marginTop: 20 }}
                level="2"
                weight="medium"
              >
                {friendInfo.userInfo.first_name}, {friendInfo.userInfo.age}
              </Title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={postWall}>
                  <Avatar size={48}>
                    <Icon28AdvertisingOutline fill="var(--dynamic_blue)" />
                  </Avatar>
                </IconButton>
                <IconButton
                  onClick={likeUser}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    height: "auto",
                  }}
                >
                  <Avatar
                    style={{
                      background:
                        friendInfo.userInfo.sex === 1
                          ? "var(--dynamic_red)"
                          : "var(--dynamic_blue)",
                    }}
                    size={72}
                  >
                    <Icon36Like fill="var(--button_commerce_foreground)" />
                  </Avatar>
                </IconButton>
                <IconButton onClick={openRoom}>
                  <Avatar size={48}>
                    <Icon28Message fill="var(--dynamic_green)" />
                  </Avatar>
                </IconButton>
              </div>
              <Div>
                {
                  dbUser.userInfo.vip
                    ?
                    <Link target='_blank' href={userHref} className='link-btn'>
                      <Button
                        before={<Icon28LogoVk />}
                        size="l"
                        mode="outline"
                      >
                        Перейти на страницу VK
                      </Button>
                    </Link>
                    :
                    <Button
                      onClick={() => setModalVip()}
                      before={<Icon28LogoVk />}
                      size="l"
                      mode="outline"
                    >
                      Перейти на страницу VK
                    </Button>
                }
              </Div>
            </Gradient>
          </Group>
          <Group
            header={
              <Header mode="secondary">Информация о пользователе:</Header>
            }
          >
            <SimpleCell>
              <InfoRow header="Цель знакомства:">
                {friendInfo.userInfo.condition_title || ""}
              </InfoRow>
            </SimpleCell>
            <SimpleCell>
              <InfoRow header="Город:">
                {friendInfo.userInfo.city_title || ""}
              </InfoRow>
            </SimpleCell>
            <SimpleCell>
              <InfoRow header="Кого ищет:">
                Ищу {friendInfo.userInfo.find_sex === 2 ? "парня" : "девушку"}{" "}
                от {friendInfo.userInfo.age_start} до{" "}
                {friendInfo.userInfo.age_end}
              </InfoRow>
            </SimpleCell>
          </Group>
          <Group header={<Header mode="secondary">Фото:</Header>}>
            <CardGrid size="s">
              {friendInfo.images.length > 0 &&
                friendInfo.images.map((item, index) => {
                  let styleImage = {
                    height: 96,
                    background: `url(${item}) center / cover no-repeat`,
                  };
                  if (index === 3) styleImage.marginLeft = 0;
                  return (
                    <Card
                      mode="outline"
                      onClick={() => openLightboxOnSlide(index + 1)}
                      style={styleImage}
                      key={index}
                    />
                  );
                })}
              {friendInfo.images.length > 0 && (
                <Swipeable
                  onSwipedUp={(eventData) => {
                    if (
                      eventData.absY >
                      Math.round(eventData.event.target.height / 2)
                    ) {
                      setLightboxController({
                        ...lightboxController,
                        toggler: false,
                      });
                    }
                  }}
                  onSwipedDown={(eventData) => {
                    if (
                      eventData.absY >
                      Math.round(eventData.event.target.height / 2)
                    ) {
                      setLightboxController({
                        ...lightboxController,
                        toggler: false,
                      });
                    }
                  }}
                >
                  <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={friendInfo.images}
                    slide={lightboxController.slide}
                    customToolbarButtons={[
                      {
                        viewBox: "0 0 96.124 96.123",
                        d:
                          "M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803 c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654 c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246 c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z",
                        width: "17px",
                        height: "17px",
                        title: "Facebook",
                        onClick: () => console.log("Facebook button clicked!"),
                      },
                    ]}
                  />
                </Swipeable>
              )}
            </CardGrid>
          </Group>
          <Group header={<Header mode="secondary">Характеристики:</Header>}>
            <MiniInfoCell
              before={<Icon28ArrowUpOutline height={20} width={20} />}
            >
              {friendInfo.userInfo.height || "---"}
            </MiniInfoCell>
            <MiniInfoCell before={<Icon20AccessibilityOutline />}>
              {friendInfo.userInfo.physique_title || "---"}
            </MiniInfoCell>
            <MiniInfoCell before={<Icon20HomeOutline />}>
              {friendInfo.userInfo.live_title || "---"}
            </MiniInfoCell>
            <MiniInfoCell before={<Icon20WaterDropOutline />}>
              {friendInfo.userInfo.alcohol_title || "---"}
            </MiniInfoCell>
            <MiniInfoCell
              before={<Icon24HorseToyOutline height={20} width={20} />}
            >
              {friendInfo.userInfo.child_title || "---"}
            </MiniInfoCell>
            <MiniInfoCell before={<Icon28InboxOutline />}>
              {friendInfo.userInfo.job_title || "---"}
            </MiniInfoCell>
            <MiniInfoCell
              style={{ color: "var(--destructive)" }}
              before={<Icon20ErrorCircleOutline fill="var(--destructive)" />}
              mode="more"
              onClick={() => setComplaintOpened(true)}
            >
              Пожаловаться
            </MiniInfoCell>
          </Group>
          {snackbar}
        </>
      ) : (
        <Spinner style={{ marginTop: "50%" }} size="medium" />
      )}
    </Panel>
  );
};

export default connect(mapStateToProps)(React.memo(Friend));
