import Konva from 'konva';
import downIcon from '../img/opinion_down.svg';
import messageIcon from '../img/opinion_messages.svg';

export const OpinionStory = (props) => {
	return new Promise(async (resolve, reject) => {
		const width = 1080;
		const height = 1920;

		const canvas = new Konva.Stage({
			width: width,
			height: height,
			container: '#storyId'
		});

		let layer = new Konva.Layer();
		const groupText = new Konva.Group({
			y: 900
		});

		let images = {};

		const setText = (size, margin, text) => {
			let textComponent = new Konva.Text({
				x: 0,
				y: margin,
				width: width,
				text: text,
				fontSize: size,
				fill: 'white',
				align: 'center'
			});
			groupText.add(textComponent);
		}

		const setImage = (images) => {
				const message = new Konva.Image({
					image: images.message,
					width: 200,
					height: 150,
					x: (width / 2) - 100,
					y: 175
				});
				const down = new Konva.Image({
					image: images.down,
					width: 80,
					height: 80,
					x: (width / 2) - 40,
					y: 700
				});
				groupText.add(message);
				groupText.add(down);
				layer.batchDraw();

			layer.add(groupText);
			canvas.add(layer);

			const base64 = canvas.toDataURL();
			canvas.destroy();
			resolve(base64);
		}

		const loadImages = (sources, callback) => {
			let loadedImages = 0;
			let numImages = 0;
			for (let src in sources) {
				numImages++;
			}
			for (let src in sources) {
				images[src] = new Image();
				images[src].onload = function () {
					if (++loadedImages >= numImages) {
						callback(images);
					}
				};
				images[src].src = sources[src];
			}
		}

		/*gradient*/
		const Gradient = new Konva.Rect({
			width: width,
			height: height,
			fillLinearGradientColorStops: [0, '#5079B1', 1, '#E65679'],
			fillLinearGradientStartPoint: {
				x: 0,
				y: 0
			},
			fillLinearGradientEndPoint: {
				x: width,
				y: height
			}
		});

		layer.add(Gradient);

		setText(64, 15, `${props.name}, ${props.age}`);
		setText(48, 95, `г.${props.city}`);
		setText(64, 350, 'Как ты ко мне относишься?');
		setText(48, 450, 'Оставь свое мнение анонимно \n нажав на кнопку \"Открыть\"');
		setText(42, 600, 'И если наше отношение совпадет\n то мы это увидим');

		Konva.Image.fromURL(props.image,  (image) => {
			let imageW = image.attrs.image.width;
			let imageH = image.attrs.image.height;

			let size = Math.min(imageH, imageW);
			let scale = 480 / size;

			const group = new Konva.Group({
				x: 320,
				y: 320,
				width: 480,
				height: 480,
				clipFunc: function(ctx) {
					ctx.arc(240, 240, 240, Math.PI * 2, false);
				},
			});
			image.setAttrs({
				x: 0,
				y: 0,
				width: imageW * scale,
				height: imageH * scale,
				//обрезка по квадрату из центра
				/*crop: {
					x: (imageW / 2) - (size / 2),
					y: (imageH / 2) - (size / 2),
					width: size,
					height: size
				},*/
			});
			group.add(image);

			layer.add(group);
			layer.batchDraw();

			loadImages({
				message: messageIcon,
				down: downIcon
			},setImage);
		});
	});
}