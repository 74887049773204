import React, {useCallback, useEffect, useState} from 'react';
import {Panel , PanelHeader, PanelHeaderBack, Group,List, Cell} from '@vkontakte/vkui';
import InfiniteScroll from 'react-infinite-scroll-component';
import {connect} from 'react-redux'
import bridge from '@vkontakte/vk-bridge';
import {setActivePage, setEditData} from "../actions/actions";
import { ServiceConstants } from "../constants/ServiceConstants";
const mapStateToProps = (state = {}) => {
	return {...state};
};

const ImageList = ({ id, go, fetchedUser, setAlertImagesError, editData, dispatch, setAlertValidateImage}) => {
		const [imagesList, setImagesList] = useState({
			offset: 0,
			images: []
		})
		const validateImage = (imageUri) => {
				return editData.images.other
					.map(item => (item && item.image_uri) && item.image_uri)
					.concat(editData.images.avatar.main)
					.some((item) => item === imageUri)
		}
		const editImage = (data) => {
			if(!validateImage(data.image_uri)){
				if(fetchedUser.imagesEditData.isEdit){
					let DataImage = null;
					if(fetchedUser.imagesEditData.isMain){
						DataImage = {
							...editData,
								images: {
									...editData.images,
									avatar: {
										main: data.image_uri,
										preview: data.preview,
										image_id: data.image_id
									}
								}
						}
					} else {
						DataImage = {
							...editData,
							images: {
								...editData.images,
								other: editData.images.other.map((item,index) => (index === fetchedUser.imagesEditData.index ? data : item))
							},
						}
					}
					dispatch(setEditData(DataImage))
					dispatch(setActivePage(fetchedUser.imagesEditData.prevPageData))
				}
			} else {
				setAlertValidateImage()
			}
		}

		const loadImages = useCallback(async () => {
			if(fetchedUser.imagesEditData && fetchedUser.access_token){
				await bridge.send('VKWebAppCallAPIMethod', {
					"method": "photos.get",
					"params":
						{
							"album_id": "profile",
							"owner_id": fetchedUser.id,
							"count": 20,
							"v": ServiceConstants.VERSION_API,
							"rev": 1,
							"offset": imagesList.offset,
							"access_token": fetchedUser.access_token
						}
				})
					.then(result => {
						if (result.response.items && result.response.count > 0) {
							setImagesList({
								offset: imagesList.offset + 20,
								images: imagesList.images.concat(result.response.items)
							})
						} else {
							setAlertImagesError()
						}
					})
					.catch(err => {
						console.log(err)
					})
			}
		},[imagesList.offset])

		useEffect(() => {
			loadImages()
		},[])

		return (
			<Panel
				separator={false}
				id={id}>
				<PanelHeader
					left={<PanelHeaderBack onClick={go} data-prev={true} />}
				>Выбор фото</PanelHeader>
				<Group>
					<List>
						<Cell>
							<InfiniteScroll
								dataLength={20}
								next={loadImages}
								hasMore={true}
								loader=''
							>
								{
									(imagesList.images && imagesList.images.length > 0) && (
										<div className='row-images'>
											{
												imagesList.images.map((item, index) => {
														let url = null;
														if (item.sizes && item.sizes.length > 0) {
															if (item.sizes.length > 3) {
																url = item.sizes[3].url
															} else {
																url = item.sizes[item.sizes.length - 1].url
															}
														}
														if(url != null){
															return (<div className='col-image'
																		 key={index}>
																<img
																	onClick={() => editImage({
																		user_id: fetchedUser.id,
																		image_id: item.id,
																		image_uri: url,
																		preview: item.sizes[0].url,
																	})}
																	src={url}
																/>
															</div>)
														} else {
															return null
														}
													}
												)
											}
										</div>
									)
								}
							</InfiniteScroll>
						</Cell>
					</List>
				</Group>
			</Panel>
		)
}
export default connect(mapStateToProps)(React.memo(ImageList))
