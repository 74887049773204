import React from "react";
import { Caption, Card, CardGrid, Spacing, Title } from "@vkontakte/vkui";
import { Icon28Notification } from '@vkontakte/icons';
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import { appConstants } from "../constants/appConstants";
import { MessageButtonAllow } from "./MessageButtonAllow";

export const AllowMessagesBanner = ({callBack}) => {
	return (
		<CardGrid size="l">
			<Card size="l">
				<Div style={{
					display: 'flex',
					alignItems: 'start',
					gap: 15,
				}} >
					<div>
						<Icon28Notification fill="var(--dynamic_blue)" />
					</div>
					<div>
						<Title weight="bold" level="2">У вас не включены уведомления!</Title>
						<Spacing />
						<Caption weight="regular" level="1" style={{color: 'var(--text_secondary)'}}>
							{appConstants.TEXT_ALLOW_MESSAGES_INVALID}
						</Caption>
						<Spacing />
						<MessageButtonAllow hiddenIcon={true} callBack={callBack} />
					</div>
				</Div>
			</Card>
		</CardGrid>
	)
}
