export const vipConstants = {
	FRIEND_PAGE: 'Для открытия страницы ВКонтакте, требуется подписка VKDonut!',
	FRIEND_PROFILE: 'Для открытия профиля, требуется подписка VKDonut',
	SUBSCRIBE_VIEWS_PEOPLE: 'Оформите подписку VKDonut и смотрите кто рядом с вами без ограничений!',
	GET_SUBSCRIBE: 'Оформите подписку от 179 рублей, не выходя из приложения!',
	SUBSCRIBE_SUCCESS: 'Вы успешно оформили подписку VKDonut.',
	CONGRATULATE: 'Поздравляем!',
	SEND_MESSAGE: 'Чтобы писать без ограничений, требуется подписка VKDonut!',
	WARNING_SYMPATHY: 'У вас нет взаимной симпатии',
	TEXT_BY_SUBSCRIBE: 'Оформить подписку',
	TEXT_EXTEND_SUBSCRIBE: 'Продлить подписку',
	TEXT_END_SUBSCRIBE: 'До окончания подписки',
	WARNING_ACCESS_IS_CLOSED: 'Доступ закрыт!',
	TEXT_GUEST: 'Чтобы просматривать гостей, вам необходимо оформить подписку VKDonut!',
	TEXT_PERIOD: 'Оформить подписку на 1 месяц',
	DONUT_GET_TOP_USERS: 'Для добавление себя в Топ-анкеты города, требуется оформить подписку VK Donut!',
}
