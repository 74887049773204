import React, { useEffect, useState } from "react";
import { Avatar, Cell, IconButton } from "@vkontakte/vkui";
import Icon24Add from "@vkontakte/icons/dist/24/add";
import Icon24Write from "@vkontakte/icons/dist/24/write";
import Icon24Delete from "@vkontakte/icons/dist/24/delete";

const styleAvatar = {
  backgroundColor: 'var(--dynamic_blue)',
};

const StyleAvatarMini = {
  ...styleAvatar,
  margin: "5px 0",
}

const ImagesUserList = ({ images, getImagesList, setUserImages }) => {
  const [userImages, setImages] = useState(images);
  const [count, setCount] = useState(0);
  const deleteImage = (index) => {
    setImages(() => ({
      ...userImages,
      other: Object.values({
        ...userImages.other,
        [index]: false,
      }),
    }));
    setCount(count + 1);
  };
  useEffect(() => {
    setUserImages(userImages);
  }, [count]);
  return (
    <Cell
      disabled
      multiline={true}
      style={{
        paddingTop: 20,
        paddingBottom: 20,
      }}
      className="cell-img"
    >
      <div className="grid-container">
        {userImages.other &&
          userImages.other.map((item, index) =>
            item ? (
              <div key={index} className={"col-image-" + index}>
                <div
                  className="image-full"
                  style={{
                    background: `url('${item.image_uri}') center/cover no-repeat`,
                    width: 56,
                    height: 56,
                  }}
                >
                  <IconButton
                    onClick={() => deleteImage(index)}
                    className="delete-img"
                  >
                    <Avatar
                      size={24}
                      style={StyleAvatarMini}
                    >
                      <Icon24Delete fill="white" width={12} height={12} />
                    </Avatar>
                  </IconButton>

                  <IconButton
                    onClick={() => getImagesList(index, false)}
                    className="edit-img"
                  >
                    <Avatar
                      size={24}
                      style={StyleAvatarMini}
                    >
                      <Icon24Write fill="white" width={12} height={12} />
                    </Avatar>
                  </IconButton>
                </div>
              </div>
            ) : (
              <div key={index} className={"col-image-" + index}>
                <div className="bg-mini-image">
                  <IconButton onClick={() => getImagesList(index, false)}>
                    <Avatar size={48}
                            style={styleAvatar}
                    >
                      <Icon24Add fill="white" />
                    </Avatar>
                  </IconButton>
                </div>
              </div>
            )
          )}
        <div className="center">
          <div
            className={userImages.avatar.main ? "bg-main-image" : "bg-empty"}
            onClick={() => {
              if (!userImages.avatar.main) {
                getImagesList(0, true);
              }
            }}
            style={{
              background: userImages.avatar.main
                ? `url('${userImages.avatar.main}') center/cover no-repeat`
                : "var(--dynamic_blue)",
            }}
          >
            <IconButton
              className="edit-img"
              onClick={() => getImagesList(0, true)}
            >
              <Avatar
                size={48}
                style={styleAvatar}
              >
                {userImages.avatar.main ? (
                    <Icon24Write fill="white" />
                ) : (
                  <Icon24Add fill="white" />
                )}
              </Avatar>
            </IconButton>
          </div>
        </div>
      </div>
      <small style={{ textAlign: "center", display: "flex", marginTop: "2em" }}>
        Загрузите в приложение свои реальные фотографии с профиля ВКонтакте
      </small>
    </Cell>
  );
};
export default ImagesUserList;
