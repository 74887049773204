import React from 'react';
import { Avatar, Caption, Card, CardGrid, Cell, Group } from "@vkontakte/vkui";
import Icon28UserCircleOutline from '@vkontakte/icons/dist/28/user_circle_outline';
import { dateFormat } from "../helpers/Helper";
import Div from "@vkontakte/vkui/dist/components/Div/Div";

export const OpinionRow = ({data}) => {
	return (
		<React.Fragment>
				{data.map((i,k) => (
							i.coincided === 1
								?
								<Div>
									<Card size="l" mode="shadow" key={k}>
										<Cell
											multiline
											before={<Avatar size={72} >
												<div className='avatarAround' style={{width: 72, height: 72, backgroundImage: `url(${i.image})`}} />
											</Avatar>}
											size="l"
											description={<div>{i.sex === 2 ? 'Оставил' : 'Оставила'} о Вас мнение <b>{i.value}</b><br/>{dateFormat(i.date_edit)}</div>}
											bottomContent={<Caption weight='semibold' level={1}>{i.value}</Caption>}
										>{i.first_name}, {i.age}</Cell>
									</Card>
								</Div>
							:
								<Div>
									<Card size="l" mode="shadow" key={k}>
										<Cell
											multiline
											before={<Icon28UserCircleOutline width={72} height={72} />}
											size="l"
											description={<div>Оставил о Вас мнение<br/>{dateFormat(i.date_edit)}</div>}
											bottomContent={<Caption weight='semibold' level={1}>Мнение будет доступно если оно совпадет</Caption>}
										>Анонимный пользователь</Cell>
									</Card>
								</Div>
						))
				}
		</React.Fragment>
	)
}
