'use strict';

const searchParams = new URLSearchParams(window.location.search);
let userIdHeader = searchParams.get('vk_user_id');

export function sendNotice({ userId, type }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/vk/get-notifications/${userId}/${type}`,
    {
      method: 'GET',
      headers: {
          'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data.result) {
        return data.result;
      } else {
        return false;
      }
    })
    .catch(error => console.error(error));
}
export function sendNotification(data) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/vk/send-notifications`,
    {
      method: 'POST',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader,
        'Content-Type': 'application/json; charset=utf-8',
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => data)
    .catch(error => console.error(error));
}
export function isMessagesFromGroupAllowed() {
  return fetch(`${process.env.REACT_APP_URL_API}/api/vk/allow_messages`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => data)
    .catch(error => console.error(error));
}
export function getUserByVKID( userId ) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/users-vk-id/${userId}`,
        {
            method: 'GET',
            headers: {
                'X-Referer': window.location.href,
                'X-USER-ID': userIdHeader
            },
            referrerPolicy: 'unsafe-url'
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            throw new Error(response.statusText);
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            throw new Error(error)
        });
}

export function getUser({ userId }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/users/${userId}`,
    {
      method: 'GET',
      headers: {
          'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data && data.userInfo) {
        return data;
      }
    })
    .catch(error => {
        throw new Error(error)
    });
}

export function getTransactions(group_id) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/transaction/${group_id}`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => (data))
    .catch(error => {
      throw new Error(error)
    });
}

export function getStatisticGroup(group_id) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/statistic-group/${group_id}`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => (data))
    .catch(error => {
      throw new Error(error)
    });
}

export function deleteUser(userId) {
  fetch(`${process.env.REACT_APP_URL_API}/api/users/${userId}`,
    {
      method: 'DELETE',
        headers: {
            'X-Referer': window.location.href,
            'X-USER-ID': userIdHeader,
        },
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        window.location.reload();
      }
    })
    .catch(error => console.error(error));
}
export function editCreditCard( data ) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/users-edit-group-admin`,
    {
      method: 'PUT',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function saveUserData({ fetchedUserId, data }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/users/${fetchedUserId}`,
    {
      method: 'PUT',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function setUserDataDb({ data }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/users`,
    {
      method: 'POST',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.userInsert) {
        return data;
      } else {
        return false;
      }
    })
    .catch(error => console.error(error));
}
export function setAllowNotificationDb({ fetchedUserId, allow, notifications }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/notifications/${fetchedUserId}`,
    {
      method: 'PUT',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify({ allow_notifications: allow, notifications: notifications })
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return allow === 1;
      }
    })
    .catch(error => console.error(error));
}
export function saveFindOptions({ fetchedUserId, settings }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/find/${fetchedUserId}`,
    {
      method: 'PUT',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(settings)
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function sendLike({ fetchedUserId, userInfoId }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/like`,
    {
      method: 'POST',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        user_id: fetchedUserId,
        user_like: userInfoId
      }),
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        return data;
      }
    })
    .catch(error => console.error(error));
}
export function sendDislike({ fetchedUserId, userInfoId }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/dislike`,
    {
      method: 'POST',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        user_id: fetchedUserId,
        user_dislike: userInfoId
      }),
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.save) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function saveStory({ user_id, uid }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/story`,
    {
      method: 'POST',
      headers: {
        'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        user_id,
        uid
      }),
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => data)
    .catch(error => console.error(error));
}
export function saveOpinion(data) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/opinion`,
    {
      method: 'POST',
      headers: {
        'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(data),
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => data)
    .catch(error => console.error(error));
}
export function getOpinion(uid) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/opinion/${uid}`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.status === 404) throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data.data) {
        return data.data;
      } else {
        return false;
      }
    })
    .catch(error => console.error(error));
}
export function getOpinionsList() {
  return fetch(`${process.env.REACT_APP_URL_API}/api/opinions`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.status === 404) throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data.data) {
        return data.data;
      } else {
        return false;
      }
    })
    .catch(error => console.error(error));
}
export function loadCard(fetchedUserId, group_id, limit, offset, withoutUsersIds) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/card/${fetchedUserId}/${group_id}/${limit}/${offset}`,
    {
      method: 'POST',
        headers: {
            'X-Referer': window.location.href,
            'X-USER-ID': userIdHeader,
            'Content-Type': 'application/json; charset=utf-8',
        },
      body: JSON.stringify({withoutUsersIds}),
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.status === 404) throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => data)
    .catch(error => console.error(error));
}
export function getOrderData(params) {
    let url = new URL(`${process.env.REACT_APP_URL_API}/api/get-order`);
    url.searchParams.set('type', params.type);
    return fetch(url,
        {
            method: 'GET',
            headers: {
                'X-Referer': window.location.href,
                'X-USER-ID': userIdHeader
            },
            referrerPolicy: 'unsafe-url'
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            if (response.status === 404) throw new Error(response.statusText);
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                return data;
            } else {
                return false;
            }
        })
        .catch(error => console.error(error));
}
export function loadCountries(fetchedUserId) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/countries`,
        {
            method: 'GET',
            headers: {
                'X-Referer': window.location.href,
                'X-USER-ID': userIdHeader
            },
            referrerPolicy: 'unsafe-url'
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            if (response.status === 404) throw new Error(response.statusText);
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                return data;
            } else {
                return false;
            }
        })
        .catch(error => console.error(error));
}
export function getVipAdw()
{
  return fetch(`${process.env.REACT_APP_URL_API}/api/adw-vip`,
    {
      method: 'POST',
      headers: {
        'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8',
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url',
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.status === 404) throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.error(error));
}
export function loadCities(params) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/cities`,
        {
            method: 'POST',
            headers: {
                'X-Referer': window.location.href,
                'Content-Type': 'application/json; charset=utf-8',
                'X-USER-ID': userIdHeader
            },
            referrerPolicy: 'unsafe-url',
            body: JSON.stringify(params)
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            if (response.status === 404) throw new Error(response.statusText);
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.error(error));
}
export function getHandBooks(fetchedUserId) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/handbooks/`,
    {
      method: 'GET',
      headers: {
          'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      let array = {};
      data.map((item) => {
        if (!array[item.group]) array[item.group] = new Array(0);
        array[item.group].push(item);
        return null;
      }
      );
      return array;
    })
    .catch(error => console.error(error));
}
export function editUserDb(userData) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/edit`,
    {
      method: 'PUT',
      headers: {
          'X-Referer': window.location.href,
          'X-USER-ID': userIdHeader,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(userData)
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function getUsersMap(urlQuery) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/map?${urlQuery}`,
    {
      method: 'GET',
        headers: {
            'X-Referer': window.location.href,
            'X-USER-ID': userIdHeader
        },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.status === 404) throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      return data.users;
    })
    .catch(error => console.error(error));
}
export function getUserHref(userId) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/users-href/${userId}`,
        {
            method: 'GET',
            headers: {
                'X-Referer': window.location.href,
                'X-USER-ID': userIdHeader
            },
            referrerPolicy: 'unsafe-url'
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            if (response.status === 404) throw new Error(response.statusText);
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.error(error));
}
export function getNoticeTotalDb(fetchedUserId) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/total-notice/${fetchedUserId}`,
    {
      method: 'GET',
        headers: {
            'X-Referer': window.location.href,
        },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        return data;
      }
    })
    .catch(error => console.error(error));
}
export function getChats(fetchedUserId) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/chat-list/${fetchedUserId}`,
    {
      method: 'GET',
        headers: {
            'X-Referer': window.location.href,
        },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        return data;
      }
    })
    .catch(error => console.error(error));
}
export function loadSympathies(fetchedUserId) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/sympathies/${fetchedUserId}`,
    {
      method: 'GET',
        headers: {
            'X-Referer': window.location.href,
        },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        return data;
      }
    })
    .catch(error => console.error(error));
}
export function loadGuests(fetchedUserId) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/guests/${fetchedUserId}`,
    {
      method: 'GET',
        headers: {
            'X-Referer': window.location.href,
        },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        return data;
      }
    })
    .catch(error => console.error(error));
}
export function sendGuest({ fetchedUserId, UserId }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/guest/${UserId}`,
    {
      method: 'POST',
      headers: {
          'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify({ guest_id: fetchedUserId })
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function setComplaint({ fetchedUserId, UserId, complaint }) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/complaint`,
    {
      method: 'POST',
      headers: {
            'X-Referer': window.location.href,
            'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        user_id: fetchedUserId,
        user_to: UserId,
        message: complaint
      }),
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
        if (data.status) {
            return true
        } else {
            return data
        }
    })
    .catch(error => console.error(error));
}
export function saveFindFromGeo({ fetchedUserId, settings }) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/find-geo/${fetchedUserId}`,
        {
            method: 'PUT',
            headers: {
                'X-Referer': window.location.href,
                'Content-Type': 'application/json; charset=utf-8'
            },
            referrerPolicy: 'unsafe-url',
            body: JSON.stringify(settings)
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            } else {
                throw response;
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.status) {
                return true;
            }
        })
        .catch(error => console.error(error));
}
export function setUserBlackList( data ) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/blacklist`,
        {
            method: 'POST',
            headers: {
                'X-Referer': window.location.href,
                'Content-Type': 'application/json; charset=utf-8',
            },
            referrerPolicy: 'unsafe-url',
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            } else {
                throw response;
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.status) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => console.error(error));
}
export function getUserBlackList({ fetchedUserId }) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/blacklist/${fetchedUserId}`,
        {
            method: 'GET',
            headers: {
                'X-Referer': window.location.href,
                'X-USER-ID': userIdHeader,
            },
            referrerPolicy: 'unsafe-url'
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            throw new Error(response.statusText);
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                return data;
            }
        })
        .catch(error => {
            throw new Error(error)
        });
}
export function getFreeVIP({UserId}) {
    return fetch(`${process.env.REACT_APP_URL_API}/api/free-vip`,
        {
            method: 'POST',
            headers: {
                'X-Referer': window.location.href,
                'Content-Type': 'application/json; charset=utf-8'
            },
            referrerPolicy: 'unsafe-url',
            body: JSON.stringify({ id: UserId })
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            } else {
                throw response;
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data) {
                return data;
            }
        })
        .catch(error => console.error(error));
}

export function appToGroups(group_id){
  return fetch(`${process.env.REACT_APP_URL_API}/api/app-to-groups`,
    {
      method: 'POST',
      headers: {
        'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8',
        'X-USER-ID': userIdHeader,
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify({ group_id })
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data) {
        return data;
      }
    })
    .catch(error => console.error(error));
}

export function getGroupsBoot( ) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/groups/`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      throw new Error(error)
    });
}
export function setGroupBoot(data) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/groups`,
    {
      method: 'POST',
      headers: {
        'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        ...data
      }),
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.error(error));
}
export function updateGroupBoot( data, id ) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/groups`,
    {
      method: 'PUT',
      headers: {
        'X-Referer': window.location.href,
        'Content-Type': 'application/json; charset=utf-8'
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify({
        data: data,
        user_id: userIdHeader,
        id: id,
      })
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status) {
        return true;
      }
    })
    .catch(error => console.error(error));
}
export function deleteUGroupBoot(id) {
  return fetch(`${process.env.REACT_APP_URL_API}/api/groups/${id}`,
    {
      method: 'DELETE',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader,
      },
      referrerPolicy: 'unsafe-url'
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw response;
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data.status){
        return true;
      }
    })
    .catch(error => console.error(error));
}

/**
 * Получаем Топ-анкет
 * @returns {Promise<any>}
 */
export function getTopUsers() {
  return fetch(`${process.env.REACT_APP_URL_API}/api/top-users`,
    {
      method: 'GET',
      headers: {
        'X-Referer': window.location.href,
        'X-USER-ID': userIdHeader
      },
      referrerPolicy: 'unsafe-url'
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      throw new Error(error)
    });
}
