import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  SelectMimicry,
  Group,
  Select,
  FormItem,
} from "@vkontakte/vkui";
import ImagesUserList from "../../components/ImagesUserList";
import InputMask from "react-input-mask";
import { setActivePage, setUserData, setEditData } from "../../actions/actions";
import { getHandBooks } from "../RequestApi";
import bridge from "@vkontakte/vk-bridge";
import { Icon28DoneOutline } from '@vkontakte/icons';

const ValidatorMain = require("jsonschema").Validator;
const validator = new ValidatorMain();

const editScheme = {
  type: "object",
  required: ["age", "condition_id", "city_id"],
  properties: {
    height: {
      anyOf: [
        {
          type: "null",
        },
        {
          type: "integer",
          minimum: 50,
          maximum: 250,
        },
      ],
    },
    age: {
      type: "integer",
      minimum: 18,
      maximum: 90,
    },
    city_id: {
      type: "integer",
      minimum: 1,
    },
    condition_id: {
      anyOf: [
        {
          not: {
            type: "null",
          },
        },
        {
          type: "string",
          minLength: 1,
        },
      ],
    },
  },
};

export const ProfileEditForm = (props) => {
  const { sendData, setAlertDefault } = props;
  const { fetchedUser, dbUser, editData } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [formValid, setFormValid] = useState({
    age: "default",
    height: "default",
    condition_id: "default",
  });
  const [handbooks, setHandbooks] = useState(null);

  useEffect(() => {
    let isMounted = true;
    getHandBooks(fetchedUser.id)
      .then((result) => {
        if (isMounted) setHandbooks(result);
      })
      .catch((err) => {
        console.log(err);
      });

    if (!editData) {
      dispatch(setEditData(dbUser));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const getCountryModal = () => {
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewCountry",
        activePanel: "county",
      })
    );
  };

  const getCityModal = () => {
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewCountry",
        activePanel: "GetCityUserEdit",
      })
    );
  };

  const getImagesList = useCallback(
    async (index, isMain) => {
      bridge
        .send("VKWebAppGetAuthToken", {
          app_id: parseInt(process.env.REACT_APP_APP_ID),
          scope: "photos",
        })
        .then((result) => {
          if (result.access_token) {
            dispatch(
              setUserData({
                ...fetchedUser,
                access_token: result.access_token,
                imagesEditData: {
                  index: index,
                  isMain: isMain,
                  isEdit: true,
                  prevPageData: {
                    historyDelete: true,
                    prevView: 'viewImagesList',
                    prevPanel: 'imagesList',
                    activeView: 'viewProfile',
                    activePanel: 'editProfile'
                  },
                },
              })
            );
            dispatch(
              setActivePage({
                historyAdd: true,
                activeView: "viewImagesList",
                activePanel: "imagesList",
              })
            );
          }
        })
        .catch((er) => {
          console.log(er);
        });
    },
    [fetchedUser]
  );

  const saveData = async () => {
    if (await validate()) {
      sendData();
    } else {
      setAlertDefault({
        alertTitle: "Форма содержит ошибки!",
        alertText: "Проверьте заполненные поля.",
        buttonTitle: "Закрыть",
      });
    }
  };

  const changeValue = useCallback(
    (e) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      )
        ? parseInt(e.currentTarget.value)
        : null;
      dispatch(
        setEditData({
          ...editData,
          userInfo: {
            ...editData.userInfo,
            [name]: value,
          },
        })
      );
      validate({
        name: name,
        value: value,
      });
    },
    [formValid, editData, dispatch]
  );

  const validate = useCallback(
    async (input = false) => {
      let dataInput = null;
      if (input) {
        dataInput = {
          [input.name]: input.value,
        };
        formValid[input.name] = "valid";
      } else {
        dataInput = editData.userInfo;
      }
      const valid = validator.validate(dataInput, editScheme);
      await valid.errors.some((item) => {
        const name = item.property.replace("instance.", "");
        if (formValid[name]) {
          formValid[name] = "error";
        }
      });
      setFormValid(() => formValid);
      return valid.valid;
    },
    [formValid, editData, changeValue, dispatch]
  );

  return (
    <Group>
      <ImagesUserList
        images={editData.images}
        getImagesList={(index, isMain) => getImagesList(index, isMain)}
        setUserImages={(data) => {
          dispatch(
            setEditData({
              ...editData,
              images: data,
            })
          );
        }}
      />

      <FormItem
        top="Выберите страну"
        bottom={editData.userInfo.country_id === 0 ? "Выберите страну" : ""}
        status={editData.userInfo.country_id === 0 ? "error" : "success"}
      >
        <SelectMimicry
          placeholder="Не выбрана"
          onClick={() => getCountryModal()}
        >
          {editData.userInfo.country_title || editData.userInfo.country_title}
        </SelectMimicry>
      </FormItem>
      <FormItem
        bottom={editData.userInfo.city_id === 0 ? "Выберите город" : ""}
        status={editData.userInfo.city_id === 0 ? "error" : "success"}
        top="Выберите город"
      >
        <SelectMimicry placeholder="Не выбран" onClick={() => getCityModal()}>
          {editData.userInfo.city_title || editData.userInfo.city_title}
        </SelectMimicry>
      </FormItem>

      {handbooks && (
        <React.Fragment>
          <FormItem
            top="Цель знакомства"
            bottom={formValid.condition_id === "error" ? "Выберите цель!" : ""}
            status={formValid.condition_id}
          >
            <Select
              placeholder="Выберите"
              name="condition_id"
              onChange={(e) => changeValue(e)}
              value={editData.userInfo.condition_id || null}
              options={handbooks.conditions.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem
            top="Ваш возраст (18 - 90)"
            status={formValid.age}
            bottom={formValid.age === "error" ? "Некорректный возраст" : ""}
          >
            <InputMask
              name="age"
              mask="99"
              value={editData.userInfo.age || null}
              onChange={(e) => changeValue(e)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </FormItem>
          <FormItem
            top="Телосложение"
          >
            <Select
              placeholder="Выберите"
              name="physique_id"
              onChange={(e) => changeValue(e)}
              value={editData.userInfo.physique_id || null}
              options={handbooks.physique.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem
            top="Я живу"
          >
            <Select
              placeholder="Выберите"
              name="live_id"
              onChange={(e) => changeValue(e)}
              value={editData.userInfo.live_id || null}
              options={handbooks.live.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem
            top="Алкоголь"
          >
            <Select
              placeholder="Выберите"
              name="alcohol_id"
              onChange={(e) => changeValue(e)}
              value={editData.userInfo.alcohol_id || null}
              options={handbooks.alcohol.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem
            top="Дети"
          >
            <Select
              placeholder="Выберите"
              name="child_id"
              onChange={(e) => changeValue(e)}
              value={editData.userInfo.child_id || null}
              options={handbooks.child.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem
            top="Работа"
          >
            <Select
              placeholder="Выберите"
              name="job_id"
              onChange={(e) => changeValue(e)}
              value={editData.userInfo.job_id || null}
              options={handbooks.job.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem top="Ваш рост">
            <InputMask
              onChange={(e) => changeValue(e)}
              defaultValue={editData.userInfo.height || ""}
              name="height"
              placeholder=""
              mask="999"
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </FormItem>
        </React.Fragment>
      )}
      <FormItem>
        <Button
					before={<Icon28DoneOutline />}
					onClick={saveData} size="l" stretched>
          Сохранить
        </Button>
      </FormItem>
    </Group>
  );
};
