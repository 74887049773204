import socketIOClient from "socket.io-client";

const initialState = {
    chatList:[],
    fetchedUser: false,
    messages: [],
    dbUser: false,
    history: [
        {
            activeView: "viewHome",
            activePanel: "home",
        }
    ],
    activePage: {
        activeView: "viewHome",
        activePanel: "home",
    },
    loadingInner: false,
    editData: null,
    userGroups: [],
    totalCards: 0,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIAL_SOCKET':
            const endpoint = `${process.env.REACT_APP_URL_CHAT}` + window.location.search;
            return {
                ...state,
                socket: socketIOClient(endpoint)
            }
        case 'SET_USER_DATA':
            return {
                ...state,
                fetchedUser: action.data
            }
        case 'SET_USER_GROUPS':
            return {
                ...state,
                userGroups: action.data
            }
        case 'SET_LOADING':
            return {
                ...state,
                loadingInner: action.data
            }
        case 'SET_USER_DB_DATA':
            return {
                ...state,
                dbUser: action.data
            }
        case 'SET_ACTIVE_ROOM':
            return {
                ...state,
                room:action.room,
            }
        case 'SET_ACTIVE_PAGE':
            return {
                ...state,
                history: action.history ? action.history : [],
                editData: action.editData ? action.editData : null,
                activePage: action.activePage,
            }
        case 'SET_USER_ID_COMPLAINT':
            return {
                ...state,
                userComplaintId:action.userComplaintId,
            }
        case 'SET_FRIEND_ID':
            return {
                ...state,
                friendId:action.friendId,
            }
        case 'SET_EDIT_DATA':
            return {
                ...state,
                editData:action.data,
            }
        case 'SET_TOTAL_CARDS':
            return {
                ...state,
                totalCards: action.data
            }
        default:
            return state
    }
}
export default reducer;
