import React from 'react';
import {Button, Panel, Placeholder, View, Div} from "@vkontakte/vkui";
import Icon56DoNotDisturbOutline from '@vkontakte/icons/dist/56/do_not_disturb_outline';

const Offline = () => (
    <View id="offline" activePanel="offline">
        <Panel id="offline">
          <Div
            className='full-height'
          >
            <Placeholder
              stretched
              icon={<Icon56DoNotDisturbOutline/>}
              header="Произошла ошибка!"
              action={<Button size="l" onClick={() => {
                console.log('Connected...')
              }}>Повторить</Button>}
            >
              Проверьте Ваше соединение с интернетом.
            </Placeholder>
          </Div>
        </Panel>
    </View>
)

export default React.memo(Offline);
