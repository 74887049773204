import React from 'react';
import Countdown from 'react-countdown';
import {Div, Title} from "@vkontakte/vkui";
export const CounterDown = ({date}) => {

    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <Div className="counterDown">
                <div className="counterCol">
                    <span><Title level={1} weight="bold">{days}</Title> дн.</span>
                        <span className="delimiter"></span>
                    <span><Title level={1} weight="bold">{hours}</Title>ч.</span>
                        <span className="delimiter"></span>
                    <span><Title level={1} weight="bold">{minutes}</Title>мин.</span>
                        <span className="delimiter"></span>
                    <span><Title level={1} weight="bold">{seconds}</Title>сек.</span>
                </div>
            </Div>
        );
    };

    return (
        <Countdown
            date={new Date(date)}
            renderer={renderer}
        />
     );
}
