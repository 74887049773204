import React, { useContext, useEffect, useState } from "react";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import Icon24Info from "@vkontakte/icons/dist/24/info";
import Icon24Like from "@vkontakte/icons/dist/24/like";
import Icon24Cancel from "@vkontakte/icons/dist/24/cancel";
import { Div, IconButton, Subhead, Button } from "@vkontakte/vkui";
import SwipeSlider from "./Slider";
import { Icon20MessageCircleFillGreen, Icon28NotificationAddOutline, Icon36LogoVk } from "@vkontakte/icons";
import { CardListContext } from "../context/CardListContext";
import { connect } from "react-redux";

const StyleLabelCity = {
  maxWidth: '50%',
  boxSizing: 'border-box',
  borderRadius: 8, padding: '2px 10px',
  backgroundColor: 'var(--button_commerce_background)'
}

const StyleWordBreak = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 11,
}

const mapStateToProps = (state = {}) => {
  return { ...state };
};

const Card = ({
  userData,
  dislikeUser,
  likeUser,
  openChat,
  openFriend,
  openUserPage,
  dbUser,
}) => {
  const [userImages, setUserImages] = useState(false);
  const CardListUseContext = useContext(CardListContext);

  useEffect(() => {
    let tmpImages = [];
    if (userData.image) tmpImages.push(userData.image);
    if (userData.images) {
      userData.images.map((item) => tmpImages.push(item.image_uri));
    }
    setUserImages(tmpImages);
  }, [userData.image, userData.images]);
  return (
    <Div>
      <div className="userCard">
        <div className="cardUserWrapper">
          {userImages && <SwipeSlider images={userImages} />}
          <div
            style={{
              margin: "1em",
              position: "absolute",
              top: "0px",
            }}
          >
            <IconButton
              onClick={openFriend}
              onTouchEnd={openFriend}
            >
              <Avatar
                style={{ backgroundColor: 'var(--background_light)' }}
                size={42}>
                <Icon24Info width={41} height={41} fill="var(--text_primary)" />
              </Avatar>
            </IconButton>
            <IconButton
              style={{
                marginTop: 10
              }}
              onClick={() => openChat(userData.id)}
              onTouchEnd={() => openChat(userData.id)}
            >
              <Icon20MessageCircleFillGreen height={42} width={42} />
            </IconButton>
          </div>

          {!dbUser.userInfo.allow_messages &&
            <div
              style={{
                margin: "1em 0 0 0",
                position: "absolute",
                top: "0px",
                right: 0,
              }}
            >
              <Button
                onClick={CardListUseContext.callBack}
                mode="tertiary">
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Avatar
                    style={{
                      backgroundColor: 'var(--dynamic_blue)'
                    }}
                    size={48}>
                    <Icon28NotificationAddOutline width={32} height={32} fill="#FFFFFF" />
                  </Avatar>
                  <div
                    style={{
                      backgroundColor: 'var(--dynamic_blue)',
                      padding: '5px 7px',
                      borderRadius: '0 5px 5px 0',
                      color: '#FFFFFF',
                      position: 'relative',
                      right: 5,
                    }}
                  >
                    <Subhead  weight="medium">Включите уведомления</Subhead>
                  </div>
                </div>
              </Button>
            </div>
          }

          <div className="cardUserBottom">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                position: "absolute",
                width: "100%",
                top: "-2em",
              }}
            >
              <IconButton
                onClick={dislikeUser}
                onTouchEnd={dislikeUser}
              >
                <Avatar
                  style={{ backgroundColor: 'var(--background_light)' }}
                  size={72}>
                  <Icon24Cancel fill="var(--destructive)" />
                </Avatar>
              </IconButton>
              <IconButton
                onClick={openUserPage}
                onTouchEnd={openUserPage}
              >
                <Avatar
                  style={{ backgroundColor: 'var(--background_light)' }}
                  size={64}>
                  <Icon36LogoVk fill="var(--dynamic_blue)" />
                </Avatar>
              </IconButton>
              <IconButton
                onClick={likeUser}
                onTouchEnd={likeUser}
              >
                <Avatar
                  style={{backgroundColor: 'var(--destructive)'}}
                  size={72}>
                  <Icon24Like fill="white" />
                </Avatar>
              </IconButton>
            </div>

            <div className="cardDescription">
              <div className="flex-row">
                <div>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      margin: 0,
                      textShadow: '1px 0 1px var(--background_light), 0 1px 1px var(--background_light),  -1px 0 1px var(--background_light), 0 -1px 1px var(--background_light)',
                      color:
                        userData.sex === 1
                          ? "var(--dynamic_red)"
                          : "var(--dynamic_blue)",
                    }}
                  >
                    {userData.first_name}, {userData.age}
                  </h2>
                </div>
              </div>
              <div className="flex-row" style={{marginBottom: '0.5rem'}}>
                <div style={{...StyleLabelCity, ...{
                  backgroundColor: 'var(--button_commerce_background)'
                }}}>
                  <Subhead style={StyleWordBreak} weight='semibold'>{userData.condition_title || ""}</Subhead>
                </div>
                <div style={{margin: '0 2px'}} />
                <div style={{...StyleLabelCity,...{
                  backgroundColor: 'var(--button_secondary_destructive_foreground)'
                }}}>
                  <Subhead style={StyleWordBreak} weight='semibold'>{userData.city_title}</Subhead>
                </div>
              </div>
              <div className="flex-row">
                <div style={{
                  borderRadius: 8, padding: '2px 10px',
                  backgroundColor: userData.sex === 2
                    ? "var(--dynamic_red)"
                    : "var(--dynamic_blue)",
                }}>
                  <Subhead style={{fontSize: 12}} weight='semibold'>Ищу {userData.find_sex === 2 ? "парня" : "девушку"} от{" "}
                    {userData.age_start} до {userData.age_end}</Subhead>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
};

export default connect(mapStateToProps)(Card);
