import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Div, Group,
  Header,
  IconButton,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  PanelSpinner,
  Placeholder,
  SimpleCell,
  Snackbar
} from "@vkontakte/vkui";
import Icon56Users3Outline from "@vkontakte/icons/dist/56/users_3_outline";
import Icon24Delete from "@vkontakte/icons/dist/24/delete";
import Icon20Info from "@vkontakte/icons/dist/20/info";
import Icon20CheckBoxOn from "@vkontakte/icons/dist/20/check_box_on";

import { deleteUGroupBoot, getGroupsBoot } from "./RequestApi";
import bridge from "@vkontakte/vk-bridge";
import { useDispatch, useSelector } from "react-redux";
import { setUserGroups } from "../actions/actions";
import { ServiceConstants } from "../constants/ServiceConstants";
import { Icon28AddSquareOutline } from "@vkontakte/icons";

export const BootGroupModal = ({ getGroups, go, id }) => {
  const { userGroups } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGroupsBoot().then((r) => {
      if (r) dispatch(setUserGroups(r));
    });
  }, []);

  const deleteGroup = async (group) => {
    setLoading(true);
    await bridge
      .send("VKWebAppGetCommunityToken", {
        app_id: parseInt(process.env.REACT_APP_APP_ID),
        group_id: group.group_id,
        scope: "manage",
      })
      .then(async (result_token) => {
        if (result_token.access_token) {
          await deleteUGroupBoot(group.id)
            .then(async (resultDelete) => {
              if (resultDelete) {
                const result = await bridge.send("VKWebAppCallAPIMethod", {
                  method: "groups.deleteCallbackServer",
                  params: {
                    group_id: group.group_id,
                    v: ServiceConstants.VERSION_API,
                    server_id: group.server_id,
                    access_token: result_token.access_token,
                  },
                });
                if (result.response == 1) {
                  dispatch(
                    setUserGroups(
                      userGroups.filter((item) => item.id !== group.id)
                    )
                  );
                  openSnack({
                    text: "Чат-бот удален из группы.",
                    type: "danger",
                  });
                }
              }
            })
            .catch((r) => {
              console.log(r);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        openSnack({
          text: "Произошел сбой, попробуйте еще раз.",
          type: "danger",
        });
      });
    setLoading(false);
  };

  const openSnack = useCallback(
    (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={
            snack.type === "danger" ? (
              <Icon20Info fill="var(--like_text_tint)" />
            ) : (
              <Icon20CheckBoxOn fill="var(--field_valid_border)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      );
    },
    [snackbar]
  );

  return (
    <Panel className='panel-min-height-100' id={id}>
      <PanelHeader
        left={<PanelHeaderBack onClick={!loading && go} data-prev={true} />}
      >
        Сообщества
      </PanelHeader>
      {loading ? (
        <PanelSpinner height={150} size="medium" />
      ) : (
        <React.Fragment>
          {userGroups.length < 1 ? (
            <Placeholder
              header="Чат-бот знакомств для сообществ"
              icon={<Icon56Users3Outline />}
              action={
                <Button
                  before={<Icon28AddSquareOutline />}
                  size="l"
                  onClick={getGroups}
                >
                  Выбрать сообщество
                </Button>
              }
            >
              Вы еще не подключали чат-бот ни к одному сообществу.
            </Placeholder>
          ) : (
            <Group
              header={<Header mode="secondary">Список ваших групп</Header>}
            >
              {userGroups.map((item, key) => (
                <SimpleCell
                  className="text-left"
                  key={key}
                  after={
                    <IconButton onClick={() => deleteGroup(item)}>
                      <Avatar size={48}>
                        <Icon24Delete />
                      </Avatar>
                    </IconButton>
                  }
                  before={<Avatar size={48} src={item.group_image} />}
                  description={item.group_url ? `${item.group_url}` : ""}
                >
                  {item.group_name}
                </SimpleCell>
              ))}
              <Div>
                <Button
                  stretched
                  before={<Icon28AddSquareOutline />}
                  size="l"
                  onClick={getGroups}
                >
                  Выбрать сообщество
                </Button>
              </Div>
            </Group>
          )}
        </React.Fragment>
      )}
      {snackbar}
    </Panel>
  );
};
