import React, { useMemo, useState } from "react";
import TinderCard from "react-tinder-card";
import { default as Fade } from "react-fade";
import Card from "./Card";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import { Icon36Cancel, Icon36Like } from "@vkontakte/icons";

export const  MobileTinderCards = (props) => {
	const { fetchedCards, openFriend, onCardLeftScreen, openChat, openUserPage, avatarLikeRef, avatarDislikeRef } = props;
	const [fadeOut, setFadeout] = useState(null);
	const inputRefs = useMemo(() => Array(5).fill(0).map(i => React.createRef()), [fetchedCards]);

	const clickIcon = (direction, index) => {
		setFadeout(fetchedCards[index].id);
		inputRefs[index].current.swipe(direction);
	}

	const fadeDuration = 0.5;

	return (
		<div className="swipe-wrapper">
			{fetchedCards.map((user, index) => (
				<TinderCard
					ref={inputRefs[index]}
					onCardLeftScreen={(direction) => onCardLeftScreen(direction, user.id)}
					key={user.id}
					className='swipe'
					preventSwipe={["up", "down"]}
				>
					<Fade
						out={fadeOut === user.id}
						duration={fadeDuration}
						style={{ visibility: fadeOut === user.id ? "hidden" : "visibility", }}
					>
						<Card
							openUserPage={() => openUserPage(user.id)}
							userData={user}
							likeUser={() =>
								clickIcon("right", index)
							}
							dislikeUser={() =>
								clickIcon("left", index)
							}
							openFriend={() => openFriend(user.id)}
							openChat={openChat}
						/>
					</Fade>
				</TinderCard>
			))}
			<React.Fragment>
				<Avatar id="ratio-swipe-icon" size={96}>
					<Icon36Like
						getRootRef={avatarLikeRef}
						id="ratio-like"
						width={42}
						height={42}
						fill="var(--destructive)"
					/>
					<Icon36Cancel
						getRootRef={avatarDislikeRef}
						id="ratio-dislike"
						width={42}
						height={42}
						fill="var(--dynamic_violet)"
					/>
				</Avatar>
			</React.Fragment>
		</div>
	);
}
