import React, { useEffect } from 'react';
import Slider  from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slick = ({images}) => {
    useEffect(() => {

    },[])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        fade: true,
        dotsClass: 'vk-dots',
        arrows: false,
        lazyLoad: true,
        swipe: true,
    };

    return (
        <Slider {...settings}>
            {
                images && (
                    images.map((item,index) =>
                        <div key={index}>
                            <div
                                className='cardInnerImage'
                                style={{
                                    backgroundImage: `url(${item})`,
                                }}
                            />
                        </div>
                    )
                )
            }
        </Slider>
    )
}
export default Slick
