"use strict";
import React from 'react';
import {Alert, PopoutWrapper, Div, Button} from "@vkontakte/vkui";


export const AlertDeleteProfile = ({deleteUser,setPopout}) => (
    <Alert
        actionsLayout="horizontal"
        actions={
            [{
                title: 'Отмена',
                autoclose: true,
                style: 'cancel'
            }, {
                mode: 'destructive',
                title: 'Подтверждаю',
                autoclose: true,
                action: () => deleteUser(),
            }]}
        onClose={() => setPopout(false)}
        header="Подтвердите действие"
        text="При удалении анкеты пропадёт вся ваша переписка, гости и симпатии. Вы действительно хотите удалить свою анкету из приложения?"
    />
)
export const AlertWarningDefault = ({setPopout, alertText,alertTitle, buttonTitle}) => (
    <PopoutWrapper v="center" h="center" closing={false}>
        <Alert
            actionsLayout="vertical"
            actions={
                [{
                    title: buttonTitle || 'Понятно',
                    autoclose: true,
                    style: 'cancel'
                }]}
            onClose={() => setPopout(null)}
            header={alertTitle ? alertTitle : ''}
            text={alertText}
        />
    </PopoutWrapper>
)
export const AlertErrorImage = ({userId,setPopout}) => (
    <PopoutWrapper v="center" h="center" closing={false}>
        <Alert
            actionsLayout="vertical"
            actions={
                [
                    {
                        title: 'перейти в свой профиль',
                        mode: 'cancel',
                        action() {
                            window.open(`https://vk.com/id${userId}`, '_blank')
                        }
                    },
                    {
                        title: 'Закрыть',
                        autoclose: true,
                        mode: 'destructive',
                        action: () => setPopout(null)
                    }]}
            onClose={() => setPopout(null)}
            text='Для продолжения необходимо добавить минимум 1 фотографию в свой профиль!'
        />
    </PopoutWrapper>
)
export const AlertErrorAge = ({userId}) => (
    <PopoutWrapper v="center" h="center" closing={false}>
        <Div
            style={{
                background: 'white',
                margin: 15,
                padding: 15,
                borderRadius: 15,
                textAlign: 'center'
            }}
        >
            <p
                style={{
                    color: 'red'
                }}
            >Вам еще не исполнилось 18!</p>
            <Button
                onClick={() => window.open(`https://vk.com/id${userId}`, '_blank')}
            >Перейти в профиль</Button>
        </Div>
    </PopoutWrapper>
)
export const AlertWarning = ({alertData,setPopout}) => {
    const actionToggle = (e) => {
        window.dispatchEvent(new CustomEvent(alertData.event, {
            detail: { status: e }
        }));
    }
    return (
            <Alert
            actionsLayout="vertical"
            actions={
                [{
                    title: 'Отмена',
                    autoclose: true,
                    mode: 'cancel',
                }, {
                    mode: 'destructive',
                    title: 'Подтвердить',
                    autoclose: true,
                    action: () => actionToggle(true),
                }]}
            onClose={() => setPopout(false)}
            header={alertData.title}
            text={alertData.description}
        />
    )
}
