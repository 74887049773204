import React from "react";
import { Gradient, Group, Title } from "@vkontakte/vkui";
import Icon24Add from "@vkontakte/icons/dist/24/add";
import Icon24Write from "@vkontakte/icons/dist/24/write";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import { Icon56AddCircleOutline } from '@vkontakte/icons';

const ImagesUserListRegister = ({ images, getImagesList }) => {
  return (
    <Group>
      <Gradient style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 32,
      }}>
        <div
          onClick={() => getImagesList(0, true)}
          style={{
            width: 172,
            height: 172,
            borderRadius: "50%",
            margin: "0.5em",
            position: "relative",
            background: `url(${images.avatar.main}) center center / cover no-repeat`,
            border: '1px solid',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!images.avatar.main && <Icon56AddCircleOutline style={{color: 'var(--dynamic_blue)'}} width={72} height={72} />}
        </div>
        <Title style={{ marginBottom: 8, marginTop: 20 }} level="3" weight="medium">
          Загрузите в приложение свою реальную фотографию из профиля ВКонтакте
        </Title>
        <Div>
          <Button
            onClick={() => getImagesList(0, true)}
            before={images.avatar.main ? <Icon24Write /> : <Icon24Add /> }
            size="l"
            mode="outline">{images.avatar.main ? 'Редактировать фото' : 'Добавить фото' }</Button>
        </Div>
      </Gradient>
    </Group>
  );
};
export default ImagesUserListRegister;
