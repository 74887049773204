import React, {useEffect, useState} from 'react';
import {setEditData} from '../actions/actions'
import {Panel, PanelHeader, InfoRow, PanelHeaderBack, List, Cell, PanelSpinner, Title, Div, Search} from '@vkontakte/vkui';
import {connect} from 'react-redux'
import {loadCities} from "../components/RequestApi";

const mapStateToProps = (state = {}) => {
    return {...state};
};

const City = ({ id, go, dispatch, editData, fetchedUser}) => {
    const [citiesList, setCityList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [cityState, setCityState] = useState({
        id: 0,
        title: ''
    })
    const [loading, setLoading] = useState(true);

    const loadCity = async (data) => {
        setCityState({
            ...cityState,
            new: false
        })
        const text = data;
        await loadCities({
            q: text.toString(),
            country_id: editData.userInfo.country_id,
        }).then((r) => {
                setCityList(r || []);
            });
        setLoading(false);
    }

    useEffect( () => {
        let isMounted = true;
        if(editData.userInfo.city_id !== 0 && isMounted){
            setCityState({
                id: editData.userInfo.city_id,
                title: editData.userInfo.city_title
            })
            setSearchText(editData.userInfo.city_title)
        }
        if(isMounted) loadCity(editData.userInfo.city_title || '')
        return () => {
            isMounted = false
        };
    },[])

    useEffect( () => {
        (async () => {
            setLoading(true);
            await loadCity(searchText || '');
        })();
    },[searchText]);


    const saveData = (data) => {
        if (data.id !== 0) {
            dispatch(setEditData({
                ...editData,
                userInfo: {
                    ...editData.userInfo,
                    city_id: data.id,
                    city_title: data.title
                }
            }))
            window.history.back();
        }
    }

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={go} data-prev={true} />}
            >Выбор города</PanelHeader>
            <Search value={searchText} onChange={(e) => setSearchText(e.currentTarget.value)} after={null}/>
            {
                loading ?
                    (
                        <PanelSpinner size='medium'/>
                    )
                    :
                    (
                        <List>
                            {
                                citiesList && citiesList.length > 0 ?
                                    citiesList.map((item,index) => {
                                        let region = item.region || ''
                                        let area = item.area || ''
                                        const textRegion = region + ' ' + area
                                        return(
                                            <Cell
                                                onClick={() => saveData(item)}
                                                key={index}
                                                expandable
                                            >
                                                <InfoRow header={
                                                    textRegion
                                                }>
                                                    {item.title}
                                                </InfoRow>
                                            </Cell>
                                        )
                                    })
                                    :
                                    (
                                        <Div>
                                            <Title className='text-center' weight='bold' level={2} >
                                                Ничего не найдено
                                            </Title>
                                        </Div>
                                    )
                            }
                        </List>
                    )
            }
        </Panel>
    )
}
export default connect(mapStateToProps)(React.memo(City))
