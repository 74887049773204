export const ServiceConstants = {
	VERSION_API: 5.131,
	SEX_OPTIONS: [
		{
			value: 2,
			label: 'Парня'
		},
		{
			value: 1,
			label: 'Девушку'
		},
	],
	DEFAULT_CONDITION_ID: 1,
}

export const REASON_BLACKLIST = {
	IS_YOUNG: {
		type: 'is_young',
		title: 'Доступ закрыт!',
		description: 'Приложением можно пользоваться только с 18 лет.',
	},
	IS_SEX: {
		type: 'is_sex',
		title: 'Доступ закрыт!',
		description: 'Доступ закрыт!',
	},
}
