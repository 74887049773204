export const appConstants = {
	BUTTON_CLOSE: 'Закрыть',
	BUTTON_GET_SUBSCRIBE: 'Оформить',
	BUTTON_EDIT: 'Изменить',
	BUTTON_ADD_GROUP_TO_MONETIZATION: 'Подключить свою группу',
	TEXT_MONETIZATION: 'Монетизировать своё сообщество',
	TEXT_MONETIZATION_CABINET: 'Кабинет монетизации',
	TEXT_HEADER_MONETIZATION: 'Зарабатывай реальные деньги на своём сообществе уже сейчас!',
	TEXT_AUTO_SEND_MONEY: 'Автоматические выплаты на банковскую карту',
	TEXT_AUTO_SEND_MONEY_DESCRIPTION: 'Автоматизированная выплата при достижении 1000 рублей на балансе сообщества',
	TEXT_MONEY_TITLE: '30% с покупок внутри приложения и в чат-боте',
	TEXT_MONEY_DESCRIPTION: 'Получайте 53 рублей с каждой покупки VIP-статуса вашими подписчиками',
	TEXT_RUSSIAN_TITLE: 'Для граждан Российской Федерации',
	TEXT_RUSSIAN_DESCRIPTION: 'Монетизировать свои сообщества могут только граждане Российской Федерации, имеющие карту открытую в России',
	TEXT_DETAIL_STATISTIC_TITLE: 'Детальная статистика заработка',
	TEXT_DETAIL_STATISTIC_DESCRIPTION: 'Отслеживайте ежедневные суммы заработка и выплат в удобном кабинете внутри приложения',
	TEXT_GROUP_TITLE_MONETIZATION: 'Если вы подключали в своё сообщество приложение ранее, просто зайдите в приложение через своё сообщество',
	MESSAGE_ADD_APP: 'Сервис установлен в сообщество. Для того чтобы перейти в кабинет монетизации, откройте приложение через своё сообщество',
	TEXT_MONETIZATION_BALANCE: 'Ваш текущий личный баланс:',
	TEXT_MONEY_GROUP_AMOUNT: 'Складывается со всех Ваших групп, подключенных к монитизации!',
	BUTTON_CREDIT_CART: 'Банковская карта',
	BUTTON_SEND_MONEY: 'Вывод средств',
	BUTTON_HISTORY_TRANSFERS: 'История выводов',
	BUTTON_SAY_FRIENDS: 'Рассказать о приложении на стене',
	BUTTON_ADD_WIDGET: 'Подключить виджет в сообщество',
	BUTTON_ADD_CHAT_BOOT: 'Подключить чат-бот в сообщество',
	TEXT_MOTIVATION: "30% прибыли от покупок VIP через вашу группу переводится Вам. <br/> Автовывод денежных средств происходит при достижения 1000 рублей на балансе Вам на банковскую карту",
	TEXT_GET_SELLS: 'Увеличивайте вашу прибыль с сообщества! Расскажите подписчикам своего сообщества о приложений. Чем больше покупок будет через вашу группу, тем больше Вы будете зарабатывать!',
	TEXT_MONEY: 'Заработано рублей:',
	TEXT_MONEY_ALL_GROUPS: 'Заработано рублей со всех групп:',
	TEXT_MONEY_GROUP: 'Учитывается заработок только с этого сообщества!',
	TEXT_TODAY: 'Сегодня',
	TEXT_CONTROL_GROUP: 'Управление сообществом:',
	TEXT_WEEK: 'На этой недели',
	TEXT_TOTAL: 'Всего',
	TEXT_REGISTER: 'Зарегистрированных участников:',
	TEXT_REGISTER_GROUP: 'Учитываются участники только с этого сообщества!',
	TEXT_FAQ: 'FAQ',
	TEXT_OFFICIAL_GROUP: 'Официальное сообщество',
	TEXT_CONNECT: 'Подключить другие сообщества',
	TITLE_SEND_MONEY: 'Вывод средств',
	DESCRIPTION_SEND_MONEY: 'Вывод средств происходит автоматически при достижении 1000 рублей на вашем счету. Администратор сообществ получает денежные средства платёжным переводом внутри социальной сети ВКонтакте. При получении денежных средств, администратор сразу может вывести денежные средства на банковскую карту любого российского банка.',
	ERROR_CARD: 'Некорректный номер карты',
	HISTORY_TRANSACTIONS: 'История выводов',
	ERROR_GET_TOKEN: 'Ошибка получения токена',
	TEXT_ALLOW_MESSAGES_INVALID: 'Включите уведомления в личные сообщения ВКонтакте, чтобы быстро узнать, кто вам отправил сообщение в приложении!',
}
