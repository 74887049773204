import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import { sendNotice, sendNotification, setComplaint } from "../components/RequestApi";
import {
	Panel,
	PanelHeader,
	PanelHeaderContext,
	PanelHeaderContent,
	List,
	Cell,
	FormLayout,
	PanelHeaderBack,
	Placeholder,
	FormLayoutGroup,
	Radio, Spinner, Snackbar
} from '@vkontakte/vkui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputMessage from '../components/InputMessage'
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import Icon16CheckCircle from '@vkontakte/icons/dist/16/check_circle';
import Icon16Clear from '@vkontakte/icons/dist/16/clear';
import {faExclamationTriangle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faTimesCircle} from "@fortawesome/free-regular-svg-icons/faTimesCircle";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import { setActiveRoom} from "../actions/actions";
import ChatMessages from '../components/ChatMessages'
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import Icon56WriteOutline from '@vkontakte/icons/dist/56/write_outline';
import Icon56LockOutline from '@vkontakte/icons/dist/56/lock_outline';
import {useListenerEvent} from "../components/useListenerEvent";
import { vipConstants } from "../constants/vipConstants";

const Chat = ({ id, openFriend, toggleAlert, setModalMessage }) => {
	const dispatch = useDispatch();
	const { fetchedUser, socket, room, dbUser, loadingInner } = useSelector(state => state);

	const [messages,setMessages] = useState([])
	const [contextOpened, setContextOpened] = useState(false)
	const [friendStatus, setFriendStatus] = useState(room.blocked)
	const [complaintOpened, setComplaintOpened] = useState(false)
	const [complaint, setComplaintText] = useState('Оскорбление')
    const [loading, setLoading] = useState(true)
	const [noticeStatus, setNoticeStatus] = useState(false)
	const [snackbar, setSnackbar] = useState(null)
	const [userBlocked, setUserBlocked] = useState(null)

	useEffect(() => {
		setUserBlocked(room.user_block);
	},[room]);

	useEffect(() => {
		socket.emit('set room',room,(roomData) => {
			if(roomData.newRoom){
				setFriendStatus(roomData.newRoom.blocked);
				dispatch(setActiveRoom(roomData.newRoom))
			} else {
				setFriendStatus(room.blocked);
			}
			socket.emit('get chat messages', null, (data) => {
				if(data)setMessages(data)
				setLoading(false)
			})
		})
		return () => {
			//действие при демонтировании компонента
		};
	}, []);

	useEffect(() => {
		if(noticeStatus){
			sendNotification({
				userId: room.guest_id, type: 'NEW_MESSAGE'
			}).then();
		}
	},[noticeStatus])

	useEffect(() => {

		//примим сообщение из сокета
		socket.on("insert new message", (data) => {
			if(data){
				setMessages((messages) =>	[...messages,data])
			}
		})

		//примим статус пользователя из сокета
		socket.on("set status messages", (data) => {
			if(data.status){
				setMessages(data.messages)
			}
		})

		//примим статус и отправим себе в сокет
		socket.on("get status friend", (data) => {
			if(data){
				setUserBlocked(data.status)
				socket.emit('set status friend',data)
			}
		})

	},[])

	//Отправим сообщение в сокет
	const sendMessage = useCallback( (text) => {
		if(room.blocked === 0 && userBlocked === 0){
			const data = {
				name: fetchedUser.first_name,
				message: text,
				send_to_id: room.guest_id
			}
			socket.emit('add new message', data, (result) => {
				if(!result.status)
					setModalMessage({
						title: vipConstants.WARNING_SYMPATHY,
						description: vipConstants.SEND_MESSAGE,
						icon: <Icon56LockOutline />,
						modalId: 'AlertVip'
					});
				else
					if(room.online === 0 && room.blocked === 0 && userBlocked === 0)
						if(!noticeStatus)setNoticeStatus(true)
			});
		} else if(userBlocked === 1) {
			openSnack({text: "Собеседник Вас заблокировал",type: 'danger'});
		} else {
			openSnack({text: "Вы заблокировали пользователя",type: 'danger'});
		}
	},[messages,room,userBlocked])

	const sendComplaint = useCallback(() => {
		setComplaint({fetchedUserId: dbUser.userInfo.id, UserId: room.guest_id, complaint: complaint})
			.then(result => {
				setComplaintOpened(false)
				if(result){
					openSnack({text: "Жалоба отправлена!", type: 'success'});
				}
				if(result.save === false){
					openSnack({text: "Вы уже отправляли жалобу!",type: 'danger'});
				}
			})
			.catch(err => console.log(err))
	},[complaint, room.guest_id])

	const goBack = () => {
		socket.emit('disconnect room',room.room_id,(data) => {
			if(data.status){
				window.history.back();
			}
		})
	}

	const alertEvent = (e) => {
		if(e.detail.status){
			socket.emit('delete history',room.chat_id,(data) => {
				if(data && data.status){
					setMessages([])
					setContextOpened(false)
				} else {
					console.log(data)
				}
			})
		}
	}
	useListenerEvent("delete history", alertEvent);

	//сменить статус блокировки
	const toggleBlocked = useCallback(() => {
		const status = friendStatus === 0 ? 1 : 0;
		socket.emit('toggle user status',{status: status},(data) => {
			if(data.status)setFriendStatus(status)
			dispatch(setActiveRoom({
				...room,
				blocked: status
			}))
		})
	}, [friendStatus,room])

	const openSnack = useCallback((snack) => {
		if(snackbar) return;
		setSnackbar(
			<Snackbar
				layout="vertical"
				onClose={() => setSnackbar(null )}
				before={snack.type === 'danger' ? <Icon16Clear fill="var(--like_text_tint)" />  : <Icon16CheckCircle fill="var(--field_valid_border)" />}
			>
				{snack.text}
			</Snackbar>
		);
	},[snackbar])

		return (
			<Panel
				className='panel-min-height-100'
				id={id}>

				{
					(loading || loadingInner)
					?
						(
							<>
								<PanelHeader
									left={!loadingInner && <PanelHeaderBack onClick={goBack}/>}
								>Загрузка комнаты</PanelHeader>
								<Spinner style={{marginTop: '50%'}} size='medium' />
							</>
						)
					:
						(
							<>
								<PanelHeader
									left={
										<PanelHeaderBack onClick={goBack}/>
									}
								>
									<PanelHeaderContent
										aside={<Icon16Dropdown style={{ transform: `rotate(${contextOpened ? '180deg' : '0'})` }} />}
										onClick={() => {
										    if((!complaintOpened)) setContextOpened(!contextOpened)
										}}
										before={
											<div style={{
												width: 40,
												height: 40,
												borderRadius: '50%',
												margin: '.5em',
												position: 'relative',
												background: `url(${room.image}) center/cover no-repeat`,
											}}
											onClick={() => openFriend(room.guest_id)}
											/>
										}
									>
										{room.first_name}, {room.age}
									</PanelHeaderContent>
								</PanelHeader>
								<PanelHeaderContext style={{zIndex: 3}}  opened={(contextOpened && !complaintOpened)} onClose={() => setContextOpened(false)}>
									<List>
										{
											messages.length > 0 && (
												<Cell
													onClick={() => toggleAlert({
														title: 'Подтвердите действие',
														description: 'Вы уверены, что хотите очистить историю чата?',
														event: "delete history"
													})}
													style={{color: "var(--control_tint_muted)"}}
													before={<FontAwesomeIcon style={{marginRight: 15}}  icon={faTrash} />}
												>
													Удалить историю
												</Cell>
											)
										}
										<Cell
											onClick={()=>toggleBlocked()}
											style={{color: "var(--control_tint_muted)"}}
											before={<FontAwesomeIcon style={{marginRight: 15}}  icon={friendStatus === 0 ? faTimesCircle: faCheckCircle} />}
										>
											{friendStatus === 0 ? 'Заблокировать': 'Разблокировать'}
										</Cell>
										<Cell
											onClick={() => {
												setContextOpened(false)
												setComplaintOpened(!complaintOpened)
											}}
											style={{color: "var(--control_tint_muted)"}}
											before={<FontAwesomeIcon style={{marginRight: 15}}  icon={faExclamationTriangle} />}
										>
											Пожаловаться
										</Cell>
									</List>
								</PanelHeaderContext>
								<PanelHeaderContext style={{zIndex: 2}} opened={(complaintOpened && !contextOpened)} onClose={() => setComplaintOpened(false)}>
									<List>
										<Cell>
											<FormLayout>
												<FormLayoutGroup top="Выберите причину">
													<Radio defaultChecked={true} onChange={(e) => setComplaintText(e.currentTarget.value)} value="Оскорбление" name="complaint"><span style={{fontSize: 14}}>Оскорбление</span></Radio>
													<Radio onChange={(e) => setComplaintText(e.currentTarget.value)} value="Спам" name="complaint"><span style={{fontSize: 14}}>Спам</span></Radio>
													<Radio onChange={(e) => setComplaintText(e.currentTarget.value)} value="Реклама интим-услуг" name="complaint"><span style={{fontSize: 14}}>Реклама интим-услуг</span></Radio>
													<Radio onChange={(e) => setComplaintText(e.currentTarget.value)} value="Распространение наркотиков" name="complaint"><span style={{fontSize: 14}}>Распространение наркотиков</span></Radio>
													<Radio onChange={(e) => setComplaintText(e.currentTarget.value)} value="Другое" name="complaint"><span style={{fontSize: 14}}>Другое</span></Radio>
													<Button align='right' level='destructive' onClick={() => sendComplaint()}>Отправить</Button>
												</FormLayoutGroup>
											</FormLayout>
										</Cell>
									</List>
								</PanelHeaderContext>
								{messages && messages.length > 0 ? (
									<ChatMessages messages={messages} userId={dbUser.userInfo.id} room={room} />
								) : (
									<Placeholder
										icon={<Icon56WriteOutline />}
									>
										Начните диалог.
									</Placeholder>
								)}
								<InputMessage sendText={(e) => sendMessage(e)} snackbar={snackbar} />
							</>
						)
				}
			</Panel>
		)
}
export default React.memo(Chat);
