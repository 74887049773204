import React, { useCallback, useState } from "react";
import {
	Button, Caption,
	Div, Footer,
	Group, Headline, Link, List,
	Panel,
	PanelHeader, PanelHeaderBack, PanelSpinner, Snackbar, Text
} from "@vkontakte/vkui";
import { Icon24AddOutline } from '@vkontakte/icons';
import { Icon56RecentOutline } from '@vkontakte/icons';
import { Icon36MoneyCircleOutline } from '@vkontakte/icons';
import { Icon56AccessibilityOutline } from '@vkontakte/icons';
import { Icon56ArticleOutline } from '@vkontakte/icons';
import { appConstants } from "../constants/appConstants";
import image_avatar from "../img/Ikonka_dlya_prilozhenia_monetizatsia.png";
import bridge from "@vkontakte/vk-bridge";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingStatus } from "../actions/actions";

export const StartMonetization = (props) => {
	const { id } = props;
	const [snackbar,setSnackbar] = useState(null);

	const {loadingInner} = useSelector((state) => state);
	const dispatch = useDispatch();

	const row = (title,description,icon) => (
		<div
			className='flexInlineApp'
		>
			<Div>
				{icon}
			</Div>
			<div className='pr-12'>
				<Headline style={{color: 'var(--text_primary)'}}  weight='semibold'>{title}</Headline>
				<Caption style={{color: 'var(--text_primary)'}} weight='medium' level={2}>{description}</Caption>
			</div>
		</div>
	)

	const openSnack = useCallback((snack) => {
		if(snackbar)return;
		setSnackbar(
			<Snackbar
				layout="vertical"
				onClose={() => setSnackbar(null )}
				before={<Icon16CheckCircle fill="var(--field_valid_border)" />}
			>
				{snack.text}
			</Snackbar>
		);
	},[snackbar])

	const sendGroup = async () => {
		dispatch(setLoadingStatus(true))
		await bridge.send("VKWebAppAddToCommunity")
			.then((r) => {
				if(r.group_id)
					openSnack({text: appConstants.MESSAGE_ADD_APP})
			})
			.catch((r) => {
				console.log(r);
			});
		dispatch(setLoadingStatus(false))
	}

	return (
		<Panel id={id}>
			<PanelHeader
				left={<PanelHeaderBack onClick={() => window.history.back()}/>}
			>Монетизация</PanelHeader>
			{
				loadingInner ?
					<PanelSpinner height={150} size="medium" />
					:
					<React.Fragment>
						<Group>
							<Div className='text-center'>
								<Text weight='semibold'>{appConstants.TEXT_HEADER_MONETIZATION}</Text>
								<img src={image_avatar} className='w-100' alt={appConstants.TEXT_MONETIZATION} />
							</Div>
						</Group>
						<Group>
							<Div>
								<Button onClick={sendGroup} before={<Icon24AddOutline />} stretched size='l'>{appConstants.BUTTON_ADD_GROUP_TO_MONETIZATION}</Button>
							</Div>
							<Div>
								<Text weight='medium' className='text-center' style={{color: 'var(--dynamic_red)'}}>{appConstants.TEXT_GROUP_TITLE_MONETIZATION}</Text>
							</Div>
							<List>
								{row(appConstants.TEXT_AUTO_SEND_MONEY, appConstants.TEXT_AUTO_SEND_MONEY_DESCRIPTION, <Icon56RecentOutline style={{color: 'var(--header_tint)'}} />)}
								{row(appConstants.TEXT_MONEY_TITLE, appConstants.TEXT_MONEY_DESCRIPTION, <Icon36MoneyCircleOutline height={56} width={56} style={{color: 'var(--header_tint)'}} />)}
								{row(appConstants.TEXT_RUSSIAN_TITLE, appConstants.TEXT_RUSSIAN_DESCRIPTION, <Icon56AccessibilityOutline style={{color: 'var(--header_tint)'}} />)}
								{row(appConstants.TEXT_DETAIL_STATISTIC_TITLE, appConstants.TEXT_DETAIL_STATISTIC_DESCRIPTION, <Icon56ArticleOutline style={{color: 'var(--header_tint)'}} />)}
							</List>
							<Footer>
								<Div>
									<div className='text-center'>Подключаясь к системе монетизации, Вы соглашаетесь с<br/><Link href='https://vk.com/dev/uterms' target='_blank'>Пользовательским соглашением</Link></div>
								</Div>
							</Footer>
						</Group>
					</React.Fragment>
			}
			{snackbar}
		</Panel>
	);
}
