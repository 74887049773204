import React from "react";
import { Caption, Div, Link, Panel, Title } from "@vkontakte/vkui";
import logo from "../img/welcome-logo.png";
import phone from "../img/phone.png";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import { setActivePage } from "../actions/actions";
import { useDispatch } from "react-redux";
import { Icon24MoneyCircle } from '@vkontakte/icons';
import { Icon24Users3Outline } from '@vkontakte/icons';

const Welcome = ({ id,  }) => {

		const dispatch = useDispatch();

		const openRegister = () => {
			dispatch(setActivePage({
				historyAdd: true,
				activeView: 'viewRegister',
				activePanel: 'register'
			}))
		}

		const openBoot = () => {
			dispatch(setActivePage({
				historyAdd: true,
				activeView: 'viewChatBoot',
				activePanel: 'start'
			}))
		}

		const sendGroup = async () => {
			dispatch(setActivePage({
				historyAdd: true,
				activeView: 'viewMonetization',
				activePanel: 'startMonetization'
			}))
		}

		return (
			<Panel id={id}>
						<div
							className="welcome"
							style={{
								textAlign: 'center',
								color: 'white',
								width: '100%',
								display: 'flex',
								alignItems: 'stretch',
								justifyContent: 'space-evenly',
								flexFlow: 'column',
								position: 'fixed',
								zIndex: 1,
								background: 'linear-gradient(to bottom right,#657bb1,#db597d)'
							}}
						>

							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
									marginTop: 25
								}}
							>
								<img style={{
									paddingTop: 15,
									maxWidth: 75,
								}} alt="Знакомства по городам" src={logo}/>

								<div style={{ textAlign: 'left' }}>
									<Title level={3} weight='medium'>Знакомства по городам</Title>
									<Link target="_blank" href='https://vk.com/znvkapps' id='group-link' className='link-btn mt-'>
										<Button before={<Icon24Users3Outline />} style={{marginTop: 5, color: 'var(--text_primary)', backgroundColor: 'var(--background_keyboard)'}} mode='secondary'  size='s'>
											Официальное сообщество
										</Button>
									</Link>
								</div>
							</div>

							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<div style={{
									flex: '1 1 50%',
									padding: '15px 0px 15px 15px',
								}}>
									<img style={{
										maxWidth: 100,
										width: '100%'
									}}
											 alt="Знакомства по городам"
											 src={phone}/>
								</div>
								<div style={{
									flex: '1 1 50%',
									padding: '15px 15px 15px 0px',
								}}>
									<h4
										style={{ marginTop: 0 }}
									>
										Знакомься с теми,<br/>
										кто рядом с тобой,<br/>
										с помощью карты<br/>
										своего города
									</h4>
									<p>
										- для серьезных отношений<br/>
										- для прогулок<br/>
										- для покатушек<br/>
										- для похода в кино и другие заведения<br/>
									</p>
								</div>
							</div>

							<Div>
								<Caption weight='regular' level={3}>
									Регистрируясь в нашем приложении, Вы подтверждаете, что Вам не менее 18 лет, а также соглашаетесь с
									<br/> <Link style={{ color: 'var(--secondary_text)' }} href="https://vk.com/dev/uprivacy" target='_blank'>политикой
									конфиденциальности</Link> и <Link style={{ color: 'var(--secondary_text)' }} href="https://vk.com/dev/uterms" target="_blank">пользовательским
									соглашением</Link>
								</Caption>
							</Div>
							<div style={{ padding: '0 1em' }}><Button mode='commerce' onClick={openRegister} style={{ width: '100%' }} size='m'>Создать
								анкету через VK</Button></div>

							<div style={{ padding: '0 1em' }}>
								<Button before={<Icon24MoneyCircle />} onClick={sendGroup} mode='secondary'  style={{ width: '100%', color: 'var(--text_primary)', backgroundColor: 'var(--background_keyboard)' }} size='m'>
									<Caption weight='medium' level={1}>Монетизировать сообщество</Caption>
								</Button>
							</div>

							<div style={{ padding: '0 1em' }}>
								<Button onClick={openBoot}  mode='secondary'  style={{ width: '100%', color: 'var(--text_primary)', backgroundColor: 'var(--background_keyboard)' }} size='m'>
									<Caption weight='medium' level={1}>Добавить чат-бота знакомств в свою группу</Caption>
								</Button>
							</div>

						</div>
			</Panel>
		)
}

export default React.memo(Welcome);
