import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	Caption,
	Div, Header,
	Panel,
	PanelHeader, PanelHeaderBack,
	PanelSpinner, Snackbar
} from "@vkontakte/vkui";
import Icon24Story from '@vkontakte/icons/dist/24/story';
import { useSelector } from "react-redux";
import { getOpinionsList, saveStory } from "../components/RequestApi";
import { OpinionRow } from "../components/OpinionRow";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import { postStoryOpinions } from "../helpers/Helper";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";

export const Opinions = (props) => {
	const { id, go } = props;
	const [loading, setLoading] = useState(true);
	const [opinions, setOpinions] = useState(null);
	const [snackbar, setSnackbar] = useState(null);

	const {dbUser, loadingInner} = useSelector(state => state);


	const openSnack = useCallback(
		(snack) => {
			if (snackbar) return;
			setSnackbar(
				<Snackbar
					layout="vertical"
					onClose={() => setSnackbar(null)}
					before={
						snack.type === "danger" ? (
							<Icon16Clear fill="var(--like_text_tint)" />
						) : (
							<Icon16CheckCircle fill="var(--field_valid_border)" />
						)
					}
				>
					{snack.text}
				</Snackbar>
			);
		},
		[snackbar]
	);

	const callbackStory = (uid) => {
		if(uid){
			saveStory({user_id: dbUser.userInfo.id, uid: uid})
				.then(() => {
					openSnack({ text: "История успешно опубликована." });
				})
		}
		setLoading(false);
	}

	const postStory = async () => {
		setLoading(true);
		const text = {
			title_top: 'ОТПРАВЬ АНОНИМНОЕ МНЕНИЕ',
			title_bottom: 'ОБО МНЕ',
			description: 'мнение',
		}
		await postStoryOpinions(dbUser, text, callbackStory, 'story_id');
	}

	useEffect(() => {
		getOpinionsList()
			.then(r => {
				if(r)
					setOpinions(r);
					setLoading(false);
			});
		return () => {
			setOpinions(null);
		}
	},[]);

	return (
		<Panel id={id}>
			<PanelHeader
				left={<PanelHeaderBack onClick={go} data-prev={true} />}
			>Мнения</PanelHeader>
			{
				(loading || loadingInner) ? (
						<PanelSpinner height={150} size="medium" />
				)
					:
					(
						<>
							<Group className='text-center'>
								<Div>
									<Caption level={1} weight='regular' style={{marginBottom: 10}}>
										На данной странице отображены те друзья,
										кто оставил о Вас мнение, перейдя через Вашу опубликованную историю ВК.
										В случае, если Ваше мнение и мнение друга или подруги совпадёт,
										то Вы увидите друг друга на этой странице.
									</Caption>
									{
										dbUser.platform !== 'mobile_web' && (
											<Caption weight='bold' level={2}>
												Для того, чтобы друзья оставили о Вас мнение, необходимо опубликовать историю.
											</Caption>
										)
									}
								</Div>
									<Div>
										<Button
											stretched
											onClick={postStory}
											size='l'
											before={<Icon24Story  />}
										>
											Опубликовать историю
										</Button>
									</Div>
							</Group>
								{
									opinions &&
									<Group
										header={<Header mode='secondary'>Список мнений:</Header>}
									>
										<OpinionRow data={opinions} />
									</Group>
								}
						</>
					)
			}
			<div id='storyId' style={{display: 'none'}}/>
			{snackbar}
		</Panel>
	);
}
