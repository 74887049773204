import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Div,
  Panel,
  PanelHeader,
  PanelHeaderButton,
  PanelHeaderContext,
  Placeholder,
  Snackbar,
  Spacing,
  Spinner
} from "@vkontakte/vkui";
import Icon24Chats from "@vkontakte/icons/dist/24/chats";
import Icon24Like from "@vkontakte/icons/dist/24/like";
import Icon24Settings from "@vkontakte/icons/dist/24/settings";
import Icon28Place from "@vkontakte/icons/dist/28/place";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map as LeafletMap, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import { setActivePage, setActiveRoom, setUserDbData } from "../actions/actions";
import {
  getHandBooks,
  getUsersMap,
  saveFindFromGeo,
  saveUserData,
  sendLike,
  sendNotice
} from "../components/RequestApi";
import SettingsBox from "../components/SettingsBox";
import "react-leaflet-markercluster/dist/styles.min.css";
import Icon56PlaceOutline from "@vkontakte/icons/dist/56/place_outline";
import bridge from "@vkontakte/vk-bridge";
import Icon56HideOutline from "@vkontakte/icons/dist/56/hide_outline";
import { vipConstants } from "../constants/vipConstants";
import { Icon28PlaceOutline } from "@vkontakte/icons";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";

const MapGeo = ({ id, go, openFriend, setModalMessage }) => {
  const { fetchedUser, dbUser, socket, loadingInner } = useSelector(
    (state) => state
  );
  const [usersMap, setUsersMap] = useState([]);
  const [contextOpened, setContextOpened] = useState(false);
  const [sympathyText, setSympathyText] = useState(false);
  const [loading, setLoading] = useState(true);
  const [geoSuccess, setGeoSuccess] = useState(false);
  const [renderMap, setRenderMap] = useState(false);
  const [handbooks, setHandbooks] = useState({
    conditions: false,
  });
  const [snackbar, setSnackbar] = useState(null);

  let mapRef = React.useRef(null);

  const dispatch = useDispatch();
  const southWest = L.latLng(-90, 0);
  const northEast = L.latLng(90, 360);
  const bounds = L.latLngBounds(southWest, northEast);

  const [settings, setSettings] = useState({
    age: [dbUser.userInfo.age_start || 18, dbUser.userInfo.age_end || 60],
    sex: dbUser.userInfo.find_sex || 2,
    condition_id: dbUser.userInfo.find_condition_id || "",
    user_id: dbUser.userInfo.id,
  });

  const openFriendMap = (e) => {
    if (dbUser.userInfo.vip) {
      openFriend(e);
    } else {
      setModalMessage({
        title: vipConstants.FRIEND_PAGE,
        description: vipConstants.GET_SUBSCRIBE,
        icon: <Icon56HideOutline />,
        modalId: "AlertVip",
      });
    }
  };

  const getUsers = useCallback(async () => {
    if (settings) {
      const urlQuery = Object.keys(settings)
        .map(function (key) {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(settings[key])
          );
        })
        .join("&");
      getUsersMap(urlQuery)
        .then((result) => {
          const userData = {
            preview: dbUser.images.avatar.preview || dbUser.userInfo.preview,
            sex: fetchedUser.sex,
            geo_lat: fetchedUser.geo.lat,
            geo_long: fetchedUser.geo.long,
            image: dbUser.images.avatar.main || dbUser.userInfo.image,
            online: 1,
            first_name: fetchedUser.first_name,
            age: fetchedUser.age,
            id: fetchedUser.id,
            condition: dbUser.userInfo.condition_title,
          };
          result.push(userData);
          if (result) {
            setUsersMap(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dbUser, settings, usersMap]);

  const toggleGeo = async () => {
    await bridge
      .send("VKWebAppGetGeodata")
      .then((result) => {
        if (1 === result.available || result.available === true) {
          fetchedUser.geo = result;
          dispatch(
            setUserDbData({
              ...dbUser,
              userInfo: {
                ...dbUser.userInfo,
                allow_geo: 1,
              },
            })
          );
          const requestData = {
            data: {
              allow_geo: 1,
              geo_lat: result.lat,
              geo_long: result.long,
            },
            fetchedUserId: fetchedUser.id,
          };
          saveUserData(requestData)
            .then((result) => {
              if (result) {
                if (settings) {
                  let urlQuery = Object.keys(settings)
                    .map(function (key) {
                      return (
                        encodeURIComponent(key) +
                        "=" +
                        encodeURIComponent(settings[key])
                      );
                    })
                    .join("&");
                  getUsersMap(urlQuery)
                    .then((result) => {
                      const userData = {
                        preview:
                          dbUser.images.avatar.preview ||
                          dbUser.userInfo.preview,
                        sex: fetchedUser.sex,
                        geo_lat: fetchedUser.geo.lat,
                        geo_long: fetchedUser.geo.long,
                        image:
                          dbUser.images.avatar.main || dbUser.userInfo.image,
                        online: 1,
                        first_name: fetchedUser.first_name,
                        age: dbUser.userInfo.age,
                        id: dbUser.userInfo.id,
                        condition: dbUser.userInfo.condition_title,
                      };
                      result.push(userData);
                      if (result) {
                        setUsersMap(result);
                        setGeoSuccess(true);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            })
            .catch((err) => console.log(err));
        } else {
          setGeoSuccess(false);
        }
      })
      .catch((error) => {
        setGeoSuccess(false);
      });
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      toggleGeo();
      getHandBooks(fetchedUser.id)
        .then((res) => {
          if (isMounted) setHandbooks(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const openPopUp = useCallback(
    (e) => {
      if (e.type === "popupopen") {
        if (dbUser.userInfo.vip) {
          return true;
        } else {
          mapRef.current.leafletElement.closePopup();
          setModalMessage({
            title: vipConstants.FRIEND_PROFILE,
            description: vipConstants.SUBSCRIBE_VIEWS_PEOPLE,
            icon: <Icon56PlaceOutline />,
            modalId: "AlertVip",
          });
        }
      }
    },
    [mapRef, dbUser]
  );

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement.on("popupopen", openPopUp);
    }
    return () => {
      if (mapRef.current) {
        mapRef.current.leafletElement.off("popupopen", openPopUp);
      }
    };
  }, [mapRef.current, openPopUp]);

  const saveSettings = useCallback(
    async (e) => {
      setLoading(true);
      await saveFindFromGeo({
        fetchedUserId: fetchedUser.id,
        settings: e,
      })
        .then((result) => {
          if (result) {
            dispatch(
              setUserDbData({
                ...dbUser,
                userInfo: {
                  ...dbUser.userInfo,
                  age_start: e.age[0],
                  age_end: e.age[1],
                  find_sex: e.sex,
                  find_condition_id: e.condition_id,
                },
              })
            );
            setSettings(e);
            setContextOpened(false);
            getUsers();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    },
    [getUsers]
  );

  const openRoom = useCallback(
    async (friendId) => {
      if (dbUser.userInfo.vip) {
        await dispatch(
          setActiveRoom({
            toggleRoom: {
              userId: dbUser.userInfo.id,
              friendId: friendId,
            },
          })
        );
        dispatch(
          setActivePage({
            historyAdd: true,
            activeView: "viewChatRoom",
            activePanel: "chatRoom",
          })
        );
      } else {
        setModalMessage({
          title: vipConstants.FRIEND_PAGE,
          description: vipConstants.GET_SUBSCRIBE,
          icon: <Icon56HideOutline />,
          modalId: "AlertVip",
        });
      }
    },
    [dispatch, dbUser, go]
  );

  const likeUser = useCallback(
    (id, online) => {
      if (dbUser.userInfo.vip) {
        sendLike({ fetchedUserId: dbUser.userInfo.id, userInfoId: id })
          .then((result) => {
            if (result.save) {
              if (online === 0)
                sendNotice({
                  fetchedUserId: fetchedUser.id,
                  userId: id,
                  type: "sympathies",
                });
              if (result.mutual)
                sendNotice({
                  fetchedUserId: fetchedUser.id,
                  userId: id,
                  type: "mutual_sympathies",
                });
              openSnack({text: 'Симпатия отправлена'});
              socket.emit("send notice", { sympathy: true, userId: id });
            } else {
              openSnack({text: 'Вы уже отправляли симпатию!', type: 'danger'});
            }

          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setModalMessage({
          title: vipConstants.FRIEND_PAGE,
          description: vipConstants.GET_SUBSCRIBE,
          icon: <Icon56HideOutline />,
          modalId: "AlertVip",
        });
      }
    },
    [fetchedUser.id, dbUser]
  );

  const openSnack = (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={
            snack.type === "danger" ? (
              <Icon16Clear fill="var(--like_text_tint)" />
            ) : (
              <Icon16CheckCircle fill="var(--field_valid_border)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      );
    };

  return (
    <Panel separator={false} id={id}>
      <PanelHeader
        left={
          geoSuccess && (
            <PanelHeaderButton>
              <Icon24Settings
                onClick={() => setContextOpened(!contextOpened)}
              />
            </PanelHeaderButton>
          )
        }
      >
        Карта
      </PanelHeader>

      {loadingInner ? (
        <>
          <Spinner style={{ marginTop: "50%" }} size="medium" />
        </>
      ) : loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "50% 0",
          }}
        >
          <h5 style={{ color: "var(--loader_track_value_fill)" }}>
            Загружаем карту пользователей
          </h5>
          <br />
          <Icon28Place className="top-animation" fill="var(--dynamic_blue)" />
        </div>
      ) : (
        <>
          {geoSuccess ? (
            <>
              <PanelHeaderContext
                opened={contextOpened}
                onClose={() => setContextOpened(false)}
              >
                    <SettingsBox
                      userSettings={settings}
                      saveSettings={(e) => saveSettings(e)}
                      handbooks={handbooks}
                    />
              </PanelHeaderContext>
              <Div className="map-container">
                <LeafletMap
                  center={[fetchedUser.geo.lat, fetchedUser.geo.long]}
                  zoom={15}
                  minZoom={15}
                  maxZoom={15}
                  maxBounds={bounds}
                  className="markercluster-map"
                  whenReady={() => setRenderMap(true)}
                  attributionControl={false}
                  zoomControl={false}
                  doubleClickZoom={true}
                  scrollWheelZoom={true}
                  dragging={false}
                  animate={true}
                  easeLinearity={0.35}
                  ref={mapRef}
                >
                  <TileLayer
                    attribution=""
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <MarkerClusterGroup
                    showCoverageOnHover={false}
                    spiderfyDistanceMultiplier={2}
                  >
                    {usersMap.length > 0 &&
                      usersMap.map((item, index) => (
                        <Marker
                          key={index}
                          position={[item.geo_lat, item.geo_long]}
                          icon={L.divIcon({
                            iconSize: [50, 50],
                            className:
                              item.sex === 2
                                ? "men vk-marker"
                                : "women vk-marker",
                            html: `<div class="${
                              item.online === 1
                                ? "marker-avatar online-marker"
                                : "marker-avatar"
                            }" style="background: url(${
                              item.preview
                            }) center/cover no-repeat; border-color: ${
                              item.sex === 2
                                ? "var(--men_color)"
                                : "var(--women_color)"
                            }"></div>`,
                          })}
                        >
                          <Popup
                            minWidth={85}
                            closeButton={true}
                            className={
                              item.sex === 2
                                ? "vk-popup popup-men"
                                : "vk-popup popup-women"
                            }
                          >
                            <div className="vk-popup-content">
                              <div
                                onClick={() => {
                                  dbUser.userInfo.id !== item.id &&
                                    openFriendMap(item.id);
                                }}
                                style={{
                                  width: 80,
                                  height: 80,
                                  borderRadius: "50%",
                                  margin: "auto",
                                  background: `url(${item.image}) center/cover no-repeat`,
                                }}
                              ></div>
                              <h4>
                                {item.first_name}, {item.age}
                              </h4>
                              <p
                                style={{
                                  margin: "5px 0",
                                }}
                              >
                                {item.condition_title}
                              </p>
                              {dbUser.userInfo.id !== item.id && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Icon24Like
                                    onClick={() =>
                                      likeUser(item.id, item.online)
                                    }
                                    fill={
                                      item.is_like
                                        ? "var(--dynamic_red)"
                                        : "var(--white)"
                                    }
                                  />
                                  <Icon24Chats
                                    onClick={() => openRoom(item.id)}
                                    fill="var(--white)"
                                  />
                                </div>
                              )}
                            </div>
                          </Popup>
                        </Marker>
                      ))}
                  </MarkerClusterGroup>
                </LeafletMap>
              </Div>
            </>
          ) : (
            <Placeholder
              header="Требуется доступ к геолокации"
              icon={<Icon56PlaceOutline />}
              action={
                <Button
                  before={<Icon28PlaceOutline />}
                  size="l"
                  onClick={toggleGeo}>
                  Поделиться геолокацией
                </Button>
              }
            >
              Для того чтобы отобразить Вас на карте вашего города, а также
              узнать кто находится поблизости от Вас, нам потребуется разрешение
              на просмотр вашей геолокации.
              <Spacing />
              <b style={{color: 'var(--destructive)'}}>
                Включите геолокацию на вашем устройстве перед использованием!
              </b>
            </Placeholder>
          )}
        </>
      )}
      {snackbar}
    </Panel>
  );
};

export default React.memo(MapGeo);
