import React, { useEffect, useRef  } from 'react';
import {getDateTimeZone} from '../helpers/Helper'
const Chat = ({messages, userId, room}) => {
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    };
    useEffect( scrollToBottom,[messages])
return (
    <div
         id="messagesContainer"
    >
        {messages && messages.length > 0 && (
            messages.map((item,index) =>
                <div
                    key={index}
                    className={item.isRead === 0  ? "message isRead" : "message"}
                    style={{
                        justifyContent: userId === item.userId ? 'flex-end' : 'start',
                    }}
                >
                    {userId === item.userId && <span>{
                        getDateTimeZone(item.date_create,'H:mm',false)
                    }</span>}
                    <div
                        className={(userId === item.userId) ? 'fromMe' : (room.sex === 2) ? 'fromFriend men' : 'fromFriend women'}
                        key={index}
                    >{item.message}</div>
                    {userId !== item.userId && <span>{
                        getDateTimeZone(item.date_create,'H:mm',false)
                    }</span>}
                </div>
            )
        )}
        <div ref={messagesEndRef} />
    </div>
)
}
export default Chat
