import React from 'react';
import {
	Avatar,
	Button,
	Div,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	RichCell,
	Title,
	List
} from "@vkontakte/vkui";
import Icon24Flash from '@vkontakte/icons/dist/24/flash';
import Icon28Users3Outline from '@vkontakte/icons/dist/28/users_3_outline';
import Icon24LogoVk from '@vkontakte/icons/dist/24/logo_vk';
import logo from '../img/chat_boot.png';
import { Icon28DiscussionsCircleFillGreen } from '@vkontakte/icons';

export const BootModal = ({openGroupModal, go, id}) => (
		<Panel id={id}>
			<PanelHeader left={<PanelHeaderBack onClick={go} data-prev={true} />}>
				Чат-бот
			</PanelHeader>
			<Div>
				<Title weight='semibold' level={2} style={{textAlign: 'center'}}>Чат-бот знакомств для сообществ</Title>
				<img src={logo} className='logo_boot'/>
				<Title weight='medium' level={3}>Позвольте своим подписчикам знакомиться прямо в Вашей группе.</Title>
			</Div>
			<Div>
				<Button
					before={<Icon28DiscussionsCircleFillGreen />}
					onClick={openGroupModal}
					stretched
					size='l'>Подключить чат-бота</Button>
			</Div>
			<List>
				<RichCell
					multiline
					before={<Avatar size={48}><Icon24Flash fill='var(--dynamic_red)'/></Avatar>}
					caption='Подключение и поддержка бота бесплатна.'
				>
					Бесплатно для сообществ
				</RichCell>
				<RichCell
					multiline
					before={<Avatar size={48}><Icon28Users3Outline width={24} height={24} fill='var(--dynamic_red)'/></Avatar>}
					caption='Подключая бота в свое сообщество,вы получаете полную базу пользователей с приложения и сообществ, где бот подключен.'
				>
					Единая база пользователей
				</RichCell>
				<RichCell
					multiline
					before={<Avatar size={48}><Icon24LogoVk fill='var(--dynamic_red)'/></Avatar>}
					caption='При взаимной сипатии ваши подписчики будут получать ссылки на профили ВКонтакте.'
				>
					Ссылка на человека при симпатии
				</RichCell>
			</List>
		</Panel>
	)
