import React, { useEffect, useState } from "react";
import {
	Group,
	Panel, Div,
	PanelHeader, PanelHeaderBack, Subhead, List, PanelSpinner, Text
} from "@vkontakte/vkui";
import { appConstants } from "../constants/appConstants";
import { useSelector } from "react-redux";
import { getTransactions } from "../components/RequestApi";

export const HistoryTransactions = (props) => {
	const { id, go } = props;
	const { dbUser } = useSelector((state) => state);

	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		if(dbUser.group_id){
			getTransactions(dbUser.group_id)
				.then(data => {
					setTransactions(data.items);
					setLoading(false);
				});
		}
	},[dbUser]);

	return (
		<Panel id={id}>
			<PanelHeader
				left={!loading && <PanelHeaderBack onClick={go} data-prev={true} />}
			>{appConstants.HISTORY_TRANSACTIONS}</PanelHeader>
			{
				loading ?
					<PanelSpinner height={150} size="medium"/>
					:
					<Group
						header={
							<Div>
								<div className='flex-row text-gray'>
									<Subhead weight='semibold'>Сумма</Subhead>
									<Subhead weight='semibold'>Дата</Subhead>
								</div>
							</Div>
						}
						mode="plain">
						<Div>
							<List>
								{
									transactions.length > 0
										?
											transactions.map((item) => <div className='flex-row'><Subhead>- {item.amount} руб.</Subhead><Subhead>{item.date_create}</Subhead></div>)
										:
										<Text weight='semibold'>История выводов пуста.</Text>
								}
							</List>
						</Div>
					</Group>
			}
		</Panel>
	);
}
