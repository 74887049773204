import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Caption,
  Div,
  Header,
  Cell,
  Group,
  Headline,
  CardGrid,
  Card,
  Panel,
  List,
  CellButton,
  PanelHeader,
  PanelSpinner,
  Snackbar,
  Title,
  Subhead,
  Text,
  Link,
  PanelHeaderBack,
  useAdaptivity,
  ViewWidth,
  Spacing,
} from "@vkontakte/vkui";
import { Icon28Users3Outline } from "@vkontakte/icons";
import { Icon24AddSquareOutline } from "@vkontakte/icons";
import { Icon24UsersOutline } from "@vkontakte/icons";
import { Icon24AdvertisingOutline } from "@vkontakte/icons";
import { Icon24PaymentCardOutline } from "@vkontakte/icons";
import { Icon28DiscussionsCircleFill } from "@vkontakte/icons";
import { Icon24MoneyTransfer } from "@vkontakte/icons";
import { appConstants } from "../constants/appConstants";
import { Icon28UserAddBadgeOutline } from "@vkontakte/icons";
import bridge from "@vkontakte/vk-bridge";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage, setUserData, setUserDbData } from "../actions/actions";
import { Icon56PaymentCardOutline } from "@vkontakte/icons";
import { Icon36MoneyCircleOutline } from "@vkontakte/icons";
import { editCreditCard, getStatisticGroup } from "../components/RequestApi";

export const CabinetMonetization = (props) => {
  const { id, setModalMessage, go } = props;
  const { dbUser, fetchedUser } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { viewWidth } = useAdaptivity();

  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const [group, setGroup] = useState({
    balance: 0,
    moneyToday: 0,
    moneyWeek: 0,
    moneyTotal: 0,
    moneyTodayGroup: 0,
    moneyWeekGroup: 0,
    moneyTotalGroup: 0,
    usersToday: 0,
    usersWeek: 0,
    usersTotal: 0,
  });

  useEffect(() => {
    if (dbUser.group_id) {
      getStatisticGroup(dbUser.group_id).then((data) => {
        setGroup(data);
        setLoading(false);
      });
    }
  }, []);

  const row = (title, description, icon) => (
    <div className="flexInlineApp">
      <Div>{icon}</Div>
      <div className="pr-12">
        <Headline style={{ color: "var(--text_primary)" }} weight="semibold">
          {title}
        </Headline>
        <Caption
          style={{ color: "var(--text_primary)" }}
          weight="medium"
          level={2}
        >
          {description}
        </Caption>
      </div>
    </div>
  );

  const openSnack = useCallback(
    (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={<Icon16CheckCircle fill="var(--field_valid_border)" />}
        >
          {snack.text}
        </Snackbar>
      );
    },
    [snackbar]
  );

  const sendGroup = async () => {
    setLoading(true);
    await bridge
      .send("VKWebAppAddToCommunity")
      .then((r) => {
        if (r.group_id) openSnack({ text: appConstants.MESSAGE_ADD_APP });
      })
      .catch((r) => {
        console.log(r);
      });
    setLoading(false);
  };

  const postWall = useCallback(
    async (e) => {
      setLoading(true);
      if (fetchedUser.access_token) {
        let href = `https://vk.com/app${process.env.REACT_APP_APP_ID}_-${dbUser.group_id}?ref=group_menu`;

        await bridge
          .send("VKWebAppShowWallPostBox", {
            message: "Знакомься, общайся, действуй!",
            copyright: `https://vk.com/app${process.env.REACT_APP_APP_ID}_-${dbUser.group_id}?ref=group_menu`,
            attachments: href,
            signed: 0,
            from_group: 1,
            owner_id: -dbUser.group_id,
          })
          .then((result) => {
            if (result) {
              openSnack({ text: "Запись успешно опубликована." });
            }
          })
          .catch((er) => {
            console.log(er);
          });
        setLoading(false);
      } else {
        await bridge
          .send("VKWebAppGetAuthToken", {
            app_id: parseInt(process.env.REACT_APP_APP_ID),
            scope: "photos",
          })
          .then((result) => {
            if (result.access_token) {
              fetchedUser.access_token = result.access_token;
              dispatch(setUserData(fetchedUser));
              postWall();
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    },
    [loading, fetchedUser.access_token]
  );

  const openChatBoot = () => {
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewChatBoot",
        activePanel: "start",
      })
    );
  };

  const sendMoney = () => {
    setModalMessage({
      title: appConstants.TITLE_SEND_MONEY,
      description: appConstants.DESCRIPTION_SEND_MONEY,
      icon: <Icon56PaymentCardOutline />,
      modalId: "HowSendMoney",
    });
  };

  const copyText = () => {
    bridge
      .send("VKWebAppCopyText", {
        text: `https://vk.com/app${process.env.REACT_APP_APP_ID}_-${dbUser.group_id}?ref=group_menu`,
      })
      .then((result) => {
        if (result) {
          openSnack({ text: "Текст скопирован." });
        }
      });
  };

  const addWidget = () => {
    bridge.send("VKWebAppShowCommunityWidgetPreviewBox", {
      group_id: parseInt(dbUser.group_id),
      type: "list",
      code: `return {
				"title": "Знакомства на карте.",
					"rows": [{
						"title": "Просмотреть анкеты.",
						"title_url": "https://vk.com/app${process.env.REACT_APP_APP_ID}_-${dbUser.group_id}?ref=group_menu",
						"button": "Карта",
						"button_url": "https://vk.com/app${process.env.REACT_APP_APP_ID}_-${dbUser.group_id}?ref=group_menu",
						"text": "Тех, кто хочет познакомится рядом с тобой.",
						"icon_id": 'club160113110'
				}]
			};`,
    });
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={
          !loading && <PanelHeaderBack onClick={() => window.history.back()} />
        }
      >
        Монетизация
      </PanelHeader>
      {loading ? (
        <PanelSpinner height={150} size="medium" />
      ) : (
        <React.Fragment>
          <div className="top-right">
            <Icon36MoneyCircleOutline width={198} height={198} />
          </div>
          <Group
            header={
              <Header mode="primary">
                {appConstants.TEXT_MONETIZATION_BALANCE}
              </Header>
            }
            description={appConstants.TEXT_MONEY_GROUP_AMOUNT}
          >
            <Div>
              <Title weight="bold" level={1}>
                {group.balance || 0} руб.
              </Title>
            </Div>
          </Group>
          <Div className={viewWidth !== ViewWidth.MOBILE && "flexInlineApp"}>
            <Button
              onClick={sendMoney}
              before={<Icon24PaymentCardOutline />}
              style={{ marginRight: 15 }}
              size="l"
              stretched
            >
              {appConstants.BUTTON_SEND_MONEY}
            </Button>
            {viewWidth === ViewWidth.MOBILE && <Spacing />}
            <Button
              onClick={go}
              mode="secondary"
              data-view="viewMonetization"
              data-panel="historyTransactions"
              before={<Icon24MoneyTransfer />}
              size="l"
              stretched
            >
              {appConstants.BUTTON_HISTORY_TRANSFERS}
            </Button>
          </Div>
          <Div>
            <Subhead style={{ color: "var(--text_secondary)" }} weight="bold">
              <div
                dangerouslySetInnerHTML={{
                  __html: appConstants.TEXT_MOTIVATION,
                }}
              />
            </Subhead>
          </Div>
          <Group
            separator="hide"
            header={<Header>{appConstants.TEXT_MONEY_ALL_GROUPS}</Header>}
          >
            <CardGrid size="s">
              <Card mode="shadow">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.moneyToday || 0}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_TODAY}
                  </Subhead>
                </Div>
              </Card>
              <Card mode="shadow">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.moneyWeek || 0}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_WEEK}
                  </Subhead>
                </Div>
              </Card>
              <Card mode="shadow">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.moneyTotal || 0}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_TOTAL}
                  </Subhead>
                </Div>
              </Card>
            </CardGrid>
          </Group>
          <Div>
            <Subhead style={{ color: "var(--text_secondary)" }} weight="medium">
              {appConstants.TEXT_CONTROL_GROUP}
            </Subhead>
            <Title weight="bold" level={1}>
              {dbUser.group_ref.name}
            </Title>
          </Div>
          <CardGrid size="l">
            <Card>
              <Div>
                <Text weight="medium">{appConstants.TEXT_GET_SELLS}</Text>
              </Div>
              <List>
                <Div className="center-div">
                  <Button
                    onClick={postWall}
                    before={<Icon24AdvertisingOutline />}
                    size="l"
                  >
                    {appConstants.BUTTON_SAY_FRIENDS}
                  </Button>
                </Div>
                <Div className="center-div">
                  <Button
                    onClick={addWidget}
                    before={<Icon24AddSquareOutline />}
                    size="l"
                  >
                    {appConstants.BUTTON_ADD_WIDGET}
                  </Button>
                </Div>
                <Div className="center-div">
                  <Button
                    onClick={openChatBoot}
                    before={<Icon24UsersOutline />}
                    size="l"
                  >
                    {appConstants.BUTTON_ADD_CHAT_BOOT}
                  </Button>
                </Div>
                <Group
                  header={
                    <Header mode="secondary">Ваша реферальная ссылка</Header>
                  }
                  description="Нажмите на ссылку, для копирования в буфер обмена."
                >
                  <CellButton onClick={copyText}>
                    https://vk.com/app{process.env.REACT_APP_APP_ID}_-
                    {dbUser.group_id}?ref=group_menu
                  </CellButton>
                </Group>
              </List>
            </Card>
          </CardGrid>
          <Group
            header={<Header>{appConstants.TEXT_MONEY}</Header>}
            description={appConstants.TEXT_MONEY_GROUP}
          >
            <CardGrid size="s">
              <Card mode="outline">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.moneyTodayGroup || 0}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_TODAY}
                  </Subhead>
                </Div>
              </Card>
              <Card mode="outline">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.moneyWeekGroup || 0}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_WEEK}
                  </Subhead>
                </Div>
              </Card>
              <Card mode="outline">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.moneyTotalGroup || 0}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_TOTAL}
                  </Subhead>
                </Div>
              </Card>
            </CardGrid>
          </Group>
          <Group
            header={<Header>{appConstants.TEXT_REGISTER}</Header>}
            description={appConstants.TEXT_REGISTER_GROUP}
          >
            <CardGrid size="s">
              <Card mode="outline">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.usersToday}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_TODAY}
                  </Subhead>
                </Div>
              </Card>
              <Card mode="outline">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.usersWeek}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_WEEK}
                  </Subhead>
                </Div>
              </Card>
              <Card mode="outline">
                <Div className="center-div">
                  <Title weight="semibold" level={2}>
                    {group.usersTotal}
                  </Title>
                  <Subhead
                    style={{ color: "var(--text_secondary)" }}
                    weight="bold"
                  >
                    {appConstants.TEXT_TOTAL}
                  </Subhead>
                </Div>
              </Card>
            </CardGrid>
          </Group>
          <Group>
            <CellButton
              onClick={(e) => document.getElementById("faq_href").click()}
              before={
                <Icon28DiscussionsCircleFill style={{ marginRight: "10px" }} />
              }
            >
              <Link
                id="faq_href"
                href="https://vk.com/@-160113110-monetizaciya-soobschestv-vkontakte-zarabatyvaite-na-svoei-gr"
                target="_blank"
              >
                {appConstants.TEXT_FAQ}
              </Link>
            </CellButton>
            <CellButton
              onClick={(e) =>
                document.getElementById("official_group_href").click()
              }
              before={<Icon28Users3Outline style={{ marginRight: "10px" }} />}
            >
              <Link
                id="official_group_href"
                href="https://vk.com/public160113110"
                target="_blank"
              >
                {appConstants.TEXT_OFFICIAL_GROUP}
              </Link>
            </CellButton>
            <CellButton
              onClick={sendGroup}
              before={
                <Icon28UserAddBadgeOutline style={{ marginRight: "10px" }} />
              }
            >
              {appConstants.TEXT_CONNECT}
            </CellButton>
          </Group>
        </React.Fragment>
      )}
      {snackbar}
    </Panel>
  );
};
