import React, { useState } from "react";
import {
  FixedLayout,
  Group,
  WriteBar,
  WriteBarIcon,
} from "@vkontakte/vkui";

const InputMessage = ({ sendText, snackbar }) => {
  const [text, setText] = useState("");

  const sendMessage = () => {
    const regResult = text.match(/\s*/i)[0].length === text.length;
    if (text && text.length < 100 && !regResult) {
      sendText(text);
      setText("");
    } else {
    }
  };

  return (
    <FixedLayout
      vertical="bottom"
      className="chatRoom"
      style={{
        zIndex: 1,
        background: "var(--background_content)",
      }}
    >
      <Group
        separator='hide'
      >
        <WriteBar
          style={{
            background: 'var(--background_page)',
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          after={
            <WriteBarIcon
              onClick={sendMessage}
              mode="send"
              disabled={text.length === 0}
            />
          }
          placeholder="Наберите сообщение..."
        />
      </Group>
      {snackbar}
    </FixedLayout>
  );
};
export default InputMessage;
