import React, {useState} from "react";
import { Button, FormItem, Group, Header, List, RangeSlider, Select } from "@vkontakte/vkui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFemale} from "@fortawesome/free-solid-svg-icons/faFemale";
import {faMale} from "@fortawesome/free-solid-svg-icons/faMale";

const SettingsBox = ({userSettings,saveSettings,handbooks}) => {
    const [settings,setSettings] = useState(userSettings);
    const [count,setCount] = useState(0)
    const setSettingsData = (e) => {
        settings[e.name] = e.data
        setSettings(settings);
        setCount(count + 1)
    }
    return (
      <List
        className="settingsBox"
      >
        <Header mode='primary' style={{justifyContent: 'center'}}>Фильтр отображения</Header>
          <FormItem
            top='Кого отобразить?'
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <label htmlFor="womenIcon" className="label-s">
                <input onChange={(e) => setSettingsData({name: "sex", data: parseInt(e.currentTarget.value)})} id="womenIcon" defaultChecked={settings.sex === 1} type="radio" value={1} name="sex"
                       style={{display: "none"}}/>
                <div className="womenIcon">
                  <FontAwesomeIcon
                    style={{
                      display: 'block',
                      margin: 'auto',
                    }}
                    size="2x"
                    color="var(--dynamic_red)"
                    icon={faFemale}/>
                  <span>Девушки</span>
                </div>
              </label>
              <label htmlFor="menIcon" className="label-s">
                <input onChange={(e) => setSettingsData({name: "sex", data: parseInt(e.currentTarget.value)})} id="menIcon" defaultChecked={settings.sex === 2} type="radio" value={2} name="sex" style={{display: "none"}}/>
                <div className="menIcon">
                  <FontAwesomeIcon
                    style={{
                      display: 'block',
                      margin: 'auto',
                    }}
                    color="var(--dynamic_blue)"
                    size="2x"
                    icon={faMale}/>
                  <span>Парни</span>
                </div>
              </label>
            </div>
          </FormItem>
          <FormItem
            top='Возраст:'
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <span>От: {settings.age[0]}</span>
              <span>До: {settings.age[1]}</span>
            </div>
            <RangeSlider
              defaultValue={[settings.age[0],settings.age[1]]}
              name="age"
              min={18}
              max={90}
              step={1}
              onChange={(e) => setSettingsData({name:"age",data: e})}
            />
          </FormItem>
          <FormItem
            top='Цель знакомства:'
          >
            <Select
              value={settings.condition_id}
              placeholder='Все цели'
              onChange={(e) => setSettingsData({name: "condition_id", data: e.currentTarget.value })}
              style={{
                margin: ".5em 0"
              }}
              options={handbooks.conditions.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </FormItem>
          <FormItem>
            <Button
              stretched
              size="l"
              onClick={() => saveSettings(settings)}>Сохранить</Button>
          </FormItem>
      </List>
    )
}
export default SettingsBox
