import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Panel, PanelHeader, PanelHeaderBack, Spinner } from "@vkontakte/vkui";
import { setActivePage, setUserDbData, setEditData } from "../actions/actions";
import { getHandBooks, editUserDb } from "../components/RequestApi";
import { ProfileEditForm } from "../components/Form/ProfileEditForm";

const mapStateToProps = (state = {}) => {
  return { ...state };
};

const EditProfile = ({
  id,
  fetchedUser,
  dbUser,
  editData,
  dispatch,
  setAlertDefault,
  setAlertImagesError,
}) => {
  const [loading, setLoading] = useState(true);
  const [handbooks, setHandbooks] = useState(null);

  useEffect(() => {
    let isMounted = true;
    getHandBooks(fetchedUser.id)
      .then((result) => {
        if (isMounted) setHandbooks(result);
      })
      .catch((err) => {
        console.log(err);
      });

    if (!editData) {
      dispatch(setEditData(dbUser));
    }

    setLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  const editUser = useCallback(async () => {
    console.log(editData);
    editUserDb(editData)
      .then((result) => {
        if (result) {
          handbooks.conditions.some((item) => {
            if (item.id === editData.userInfo.condition_id)
              editData.userInfo.condition_title = item.title;
          });
          dispatch(setUserDbData(editData));
          dispatch(
            setActivePage({
              historyNew: true,
              activeView: "viewProfile",
              activePanel: "profile",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [handbooks, editData]);

  return (
    <Panel separator={false} id={id}>
      <PanelHeader
        left={<PanelHeaderBack onClick={() => window.history.back()} />}
      >
        Ваши данные
      </PanelHeader>
      {editData && !loading ? (
        <ProfileEditForm
          sendData={editUser}
          setAlertImagesError={setAlertImagesError}
          setAlertDefault={setAlertDefault}
        />
      ) : (
        <Spinner style={{ marginTop: "50%" }} size="medium" />
      )}
    </Panel>
  );
};
export default connect(mapStateToProps)(React.memo(EditProfile));
