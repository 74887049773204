import {
  Button,
  Group,
  Header,
  Link,
  List,
  MiniInfoCell,
  ModalCard,
  ModalPage,
  ModalPageHeader,
  ModalRoot,
  PanelHeaderButton
} from "@vkontakte/vkui";
import React, { useCallback, useEffect, useState } from "react";
import Icon56PlaceOutline from "@vkontakte/icons/dist/56/place_outline";
import Icon56DoNotDisturbOutline from "@vkontakte/icons/dist/56/do_not_disturb_outline";
import Icon56CheckCircleOutline from "@vkontakte/icons/dist/56/check_circle_outline";
import Icon56DiamondOutline from "@vkontakte/icons/dist/56/diamond_outline";
import { Icon28FavoriteCircleFillGreen, Icon56AdvertisingOutline } from "@vkontakte/icons";

import Icon24Dismiss from "@vkontakte/icons/dist/24/dismiss";
import Icon24Chats from "@vkontakte/icons/dist/24/chats";
import Icon24Place from "@vkontakte/icons/dist/24/place";
import Icon24View from "@vkontakte/icons/dist/24/view";
import Icon24LogoVk from "@vkontakte/icons/dist/24/logo_vk";

import Icon44SmileOutline from "@vkontakte/icons/dist/44/smile_outline";

import { useDispatch, useSelector } from "react-redux";
import { getFreeVIP, getOrderData, getVipAdw, saveStory } from "./RequestApi";
import { setLoadingStatus, setUserData, setUserDbData } from "../actions/actions";
import Radio from "@vkontakte/vkui/dist/components/Radio/Radio";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import { getImageStory } from "../helpers/CanvasStory";
import bridge from "@vkontakte/vk-bridge";
import { OpinionStory } from "../helpers/OpinionStory";
import { appConstants } from "../constants/appConstants";
import { vipConstants } from "../constants/vipConstants";
import { postStoryOpinions } from "../helpers/Helper";

const UIDGenerator = require('uid-generator');
const uidGen = new UIDGenerator();

export const Modals = ({activeModal,setActiveModal}) => {
    const [modalInfo, setModal] = useState(activeModal);
    const [freeVip, setFreeVip] = useState(false);

    const { dbUser, fetchedUser } = useSelector(state => state);
    const dispatch = useDispatch();

    const dataOpinion = {
      name: dbUser && dbUser.userInfo.first_name,
      age: dbUser && dbUser.userInfo.age,
      city: dbUser && dbUser.userInfo.city_title,
      image: dbUser && dbUser.images.avatar.main,
    }

  const getAds = async () => {
    const data = await bridge.send("VKWebAppShowNativeAds", {
      ad_format: "reward",
    })
      .catch(er => {
        console.log('adw error: ', er)
      });

    await new Promise(res => setTimeout(res, 1500));

    if (data.result){
      const request = await getVipAdw();
      if (request.vip_time) {
        dispatch(setUserDbData({
          ...dbUser,
          userInfo: {
            ...dbUser.userInfo,
            vip: true,
            vip_time: request.vip_time
          }
        }));
        setModal({
          ...modalInfo,
          description: "Вы получили бесплатный VIP статус на 5 минут.",
          modalId: "successFreeVip"
        });
      }
    }

  };
  const setStory = (e) => {
        dispatch(setUserData({...fetchedUser, storyChecked: e}))
    }

    const requestFreeVIP = async () => {
        const free = await getFreeVIP({UserId: dbUser.userInfo.id});
        if(free.status){
            dispatch(setUserDbData({
                ...dbUser,
                userInfo: {
                    ...dbUser.userInfo,
                    vip: true,
                    vip_time: free.vip_time
                }
            }));
            setModal({
                ...modalInfo,
                description: 'Вы получили бесплатный VIP статус на 15 минут.',
                modalId: 'successFreeVip'
            });
        }
    }

    const postStory = useCallback(async () => {
        if(fetchedUser.storyChecked){

            setActiveModal({...modalInfo, modalId: null});

            window.dispatchEvent(new CustomEvent('send story', {
                detail: {
                    main: false,
                    status: true,
                }
            }));

            const image = await getImageStory(dbUser,fetchedUser.storyChecked)
            await bridge.send("VKWebAppShowStoryBox", {
                "background_type": "image",
                "locked": true,
                "blob": image,
                "attachment": {
                    text: 'open',
                    type: 'url',
                    url: 'https://vk.com/app'+process.env.REACT_APP_APP_ID+'#user_id='+dbUser.userInfo.id
                }
            }).then(response => {
                if(freeVip && response.result)
                {
                    requestFreeVIP();
                }
            }).catch(error => {
                console.log(error)
            })

            window.dispatchEvent(new CustomEvent('send story', {
                detail: {
                    main: true,
                    status: false,
                }
            }));
        }
    },[fetchedUser.storyChecked,dbUser,freeVip]);

  const sendStoryOpinion = async () => {
    if(dbUser){
      await OpinionStory(dataOpinion)
        .then( async canvas => {
          if(canvas){
            const uid = await uidGen.generate();
            bridge.send("VKWebAppShowStoryBox", {
              "background_type": "image",
              "locked": true,
              "blob": canvas,
              "attachment": {
                text: 'open',
                type: 'url',
                url: 'https://vk.com/app'+process.env.REACT_APP_APP_ID+'#story_id='+uid
              }
            })
              .then((r) => {
                if(r.result)
                {
                  saveStory({user_id: dbUser.userInfo.id, uid: uid})
                    .then((r) => {
                      if(r){
                        requestFreeVIP();
                      }
                    })
                }
              })
              .catch((er) => {
                console.log(er);
              });
          }
        });
      setActiveModal({...modalInfo, modalId: null});
    }
  }

  const callbackStory = (uid) => {
    saveStory({user_id: dbUser.userInfo.id, uid: uid})
      .then(() => {
        dispatch(setLoadingStatus(false))
      })
  }

  const postSympathyStory = async () => {
    dispatch(setLoadingStatus(true))
    setActiveModal(null)
    const text = {
      title_top: 'Я ТЕБЕ НРАВЛЮСЬ?',
      title_bottom: '',
      description: 'симпатию',
    }
    await postStoryOpinions(dbUser, text, callbackStory, 'user_id');
  }


  useEffect(() => {
        if(modalInfo.modalType !== 'free' && freeVip)
            setFreeVip(false);
    },[modalInfo.modalId]);

    useEffect(() => {
        setModal(activeModal);
    },[activeModal]);

    return (
        <ModalRoot onClose={() => setActiveModal(null)} activeModal={modalInfo.modalId}>
            <ModalCard
                id="AlertGeo"
                onClose={() => setActiveModal(null)}
                icon={<Icon56PlaceOutline/>}
                title="Включите Geo локацию на вашем устройстве."
                subheader="Для корректной работы необходимо Ваше местоположение, чтобы мы смогли Вас отобразить на карте, а также тех, кто находится рядом с Вами!"
                actions={<Button onClick={() => setActiveModal(null)} size="l" mode="primary">{appConstants.BUTTON_CLOSE}</Button>}
            >
            </ModalCard>
            <ModalCard
                id="successFreeVip"
                onClose={() => setActiveModal(null)}
                icon={<Icon56CheckCircleOutline/>}
                header={vipConstants.CONGRATULATE}
                subheader={`${modalInfo.description}`}
                actions={<Button onClick={() => setActiveModal(null)} size="l" mode="primary">{appConstants.BUTTON_CLOSE}</Button>}
            >
            </ModalCard>
            <ModalCard
              id="HowSendMoney"
              onClose={() => setActiveModal(null)}
              icon={modalInfo.icon}
              header={modalInfo.title}
              subheader={modalInfo.description}
              actions={<Button onClick={() => setActiveModal(null)} size="l" mode="primary">{appConstants.BUTTON_CLOSE}</Button>}
            >
            </ModalCard>
            <ModalCard
                id="AlertVip"
                onClose={() => setActiveModal(null)}
                icon={dbUser && dbUser.isIos ? <Icon56DoNotDisturbOutline/> : modalInfo.icon}
                header={dbUser && dbUser.isIos ? (!dbUser.group_id ? "Внимание!" : "Пока недоступно, сожалеем!") : modalInfo.title || null}
                subheader={dbUser && dbUser.isIos ? (!dbUser.group_id ? 'Для получения VIP статуса просмотрите рекламный ролик.' : "В скором времени данная возможность станет доступна!") : modalInfo.description || null}
                actions={dbUser && dbUser.isIos ?
                  (
                    (!dbUser.group_id) ?
                      <React.Fragment>
                        <Button onClick={getAds} size="l" mode="primary">Просмотр</Button>
                        <Button onClick={() => setActiveModal(null)} size="l" mode="secondary">{appConstants.BUTTON_CLOSE}</Button>
                      </React.Fragment>
                    :
                      <Button onClick={() => setActiveModal(null)} size="l" mode="secondary">{appConstants.BUTTON_CLOSE}</Button>
                  )
                    :
                  <React.Fragment>
                    <Button onClick={() => setActiveModal(null)} size="l" mode="secondary">{appConstants.BUTTON_CLOSE}</Button>
                    <Link target='_blank' href={process.env.REACT_APP_DONUT_URL_WEB} className='link-btn'>
                      <Button size="l" mode="primary">{appConstants.BUTTON_GET_SUBSCRIBE}</Button>
                    </Link>
                  </React.Fragment>
                }
            >
            </ModalCard>
            <ModalCard
                id='story'
                onClose={() => setActiveModal(null)}
                icon={<Icon44SmileOutline width={56} height={56} />}
                header='Публикация истории'
                subheader={modalInfo.description || 'Выберите статус истории'}
                actions={
                  <React.Fragment>
                    <Button onClick={postStory} size="l" mode="primary">Создать</Button>
                    <Button onClick={() => setActiveModal(null)} size="l" mode="secondary">{appConstants.BUTTON_CLOSE}</Button>
                  </React.Fragment>
                }
            >
                <Div>
                    <List>
                            <Radio defaultChecked={fetchedUser.storyChecked === 'relation'} onChange={(e) => setStory(e.currentTarget.value)} value='relation' name="complaint"><span style={{fontSize: 14}}>Серьезные отношения</span></Radio>
                            <Radio defaultChecked={fetchedUser.storyChecked === 'communication'} onChange={(e) => setStory(e.currentTarget.value)} value='communication' name="complaint"><span style={{fontSize: 14}}>Общение</span></Radio>
                            <Radio defaultChecked={fetchedUser.storyChecked === 'car'} onChange={(e) => setStory(e.currentTarget.value)} value='car' name="complaint"><span style={{fontSize: 14}}>Прокатиться</span></Radio>
                            <Radio defaultChecked={fetchedUser.storyChecked === 'come'} onChange={(e) => setStory(e.currentTarget.value)} value='come' name="complaint"><span style={{fontSize: 14}}>Прогуляться</span></Radio>
                            <Radio defaultChecked={fetchedUser.storyChecked === 'film'} onChange={(e) => setStory(e.currentTarget.value)} value='film' name="complaint"><span style={{fontSize: 14}}>Сходить в кино</span></Radio>
                            <Radio defaultChecked={fetchedUser.storyChecked === 'restaurant'} onChange={(e) => setStory(e.currentTarget.value)} value='restaurant' name="complaint"><span style={{fontSize: 14}}>Сходить в заведение</span></Radio>
                    </List>
                </Div>
            </ModalCard>
            <ModalCard
                id='newUserVip'
                onClose={() => setActiveModal(null)}
                icon={<Icon56DiamondOutline />}
                header='Вам доступен бесплатный VIP-статус'
                subheader='Получите бесплатный VIP-статус на 15 минут, а также узнайте как к вам относятся ваши друзья и кому вы нравитесь с помощью публикации истории ВКонтакте'
                actions={
                  <React.Fragment>
                    <Button onClick={sendStoryOpinion} size="l" mode="primary">Создать</Button>
                    <Button onClick={() => setActiveModal(null)} size="l" mode="secondary">{appConstants.BUTTON_CLOSE}</Button>
                  </React.Fragment>
                }
            >
              <div id='storyId' style={{display: 'none'}}/>
            </ModalCard>
            <ModalPage
                id="modalPay"
                onClose={() => setActiveModal(null)}
                header={
                    <ModalPageHeader
                        right={<PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
                    >
                      Оформление VIP
                    </ModalPageHeader>
                }
                settlingHeight={40}
            >
                <Group>
                    <MiniInfoCell textWrap='short' before={<Icon24Chats fill="var(--dynamic_green)" />}>Пиши пользователям без взаимной симпатии</MiniInfoCell>
                    <MiniInfoCell textWrap='short' before={<Icon24Place fill="var(--dynamic_red)" />}>Пиши тем, кто находится рядом с тобой</MiniInfoCell>
                    <MiniInfoCell textWrap='short' before={<Icon24View fill="var(--dynamic_violet)" />}>Смотри, кто заходил на твою страницу</MiniInfoCell>
                    <MiniInfoCell textWrap='short' before={<Icon24LogoVk fill="var(--dynamic_blue)" />}>Возможность смотреть страницы VK пользователей</MiniInfoCell>
                </Group>
                <Group
                    header={<Header className='flex-center' mode="secondary">{vipConstants.TEXT_PERIOD}</Header>}
                >
                  <Div>
                    <Link target='_blank' href={process.env.REACT_APP_DONUT_URL_WEB} className='link-btn'>
                      <Button
                        stretched
                        before={<Icon28FavoriteCircleFillGreen />}
                        size="l">{vipConstants.TEXT_BY_SUBSCRIBE}</Button>
                    </Link>
                  </Div>
                </Group>
            </ModalPage>
            <ModalCard
              id='firstVisitDay'
              onClose={() => setActiveModal(null)}
              icon={<Icon56AdvertisingOutline />}
              header='Узнай кто из твоих друзей испытывает к тебе симпатию'
              subheader='Опубликуй историю и лови на чувствах тех, кому ты нравишься!'
              actions={
                <React.Fragment>
                  <Button
                    onClick={() => setActiveModal(null)} size="l" mode="destructive">{appConstants.BUTTON_CLOSE}</Button>
                  <Button
                    onClick={postSympathyStory} size="l" mode="primary">Опубликовать</Button>
                </React.Fragment>
              }
            >
              <div id='storyId' style={{display: 'none'}}/>
            </ModalCard>
        </ModalRoot>
    );
}
