import React, { useCallback, useEffect, useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUser,
  getUserBlackList,
  getUserByVKID,
} from "./components/RequestApi";
import { Modals } from "./components/Modals";
import {
  AlertDeleteProfile,
  AlertErrorImage,
  AlertWarningDefault,
  AlertWarning,
} from "./components/Alerts";
import {
  initialSocket,
  setFriendId,
  setActivePage,
  setUserData,
  setUserDbData,
  setLoadingStatus,
} from "./actions/actions";
import {
  Avatar,
  Epic,
  Tabbar,
  TabbarItem,
  View,
  IconButton, withAdaptivity, Counter
} from "@vkontakte/vkui";
import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import { Icon28NarrativeOutline } from "@vkontakte/icons";
import { Icon28GlobeOutline } from "@vkontakte/icons";
import { Icon28UserCircleOutline } from "@vkontakte/icons";
import { Icon28Notifications } from "@vkontakte/icons";
import { Icon28AddOutline } from "@vkontakte/icons";
import { Icon28CancelOutline } from '@vkontakte/icons';

import Home from "./panels/Home";
import Profile from "./panels/Profile";
import MapGeo from "./panels/MapGeo";
import MessagesList from "./panels/MessagesList";
import Chat from "./panels/Chat";
import Register from "./panels/Register";
import EditProfile from "./panels/EditFrofile";
import Welcome from "./panels/WelcomePanel";
import Friend from "./panels/Friend";
import ImagesList from "./panels/ImagesList";
import Offline from "./panels/Offline";
import GetCityUserEdit from "./panels/GetCityUserEdit";
import Country from "./panels/Country";
import { Opinions } from "./panels/Opinions";
import { AddOpinion } from "./panels/addOpinion";
import { BootModal } from "./components/BootModal";
import { BootGroupModal } from "./components/BootGroupModal";
import { BootGroupsList } from "./components/BootGroupsList";
import eruda from "eruda";
import { vipConstants } from "./constants/vipConstants";
import { StartMonetization } from "./panels/StartMonetization";
import { CabinetMonetization } from "./panels/CabinetMonetization";
import { HistoryTransactions } from "./panels/HistoryTransactions";
import { TabbarItemsConstants } from "./constants/TabbarItemsConstants";
import { TabbarWrapperMenu } from "./components/TabbarWrapperMenu";
import { hasFirstStory } from "./helpers/Helper";

let isMobile = false;
const ios = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
if (ios) {
  isMobile = navigator.plugins.length === 0;
} else {
  isMobile = navigator.plugins.length === 0 && navigator.maxTouchPoints > 1;
}

// Init VK  Mini App
bridge
  .send("VKWebAppInit", {})
  .then((result) => {
    console.log("load success: ", result);
  })
  .catch((error) => {
    console.log("load errors: ", error);
  });

/*bridge.subscribe(({ detail: { type, data }}) => {
    if (type === 'VKWebAppOpenPayFormFailed' || type === 'VKWebAppOpenPayFormResult') {
        console.log('rrr: ', data,type);
    }
});*/

bridge.subscribe(({ detail: { type, data } }) => {
  if (type === "VKWebAppUpdateConfig") {
    const schemeAttribute = document.createAttribute("scheme");
    schemeAttribute.value = data.scheme ? data.scheme : "client_light";
    document.body.attributes.setNamedItem(schemeAttribute);
  }
});

const App = () => {
  const { fetchedUser, activePage, dbUser, socket, loadingInner } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const [activeModal, setActiveModal] = useState({
    ios: false,
    text: null,
    modalId: null,
  });
  const [activeTabMenu, setActiveTabMenu] = useState(false);
  const [popout, setPopout] = useState(null);
  const [totalNotice, setTotalNotice] = useState(0);
  const [online, setOnline] = useState(true);
  const [income, setIncome] = useState({
    status: false,
    userFind: false,
    welcome: false,
  });
  const openFriend = useCallback(
    async (e) => {
      await dispatch(setFriendId(e));
      dispatch(
        setActivePage({
          activeView: "viewFriend",
          activePanel: "friend",
          historyAdd: true,
        })
      );
    },
    [activePage, dispatch]
  );

  const setModal = (e) => {
    setActiveModal({
      description: e.description || null,
      title: e.title || null,
      modalId: e.modalId || null,
      icon: e.icon || false,
      modalType: e.modalType || false,
      callback: e.callback || null,
    });
  };

  const openGroupModal = () => {
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewChatBoot",
        activePanel: "chatBootGroups",
      })
    );
  };

  useEffect(() => {
    if (socket && dbUser) {
      socket.on("income notice", (data) => {
        if((data.sympathy || data.guest) && activePage.activeView !== "viewMessages") {
          setTotalNotice(totalNotice + 1);

          dispatch(setUserDbData({...dbUser,
            notice_info: {
              ...dbUser.notice_info,
              sympathies: dbUser.notice_info.sympathies + (data.sympathy ? 1 : 0),
              total_views: dbUser.notice_info.total_views + (data.guest ? 1 : 0),
            }
          }));
        }
      });

      socket.on("pay notice", (data) => {
        if (data.vip) {
          dispatch(
            setUserDbData({
              ...dbUser,
              userInfo: {
                ...dbUser.userInfo,
                vip: true,
                vip_time: data.time,
              },
            })
          );
          setActiveModal({
            ...activeModal,
            description: vipConstants.SUBSCRIBE_SUCCESS,
            modalId: "successFreeVip",
          });
          if (dbUser.platform !== "mobile_web") {
            dispatch(setLoadingStatus(false));
          }
        }
      });
    }
  }, [socket, dbUser]);

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      e.preventDefault();
      if (window.total_history > 1) {
        setActiveModal({
          ios: false,
          modalId: null,
          text: null,
        });
        setPopout(false);
        dispatch(setActivePage({ historyDelete: true }));
      } else {
        console.log("close app");
        bridge.send("VKWebAppClose", {
          status: "success",
          payload: { name: "test" },
        });
      }
    });

    window.addEventListener("offline", function (e) {
      setOnline(false);
    });

    window.addEventListener("online", function (e) {
      setOnline(true);
    });

    let isMounted = true;
    if (isMounted) {
      const getCurrentAge = (date) => {
        return (
          ((new Date().getTime() - new Date(date)) /
            (24 * 3600 * 365.25 * 1000)) |
          0
        );
      };
      const fetchData = () => {
        let userCity = {
          city: {
            id: 0,
          },
          country: {
            id: 0,
          },
        };
        bridge
          .send("VKWebAppGetUserInfo")
          .then((result) => {
            if (!result) {
              return;
            }
            const user = Object.assign(userCity, result);

            if (user.bdate && user.bdate.split(".").length > 2) {
              const datePerson = getCurrentAge(
                new Date(user.bdate.split(".").reverse().join("/"))
              );

              user.age = datePerson;
              user.ageVK = datePerson;
            } else {
              user.ageVK = false;
              user.age = false;
            }
            user.images = {
              avatar: {
                main: false,
                preview: false,
                image_id: null,
              },
              other: [false, false, false, false],
            };

            user.access_token = false;
            user.find_age_start = 18;
            user.find_age_end = 32;
            user.storyChecked = "relation";
            user.find_sex = user.sex === 1 ? 2 : 1;
            if (user.city.id !== 0 && user.country.id !== 0)
              user.cityValid = true;
            getUserBlackList({ fetchedUserId: user.id })
              .then(async (result) => {
                if (result.status) {
                  user.blacklist = true;
                  dispatch(
                    setUserData({ ...user, blackListData: result.data })
                  );
                  setIncome({
                    status: true,
                  });
                  dispatch(
                    setActivePage({
                      historyNew: true,
                      activeView: "viewRegister",
                      activePanel: "register",
                    })
                  );
                } else {
                  dispatch(setUserData(user));
                  const findUserBd = await getUserByVKID(user.id);
                  if (findUserBd) {
                    getUser({
                      userId: findUserBd.id,
                    }).then(async (result) => {
                      const searchParams = new URLSearchParams(
                        window.location.search
                      );
                      dispatch(initialSocket());

                      let group_ref = null;

                      if (searchParams.get("vk_group_id")) {
                        group_ref = await bridge
                          .send("VKWebAppGetGroupInfo", {
                            group_id: parseInt(searchParams.get("vk_group_id")),
                          })
                          .catch(() => {
                            return false;
                          });
                      }

                      const isVkClient =
                        searchParams.get("vk_platform") === "mobile_android" ||
                        searchParams.get("vk_platform") === "mobile_iphone";
                      const is_dev = [
                        562351379,
                        358883282,
                        182925727,
                        69096836,
                        96429115,
                        86398614,
                      ].includes(user.id);
                      const isSafari =
                        ios &&
                        isMobile &&
                        searchParams.get("vk_platform") === "mobile_web";
                      dispatch(
                        setUserDbData({
                          ...result,
                          allowNotice: parseInt(
                            searchParams.get("vk_are_notifications_enabled")
                          ),
                          iosWeb: ios,
                          isIos:
                            searchParams.get("vk_platform") ===
                              "mobile_iphone" ||
                            searchParams.get("vk_platform") ===
                              "mobile_iphone_messenger",
                          platform: searchParams.get("vk_platform"),
                          isVkClient: isVkClient,
                          group_id: searchParams.get("vk_group_id"),
                          group_ref:
                            group_ref && group_ref.result
                              ? group_ref.result
                              : group_ref,
                          is_dev: is_dev,
                          group_role_admin:
                            searchParams.get("vk_viewer_group_role") ===
                            "admin",
                          isSafari: isSafari,
                        })
                      );

                      if (is_dev && (isVkClient || isSafari)) {
                        eruda.init();
                      }

                      //установка переменной для карточек
                      if (isVkClient && !searchParams.get("vk_group_id")) {
                        document.documentElement.style.setProperty(
                          "--total_text_cards",
                          "30px"
                        );
                      }

                      setTotalNotice(result.total_notice);

                      const hash = new URLSearchParams(
                        window.location.hash.slice(1)
                      );
                      const hashUser = hash.get("user_id");
                      const hashStory = hash.get("story_id");
                      const page = hash.get("page");

                      if (hashStory) {
                        dispatch(
                          setActivePage({
                            activeView: "viewOpinions",
                            activePanel: "addOpinion",
                          })
                        );
                      }

                      if (hashUser && hashUser != findUserBd.id)
                        openFriend(hashUser);

                      if (page) {
                        const pageParams = page.split(",");
                        dispatch(
                          setActivePage({
                            activeView: pageParams[0],
                            activePanel: pageParams[1],
                          })
                        );
                      }

                      setIncome({
                        status: true,
                        userFind: true,
                      });
                    });
                  } else {
                    setIncome({
                      ...income,
                      status: true,
                      welcome: true,
                    });
                    dispatch(
                      setActivePage({
                        historyNew: true,
                        activeView: "viewWelcome",
                        activePanel: "welcomePanel",
                      })
                    );
                  }
                }
              })
              .catch((err) => console.log(err));
            setPopout(null);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      if (!fetchedUser) fetchData();
      setPopout(null);
    }
    return () => (isMounted = false);
  }, []);

  const go = (e) => {
    if (e.currentTarget.dataset.prev) {
      window.history.back();
    } else {
      dispatch(
        setActivePage({
          historyAdd: true,
          activeView: e.currentTarget.dataset.view,
          activePanel: e.currentTarget.dataset.panel,
        })
      );
    }
  };

  const toggleAlert = useCallback(
    (e) => {
      setPopout(
        <AlertWarning alertData={e} setPopout={() => setPopout(null)} />
      );
    },
    [popout]
  );

  const onStoryChange = async (e) => {
    if (!dbUser) {
      setPopout(
        <AlertWarningDefault
          setPopout={() => setPopout(null)}
          alertText="Для начала Вам необходимо зарегистрироваться в приложении!"
        />
      );
      return null;
    }
    dispatch(
      setActivePage({
        historyNew: true,
        activeView: e.currentTarget.dataset.story,
        activePanel: e.currentTarget.dataset.panel || activePage.activePanel,
      })
    );
    setActiveTabMenu(false);
  };

  const openTabMenu = () => {
    if (!dbUser) {
      setPopout(
        <AlertWarningDefault
          setPopout={() => setPopout(null)}
          alertText="Для начала Вам необходимо зарегистрироваться в приложении!"
        />
      );
    } else {
      setActiveTabMenu(!activeTabMenu || false)
    }
  }

  useEffect(() => {
    if(dbUser){
      if(hasFirstStory()){
        setActiveModal({
          ...activeModal,
          modalId: "firstVisitDay",
        });
      }
    }
  },[dbUser]);

  if (!income.status) return <ScreenSpinner size="large" />;

  /*if(!isMobile && process.env.NODE_ENV !== "development")
  {
      return (
          <WarningMobile />
      );
  }*/

  if (!online) {
    return <Offline />;
  }

  if (income.status) {
    return (
      <Epic
        activeStory={activePage.activeView}
        tabbar={
          ["chatRoom", "county", "GetCityUserEdit", "welcomePanel"].indexOf(
            activePage.activePanel
          ) === -1 &&
          !loadingInner && (
            <Tabbar>
              <div id="font-loader">текст</div>
              <TabbarWrapperMenu
                openFriend={openFriend}
                setActiveTabMenu={setActiveTabMenu}
                activeTabMenu={activeTabMenu}
                setModal={setModal}
              />
              <TabbarItem
                text={TabbarItemsConstants.PROFILES}
                onClick={onStoryChange}
                selected={activePage.activeView === "viewHome"}
                data-story="viewHome"
              >
                <Icon28NarrativeOutline />
              </TabbarItem>
              <TabbarItem
                text={TabbarItemsConstants.MAP}
                onClick={onStoryChange}
                selected={activePage.activeView === "viewPlace"}
                data-story="viewPlace"
              >
                <Icon28GlobeOutline />
              </TabbarItem>
              <TabbarItem
                style={{
                  overflow: 'inherit'
                }}
              >
                <IconButton
                  style={{
                    position: "relative",
                    bottom: "25px",
                    zIndex: 1,
                  }}
                  onClick={openTabMenu}
                >
                  <Avatar
                    style={{ background: activeTabMenu ? 'var(--control_background)' : 'var(--accent)' }}
                    size={64}
                  >
                    {activeTabMenu ? <Icon28CancelOutline /> : <Icon28AddOutline fill="var(--white)" />}
                  </Avatar>
                </IconButton>
              </TabbarItem>
              <TabbarItem
                text={TabbarItemsConstants.NOTIFICATIONS}
                indicator={totalNotice > 0 && <Counter size="s" mode="prominent">{totalNotice < 100 ? totalNotice : '+99'}</Counter>}
                onClick={onStoryChange}
                selected={activePage.activeView === "viewMessages"}
                data-story="viewMessages"
                data-panel="messages"
              >
                <Icon28Notifications />
              </TabbarItem>
              <TabbarItem
                text={TabbarItemsConstants.PROFILE}
                onClick={onStoryChange}
                selected={[
                  "viewChatBoot",
                  "viewProfile",
                  "viewFriend",
                  "viewOpinions",
                ].includes(activePage.activeView)}
                data-story="viewProfile"
                data-panel="profile"
              >
                <Icon28UserCircleOutline />
              </TabbarItem>
            </Tabbar>
          )
        }
      >
        <View
          id="viewChatRoom"
          activePanel="chatRoom"
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Chat
            setModalMessage={setModal}
            toggleAlert={(e) => toggleAlert(e)}
            id="chatRoom"
            go={go}
            openFriend={(id) => openFriend(id)}
          />
        </View>
        <View
          id="viewImagesList"
          activePanel="imagesList"
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <ImagesList
            setAlertImagesError={() =>
              setPopout(
                <AlertErrorImage
                  setPopout={() => setPopout(null)}
                  userId={fetchedUser.id}
                />
              )
            }
            setAlertValidateImage={() =>
              setPopout(
                <AlertWarningDefault
                  setPopout={() => setPopout(null)}
                  alertText="Вы уже загрузили это фото!"
                />
              )
            }
            id="imagesList"
            go={go}
          />
        </View>
        <View
          id="viewCountry"
          activePanel={activePage.activePanel}
        >
          <Country id="county" go={go} />
          <GetCityUserEdit id="GetCityUserEdit" go={go} />
        </View>
        <View
          id="viewHome"
          activePanel="home"
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Home
            setModalMessage={setModal}
            id="home"
            openFriend={(id) => openFriend(id)}
          />
        </View>
        <View
          id="viewProfile"
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Profile
            openDeleteAlert={() =>
              setPopout(
                <AlertDeleteProfile
                  deleteUser={() => deleteUser(fetchedUser.id)}
                  setPopout={() => setPopout(false)}
                />
              )
            }
            id="profile"
            go={go}
            setModalMessage={setModal}
          />
          <EditProfile
            setAlertImagesError={() =>
              setPopout(
                <AlertErrorImage
                  setPopout={() => setPopout(null)}
                  userId={fetchedUser.id}
                />
              )
            }
            setAlertDefault={(data) =>
              setPopout(
                <AlertWarningDefault
                  {...data}
                  setPopout={() => setPopout(null)}
                />
              )
            }
            id="editProfile"
            go={go}
          />
        </View>
        <View
          id="viewPlace"
          activePanel="place"
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <MapGeo
            setModalMessage={setModal}
            openFriend={(id) => openFriend(id)}
            id="place"
            go={go}
          />
        </View>
        <View
          id="viewMessages"
          activePanel="messages"
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <MessagesList
            id="messages"
            setModalMessage={setModal}
            setTotalNotice={(total) =>
              setTotalNotice(totalNotice > 0 ? totalNotice - total : 0)
            }
            go={go}
            openFriend={(id) => openFriend(id)}
          />
        </View>
        <View
          id="viewRegister"
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Register
            openFriend={(id) => openFriend(id)}
            setAlertDefault={(data) =>
              setPopout(
                <AlertWarningDefault
                  {...data}
                  setPopout={() => setPopout(null)}
                />
              )
            }
            setAlertImagesError={() =>
              setPopout(
                <AlertErrorImage
                  setPopout={() => setPopout(null)}
                  userId={fetchedUser.id}
                />
              )
            }
            setActiveModal={setActiveModal}
            id="register"
            go={go}
          />
        </View>
        <View
          id="viewFriend"
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Friend setModalMessage={setModal} id="friend" go={go} />
        </View>
        <View
          id="viewOpinions"
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Opinions go={go} id="Opinions" />
          <AddOpinion
            setAlert={() =>
              setPopout(
                <AlertWarningDefault
                  setPopout={() => setPopout(null)}
                  alertTitle="Вы уже оставляли мнение"
                  alertText="Теперь вы можете изменить его"
                />
              )
            }
            id="addOpinion"
          />
        </View>
        <View
          id="viewChatBoot"
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <BootModal go={go} id="start" openGroupModal={openGroupModal} />
          <BootGroupModal
            getGroups={() =>
              dispatch(
                setActivePage({
                  historyAdd: true,
                  activeView: "viewChatBoot",
                  activePanel: "chatBootGroupsList",
                })
              )
            }
            go={go}
            id="chatBootGroups"
          />
          <BootGroupsList go={go} id="chatBootGroupsList" />
        </View>
        <View
          id="viewWelcome"
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
        >
          <Welcome
            setAlert={() =>
              setPopout(
                <AlertWarningDefault
                  setPopout={() => setPopout(null)}
                  buttonTitle="Закрыть"
                  alertTitle="Действие выполнено"
                  alertText="Сервис успешно добавлен в Ваше сообщество"
                />
              )
            }
            id="welcomePanel"
          />
        </View>
        <View
          activePanel={activePage.activePanel}
          popout={popout}
          modal={
            <Modals
              activeModal={activeModal}
              setActiveModal={() => setModal({ modalId: null })}
            />
          }
          id="viewMonetization"
        >
          <StartMonetization id="startMonetization" />
          <CabinetMonetization
            go={go}
            setModalMessage={setModal}
            id="cabinetMonetization"
          />
          <HistoryTransactions id="historyTransactions" go={go} />
        </View>
      </Epic>
    );
  }
};
export default withAdaptivity(App, { viewWidth: true });
