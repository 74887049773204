import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { setActivePage, setActiveRoom } from "../actions/actions";
import { getDateTimeZone } from "../helpers/Helper";
import {
  Button,
  Cell,
  Counter,
  Group,
  List,
  Panel,
  PanelHeader,
  Placeholder,
  Separator, Snackbar,
  Spinner,
  Tabs,
  TabsItem
} from "@vkontakte/vkui";
import Icon28Message from "@vkontakte/icons/dist/28/message";
import Icon56Users3Outline from "@vkontakte/icons/dist/56/users_3_outline";
import { getChats, getNoticeTotalDb, loadGuests, loadSympathies } from "../components/RequestApi";
import { vipConstants } from "../constants/vipConstants";
import {
  Icon28NotificationAddOutline,
  Icon28UserStarBadgeSlashOutline,
  Icon56LikeOutline,
  Icon56MessageReadOutline
} from "@vkontakte/icons";
import { appConstants } from "../constants/appConstants";
import { applyAllowMessagesFromGroup } from "../userFunctions/VkFunctions";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import { AllowMessagesBanner } from "../components/AllowMessagesBanner";
import { MessageButtonAllow } from "../components/MessageButtonAllow";

const mapStateToProps = (state = {}) => {
  return { ...state };
};

const MessagesList = ({ id, go, fetchedUser, dispatch, dbUser, openFriend, setTotalNotice, socket,setModalMessage,loadingInner }) => {
  const [activeTab, setActiveTab] = useState('messages');
  const [loading, setLoading] = useState(true);
  const [chatList, setChatList] = useState({
    totalNew: 0,
    results: []
  });
  const [sympathies, setSympathies] = useState({
    totalNew: 0,
    results: []
  });
  const [guests, setGuests] = useState({
    totalNew: 0,
    results: []
  });
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getNoticeTotalDb(fetchedUser.id)
          .then(result => {
            if (result) {
              if (isMounted){
                setSympathies({ ...sympathies, totalNew: result.sympathies });
                setGuests({ ...guests, totalNew: result.total_views });
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      getChats(fetchedUser.id)
          .then(result => {
            if (result) {
              if (isMounted) setChatList(result);
            }
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
          });
      setActiveTab('messages');

        socket.on("income notice", (data) => {
            if(data.sympathy){
                loadSympathies(fetchedUser.id)
                    .then(result => {
                        if (result) {
                            setSympathies({
                                ...sympathies,
                                results: result.results,
                                totalNew: sympathies.totalNew + 1
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                 });
            }
            if(data.guest){
                loadGuests(fetchedUser.id)
                    .then(result => {
                        if (result) {
                            setGuests({
                                ...guests,
                                results: result.results,
                                totalNew: guests.totalNew + 1
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        })

      socket.on("notice new message", (data) => {
        if(data.status){
          getChats(fetchedUser.id)
              .then(result => {
                if (result) {
                  if (isMounted) setChatList(result);
                }
              })
              .catch(err => {
                console.log(err);
              });
        }
      })
    }
    return () => (isMounted = false);
  }, []);

  const openRoom = useCallback(async (room, newMessages) => {
    if (newMessages) {
      setTotalNotice(dbUser.notice_info.total_new_messages);
    }
    await dispatch(setActiveRoom(room));
    dispatch(setActivePage({
        historyAdd: true,
      activeView: 'viewChatRoom',
      activePanel: 'chatRoom'
    }))
  }, [dispatch, go, dbUser]);

  // функции для табов
  const getMessages = useCallback(async () => {
    setLoading(() => (true))
    await getChats(fetchedUser.id)
      .then(result => {
        if (result) {
          setChatList(() => (result));
        }
      })
      .catch(err => {
        console.log(err);
      });
    setActiveTab('messages');
    setLoading(() => (false))
  }, [fetchedUser.id]);

  const getSympathies = useCallback(async () => {
      setLoading(() => (true))
    await loadSympathies(fetchedUser.id)
      .then(result => {
        if (result) {
          setSympathies(() => (result));
          setTotalNotice(dbUser.notice_info.sympathies);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setActiveTab('sympathies');
      setLoading(() => (false))
  }, [fetchedUser.id]);

  const getGuests = useCallback(async () => {
      setLoading(() => (true))
    await loadGuests(fetchedUser.id)
      .then(result => {
         if(dbUser.userInfo.vip)
         {
             if(result) {
                 setGuests(() => (result));
                 setActiveTab('guests');
               setTotalNotice(dbUser.notice_info.total_views);
             }
         }
         else
         {
             setModalMessage({
                 title: vipConstants.WARNING_ACCESS_IS_CLOSED,
                 description: vipConstants.TEXT_GUEST,
                 icon: <Icon56Users3Outline />,
                 modalId: 'AlertVip'
             });
         }
      })
      .catch(err => {
        console.log(err);
      });
      setLoading(() => (false))
  }, [fetchedUser.id, dbUser]);

  const openSnack = useCallback(
    (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={
            snack.type === "danger" ? (
              <Icon16Clear fill="var(--like_text_tint)" />
            ) : (
              <Icon16CheckCircle fill="var(--field_valid_border)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      );
    },
    [snackbar]
  );

  const toggleNotice = async () => {
    const callbackResolve = () => openSnack({ text: "Уведомления включены!" });
    const callbackReject = () => openSnack({
      text: "Ошибка, повторите попытку!",
      type: "danger"
    });

    await applyAllowMessagesFromGroup(callbackResolve, callbackReject);
  };

  return (
    <Panel
        id={id}
    >
      <PanelHeader
        theme="light"
      >Уведомления</PanelHeader>
      {
          loadingInner  ? (
                  <>
                      <Spinner style={{marginTop: '50%'}} size='medium' />
                  </>
              )
              :
        loading
          ?
          (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '50% 0' }}>
              <h5 style={{ color: 'var(--loader_track_value_fill)' }}>Загружаем Ваши уведомления</h5>
              <br/>
              <Icon28Message className="top-animation" fill="var(--dynamic_blue)"/>
            </div>
          )
          :	(
            <>
              <Group>
                <Tabs type="buttons">
                  <TabsItem
                    onClick={() => getMessages()}
                    data-tab="messages"
                    selected={activeTab === 'messages'}
                    after={<Counter>{chatList.totalNew}</Counter>}
                  >
											Чаты
                  </TabsItem>
                  <TabsItem
                    onClick={() => getGuests()}
                    data-tab="guests"
                    selected={activeTab === 'guests'}
                    after={<Counter>{guests.totalNew}</Counter>}
                  >
                      Просмотры
                  </TabsItem>
                  <TabsItem
                    onClick={() => getSympathies()}
                    data-tab="sympathies"
                    selected={activeTab === 'sympathies'}
                    after={<Counter>{sympathies.totalNew}</Counter>}
                  >
											Симпатии
                  </TabsItem>
                </Tabs>
              </Group>
              {activeTab === 'messages' && (
                <Group title="Сообщения" description={chatList.results.length > 0 && 'Выберите переписку, чтобы продолжить'}>
                  {
                    !!chatList.results.length
                      ?
                        <>
                          {!dbUser.userInfo.allow_messages && <AllowMessagesBanner callBack={toggleNotice} />}
                          <List>
                            {
                              chatList.results.map(item => (
                                    <div key={item.room_id.toString()}>
                                      <Cell
                                        onClick={() => openRoom(item, item.new_messages > 0)}
                                        description={item.is_sender === 1 ? 'Вы: ' + item.last_message : item.last_message}
                                        before={
                                          <div style={{
                                            width: 50,
                                            height: 50,
                                            borderRadius: '50%',
                                            margin: '.5em',
                                            position: 'relative',
                                            background: `url(${item.image}) center/cover no-repeat`
                                          }} >
                                            {item.online === 1 && <div className='online'></div>}
                                          </div>
                                        }
                                        asideContent={
                                          <div style={{
                                            textAlign: 'center',
                                            fontSize: '.75em'
                                          }}>{
                                            getDateTimeZone(item.date_modified)
                                          }
                                            <br/>
                                            {item.new_messages > 0 && <div className="counterMessage">{item.new_messages}</div>}
                                          </div>
                                        }
                                      >
                                        <div style={{ color: item.sex === 2 ? 'var(--dynamic_blue)' : 'var(--dynamic_red)' }}>
                                          {item.first_name}, {item.age}
                                        </div>
                                      </Cell>
                                      <Separator/>
                                    </div>
                                  )
                              )
                            }
                          </List>
                        </>
                      :
                      <Placeholder
                        header="У Вас пока нет ни одного чата!"
                        icon={<Icon56MessageReadOutline fill="var(--dynamic_blue)" />}
                        action={!dbUser.userInfo.allow_messages && <MessageButtonAllow callBack={toggleNotice} />}
                      >
                        {!dbUser.userInfo.allow_messages && appConstants.TEXT_ALLOW_MESSAGES_INVALID}
                      </Placeholder>
                  }
                </Group>)}
              {activeTab === 'guests' &&
								<Group title="Просмотры">
                  {
                    !!guests.results.length
                        ?
                      <>
                        {!dbUser.userInfo.allow_messages && <AllowMessagesBanner callBack={toggleNotice} />}
                        <List>
                          {
                            guests.results.map((item, index) => {
                                const nowDate = new Date();
                                const guestDate = new Date(item.date_visit);
                                const totalSeconds = Math.floor((nowDate - guestDate) / 1000);
                                let textDate = null;
                                if (totalSeconds < 60) {
                                  textDate = totalSeconds + ' сек. назад';
                                } else if (totalSeconds < 3600) {
                                  textDate = Math.floor(totalSeconds / 60) + ' мин. назад';
                                } else if (totalSeconds < 86400) {
                                  textDate = 'сегодня в ' + getDateTimeZone(item.date_visit,'H:mm');
                                } else {
                                  textDate = Math.floor(totalSeconds / 86400) + ' дн. назад';
                                }
                                return (
                                  <Cell
                                    onClick={() => openFriend(item.id)}
                                    key={index}
                                    description={<small>{textDate}</small>}
                                    before={
                                      <div style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: '50%',
                                        margin: '.5em',
                                        position: 'relative',
                                        background: `url(${item.image}) center/cover no-repeat`
                                      }}>
                                        {item.online === 1 && <div className='online'></div>}
                                      </div>
                                    }
                                  >
                                    {item.first_name}, {item.age}
                                  </Cell>
                                );
                              }
                            )
                          }
                        </List>
                      </>
                        :
                      <Placeholder
                        header="Вашу анкету никто не смотрел!"
                        icon={<Icon28UserStarBadgeSlashOutline height={56} width={56} fill="var(--dynamic_blue)" />}
                        action={!dbUser.userInfo.allow_messages && <MessageButtonAllow callBack={toggleNotice} />}
                      >
                        {!dbUser.userInfo.allow_messages && appConstants.TEXT_ALLOW_MESSAGES_INVALID}
                      </Placeholder>
                  }
								</Group>}
              {activeTab === 'sympathies' &&
								<Group title="Симпатии">
                  {
                    !!sympathies.results.length
                      ?
                      <>
                        {!dbUser.userInfo.allow_messages && <AllowMessagesBanner callBack={toggleNotice} />}
                        <List>
                          {
                            sympathies.results.map((item, index) => {
                              const nowDate = new Date();
                              const guestDate = new Date(item.date_create);
                              const totalSeconds = Math.floor((nowDate - guestDate) / 1000);
                              let textDate = null;
                              if (totalSeconds < 60) {
                                textDate = totalSeconds + ' сек. назад';
                              } else if (totalSeconds < 3600) {
                                textDate = Math.floor(totalSeconds / 60) + ' мин. назад';
                              } else if (totalSeconds < 86400) {
                                textDate = 'сегодня в ' + getDateTimeZone(item.date_create,'H:mm');
                              } else {
                                textDate = Math.floor(totalSeconds / 86400) + ' дн. назад';
                              }
                              return (
                                <Cell
                                  onClick={() => openFriend(item.id)}
                                  description={<small>{textDate}</small>}
                                  key={index}
                                  before={
                                    <div style={{
                                      width: 50,
                                      height: 50,
                                      borderRadius: '50%',
                                      margin: '.5em',
                                      position: 'relative',
                                      background: `url(${item.image}) center/cover no-repeat`
                                    }} >
                                      {item.online === 1 && <div className='online'></div>}
                                    </div>
                                  }
                                >
                                  {item.first_name}, {item.age}
                                </Cell>
                              );
                            })
                          }
                        </List>
                      </>
                      :
                      <Placeholder
                        header="У Вас пока нет симпатий!"
                        icon={<Icon56LikeOutline fill="var(--dynamic_blue)" />}
                        action={!dbUser.userInfo.allow_messages && <MessageButtonAllow callBack={toggleNotice} />}
                      >
                        {!dbUser.userInfo.allow_messages && appConstants.TEXT_ALLOW_MESSAGES_INVALID}
                      </Placeholder>
                  }
								</Group>}
            </>
          )
      }
      {snackbar}
    </Panel>
  );
};

export default connect(mapStateToProps)(React.memo(MessagesList));
