import React, { Fragment, useEffect, useState } from "react";
import {
	Avatar,
	Caption,
	Card,
	CardGrid,
	Group,
	Header,
	HorizontalCell,
	HorizontalScroll,
	PanelSpinner,
	Separator,
	Text
} from "@vkontakte/vkui";
import {
	Icon24CrownOutline,
	Icon28LikeOutline,
	Icon28MoneyCircleOutline,
	Icon28QuestionOutline,
	Icon56FavoriteOutline
} from "@vkontakte/icons";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import { setActivePage, setLoadingStatus } from "../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { postStoryOpinions } from "../helpers/Helper";
import { getTopUsers, saveStory } from "./RequestApi";
import { vipConstants } from "../constants/vipConstants";

const SIZE_AVATAR = 48;

export const TabbarWrapperMenu = ({activeTabMenu, setActiveTabMenu, openFriend, setModal}) => {
	const { dbUser } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [topUsers, setTopUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	const goToUser = (id) => {
		setActiveTabMenu(false)
		openFriend(id)
	}

	const openDonut = () => {
		setActiveTabMenu(false)
		setModal({
			title: vipConstants.DONUT_GET_TOP_USERS,
			description: vipConstants.GET_SUBSCRIBE,
			icon: <Icon56FavoriteOutline />,
			modalId: "AlertVip",
		})
	}

	const goMonetization = () => {
		if(dbUser.group_role_admin && dbUser.group_id)
			dispatch(setActivePage({
				historyAdd: true,
				activeView: 'viewMonetization',
				activePanel: 'cabinetMonetization'
			}))
		else
			dispatch(setActivePage({
				historyAdd: true,
				activeView: 'viewMonetization',
				activePanel: 'startMonetization'
			}))

		setActiveTabMenu(false)
	}

	const openOpinions = () => {
		dispatch(
			setActivePage({
				historyAdd: true,
				activeView: "viewOpinions",
				activePanel: "Opinions",
			})
		);
		setActiveTabMenu(false)
	}

	const callbackStory = (uid) => {
		saveStory({user_id: dbUser.userInfo.id, uid: uid})
			.then(() => {
				dispatch(setLoadingStatus(false))
			})
	}

	const postStory = async () => {
		dispatch(setLoadingStatus(true))
		const text = {
			title_top: 'Я ТЕБЕ НРАВЛЮСЬ?',
			title_bottom: '',
			description: 'симпатию',
		}
		await postStoryOpinions(dbUser, text, callbackStory, 'user_id');
	}

	useEffect( () => {
		if (activeTabMenu) {
			(async () => {
				const results = await getTopUsers();

				if (!!results.length) {
					setTopUsers(results);
				}

				setLoading(false);
			})();
		}
	}, [activeTabMenu]);

	return (
		activeTabMenu &&
		<div className={activeTabMenu ? "active-tab-menu" : "d-none"}>
			<Group header={<Header mode="secondary">Топ-анкеты г.{dbUser.userInfo.city_title}</Header>}>
				<HorizontalScroll showArrows getScrollToLeft={i => i - 120} getScrollToRight={i => i + 120}>
					<div style={{ display: "flex" }}>
						{!loading
							?
							<Fragment>
								{
									!dbUser.userInfo.vip &&
										<HorizontalCell
											onClick={openDonut}
											size="l">
											<div
												style={{
													backgroundColor: 'var(--dynamic_blue)',
													borderRadius: 10,
													position: 'relative',
												}}
											>
												<div
													style={{
														width: 72,
														height: 72,
														borderRadius: "50%",
														margin: "0.5em",
														padding: '4px 6px',
														background: `url(${dbUser.images.avatar.main}) center center / cover no-repeat`,
													}}
												/>
												<Avatar
													style={{
														backgroundColor: 'var(--dynamic_red)',
														position: 'absolute',
														right: 5,
														top: 5,
													}}
													size={32} >
													<Icon24CrownOutline width={20} height={20} fill="#FFFFFF" />
												</Avatar>
												<Caption style={{
													padding: '2px 6px 8px 6px',
													textAlign: "center", color: '#FFFFFF' }} level="1" weight="bold">Добавить<br/>себя</Caption>
											</div>
										</HorizontalCell>
								}

								{!!topUsers.length && topUsers.map((item, index) =>
										<HorizontalCell
											onClick={() => dbUser.userInfo.id !== item.id && goToUser(item.id)}
											size="l" key={index}
																		header={<div style={{ textAlign: "center" }}>{item.first_name}<br/>{item.age}
																		</div>}>
											<div
												style={{
													width: 72,
													height: 72,
													borderRadius: "50%",
													margin: "0.5em",
													background: `url('${item.image}') center center / cover no-repeat`,
												}}
											/>
										</HorizontalCell>
									)}
							</Fragment>
							:
							<PanelSpinner/>
						}
					</div>
				</HorizontalScroll>
			</Group>
			<CardGrid
				className='tab-menu-row'
				size="s"
			>
				<Card
					className='tab-menu-col'
					onClick={postStory}
				>
					<Div className="tab-menu-item"
							 style={{
								 background: 'var(--dynamic_red)'
							 }}
					>
							<Avatar
								style={{ background: "var(--background_content)" }}
								size={SIZE_AVATAR}
							>
								<Icon28LikeOutline fill="var(--dynamic_red)" />
							</Avatar>
						<Separator style={{ margin: '12px 0' }} />
						<Text>
							Собрать <b>симпатии</b> от друзей
						</Text>
					</Div>
				</Card>
				<Card
					className='tab-menu-col'
					onClick={openOpinions}
				>
					<Div className="tab-menu-item"
							 style={{
								 background: 'var(--dynamic_green)'
							 }}
					>
							<Avatar
								style={{ background: "var(--background_content)" }}
								size={SIZE_AVATAR}
							>
								<Icon28QuestionOutline fill="var(--dynamic_green)" />
							</Avatar>
						<Separator style={{ margin: '12px 0' }} />
						<Text>
							Собрать <b>мнения</b> о себе
						</Text>
					</Div>
				</Card>
				<Card
					className='tab-menu-col'
					onClick={goMonetization}
				>
					<Div className="tab-menu-item"
							 style={{
								 background: 'var(--dynamic_blue)'
							 }}
					>
							<Avatar
								style={{ background: "var(--background_content)" }}
								size={SIZE_AVATAR}
							>
								<Icon28MoneyCircleOutline fill="var(--dynamic_blue)" />
							</Avatar>
						<Separator style={{ margin: '12px 0' }} />
						<Text>
							Подключить <b>своё</b> сообщество
						</Text>
					</Div>
				</Card>
			</CardGrid>
		</div>
	)
}
