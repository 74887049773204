import React, {useEffect, useState} from "react";
import {RangeSlider} from "@vkontakte/vkui";

const Slider = ({userAge,setAge}) => {
    const [age,setAgeFirst] = useState(userAge);
    useEffect(() => {
        setAge({name: "age", data: age})
    },[age])
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <span>От: {age[0]}</span>
                <span>До: {age[1]}</span>
            </div>
            <RangeSlider
                defaultValue={[userAge[0],userAge[1]]}
                name="age"
                min={18}
                max={90}
                step={1}
                onChange={(e) => setAgeFirst(e)}
            />
        </>
    )
}
export default Slider
