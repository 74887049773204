import React from "react";
import {
  Button,
  Caption,
  Group,
  Header,
  Panel,
  PanelSpinner,
  Placeholder,
  Snackbar,
  Spacing,
  Tabs,
  TabsItem,
  Text,
  ViewWidth,
  withAdaptivity
} from "@vkontakte/vkui";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import { connect } from "react-redux";
import { getUserHref, loadCard, sendDislike, sendLike, sendNotification } from "../components/RequestApi";
import { setActivePage, setActiveRoom } from "../actions/actions";
import bridge from "@vkontakte/vk-bridge";
import Icon56HideOutline from "@vkontakte/icons/dist/56/hide_outline";
import { vipConstants } from "../constants/vipConstants";
import { Icon56CameraOffOutline } from "@vkontakte/icons";
import Icon24Story from "@vkontakte/icons/dist/24/story";
import Icon24Reply from "@vkontakte/icons/dist/24/reply";
import Icon28Users3Outline from "@vkontakte/icons/dist/28/users_3_outline";
import { getAuthToken, postStoryOpinions, postWall } from "../helpers/Helper";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import { appConstants } from "../constants/appConstants";
import autobind from "class-autobind";
import { MobileTinderCards } from "../components/MobileTinderCards";
import { DesktopTinderCards } from "../components/DesktopTinderCards";
import { applyAllowMessagesFromGroup } from "../userFunctions/VkFunctions";
import { CardListContext } from "../context/CardListContext";

const mapStateToProps = (state = {}) => {
  return { ...state };
};

class Home extends React.Component {
  constructor() {
    super(...arguments);
    autobind(this);
  }

  state = {
    loading: true,
    groupId: 0,
    fetchedCards: [],
    isMobile: this.props.viewWidth === ViewWidth.MOBILE,
    snackbar: null,
    touchStart: {
      x: 0,
      y: 0,
    },
    avatarLikeRef: React.createRef(),
    avatarDislikeRef: React.createRef(),
    stopClick: false,
    withoutUsersIds: [],
    tinderCards: [],
    tabRef: React.createRef(),
  };

  limit = 20;
  offset = 0;
  limitShowADS = 10;

  componentDidMount() {
    this.loadNewCards().then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.groupId !== prevState.groupId &&
      typeof prevState.groupId !== "undefined"
    ) {
      this.loadNewCards().then();
    }

    if (
      this.state.tabRef.current &&
      document.documentElement.style.getPropertyValue("--height_tab") !== "0px"
    ) {
      document.documentElement.style.setProperty(
        "--height_tab",
        this.state.tabRef.current.offsetHeight + "px"
      );
    }
  }

  isShowAds() {
    return (
      this.state.withoutUsersIds.length > 0 &&
      this.state.withoutUsersIds.length % this.limitShowADS === 0 &&
      this.props.dbUser.isVkClient &&
      !this.props.dbUser.group_id
    );
  }

  async loadNewCards() {
    if (this.props.dbUser.userInfo) {
      const users = await loadCard(
        this.props.dbUser.userInfo.id,
        this.state.groupId,
        this.limit,
        this.offset,
        this.state.withoutUsersIds
      );
        this.setState({
          fetchedCards: users.length > 0 ? users : [],
          tinderCards: users.length > 0 ? users.slice(0,5).reverse() : [],
        });
    }
  }

  changeActiveIndex(userId) {
    const users = this.state.fetchedCards.filter((value) => (value.id !== userId));

    this.setState((state) => ({
      withoutUsersIds: [...state.withoutUsersIds,userId],
      fetchedCards: users,
      tinderCards: users.slice(0,5).reverse(),
      stopClick: users.length === 0,
    }));

    if (this.isShowAds()) {
      bridge.send("VKWebAppShowNativeAds", {
        ad_format: "interstitial",
      }).then().catch();
    }

    if (users.length === 0) {
      this.loadNewCards().then(() => {
        this.setState({
          stopClick: false,
        })
      });
    }
  }

  async likeUser(userId) {
    const response = await sendLike({
      fetchedUserId: this.props.dbUser.userInfo.id,
      userInfoId: userId,
    });

    const findIndex = this.state.fetchedCards.findIndex((value) => value.id === userId);

    if (response && response.save) {
      if (
        this.state.fetchedCards[findIndex].online === 0 &&
        !this.state.fetchedCards[findIndex].mutual
      ) {
        await sendNotification({
          userId: userId,
          type: "SYMPATHIES",
        });
      }
      if (response.mutual) {
        await sendNotification({
          userId: userId,
          type: "MUTUAL_SYMPATHIES",
        });
        this.props.socket.emit("send notice", {
          sympathy: true,
          userId: userId,
        });
      }
    }
    await this.changeActiveIndex(userId);
  }

  async dislikeUser(userId) {
    await sendDislike({
      fetchedUserId: this.props.dbUser.userInfo.id,
      userInfoId: userId,
    }).then(() => {
      sendNotification({
        userId: userId,
        type: "DISLIKE",
      });
    });

    await this.changeActiveIndex(userId);
  }

  async onCardLeftScreen(direction, userId) {
    if (direction === "left") {
      await this.dislikeUser(userId);
    } else if (direction === "right") {
      await this.likeUser(userId);
    }
  }

  openChat(userId) {
    this.props.dispatch(
      setActiveRoom({
        toggleRoom: {
          userId: this.props.dbUser.userInfo.id,
          friendId: userId,
        },
      })
    );
    this.props.dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewChatRoom",
        activePanel: "chatRoom",
      })
    );
  }

  async openUserPage(userId) {
    let userData = Object();

    if (!this.props.dbUser.userInfo.vip) {
      this.props.setModalMessage({
        title: vipConstants.FRIEND_PAGE,
        description: vipConstants.GET_SUBSCRIBE,
        icon: <Icon56HideOutline />,
        modalId: "AlertVip",
      });
    } else if (this.props.dbUser.isIos || this.props.dbUser.iosWeb) {
      this.props.openFriend(userId);
    } else {
      userData = await getUserHref(userId);
      if (userData.vk_id) {
        if (typeof window.open == "function" && !this.props.dbUser.isIos)
          window.open(`https://vk.com/id${userData.vk_id}`);
        else window.location.href = `https://vk.com/id${userData.vk_id}`;
      }
    }
  }

  openSnack(snack) {
    if (this.state.snackbar) return;
    this.setState({
      snackbar: (
        <Snackbar
          layout="vertical"
          onClose={() => this.setState({ snackbar: null })}
          before={
            snack.type === "danger" ? (
              <Icon16Clear fill="var(--like_text_tint)" />
            ) : (
              <Icon16CheckCircle fill="var(--field_valid_border)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      ),
    });
  }

  callbackStory(result) {
    if (result) {
      this.openSnack({ text: "История опубликована." });
    }
  }

  async postStory() {
    const text = {
      title_top: "Я ТЕБЕ НРАВЛЮСЬ?",
      title_bottom: "",
      description: "симпатию",
    };
    await postStoryOpinions(this.props.dbUser, text, this.callbackStory);
  }

  async sendGroup() {
    this.setState({ loading: true });
    await bridge
      .send("VKWebAppAddToCommunity")
      .then((r) => {
        if (r.group_id) {
          this.openSnack({ text: "Сервис установлен в сообщество." });
        }
      })
      .catch((r) => {
        console.log(r);
      });
    this.setState({ loading: false });
  }

  async sendPostWall() {
    const resultToken = await getAuthToken(
      "photos,groups",
      this.props.fetchedUser,
      this.props.dispatch
    );
    if (resultToken) {
      this.setState({ loading: true });
      const result = await postWall(this.props.fetchedUser, this.props.dbUser);
      if (result) {
        this.openSnack({ text: "Запись успешно опубликована." });
      } else {
        this.openSnack({ text: "Что-то пошло не так.", type: "danger" });
      }
      this.setState({ loading: false });
    } else {
      this.openSnack({ text: appConstants.ERROR_GET_TOKEN, type: "danger" });
    }
  }

  resetVar() {
    document.documentElement.style.setProperty("--swipe_icon_scale", "0");
    document.documentElement.style.setProperty("--swipe_icon_left", "auto");
    document.documentElement.style.setProperty("--swipe_icon_right", "auto");
  }

  onTouchMove(e) {
    const touchPosition = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY,
    };

    const touchX = touchPosition.x - this.state.touchStart.x; //определяем разницу между первым касанием
    const ratioResult = (touchX / 100).toString(); //дробное соотношение 0.23

    if (touchPosition.x - window.innerWidth / 2 > 0) {
      this.state.avatarLikeRef.current.style.display = "flex";
      this.state.avatarDislikeRef.current.style.display = "none";
    } else {
      this.state.avatarLikeRef.current.style.display = "none";
      this.state.avatarDislikeRef.current.style.display = "flex";
    }

    document.documentElement.style.setProperty(
      "--swipe_icon_scale",
      ratioResult
    );

    document.documentElement.style.setProperty(
      "--swipe_icon_left",
      ratioResult < 0 ? "0" : "auto"
    );

    document.documentElement.style.setProperty(
      "--swipe_icon_right",
      ratioResult > 0 ? "0" : "auto"
    );
  }

  ontouchstart(e) {
    const touchPosition = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY,
    };
    this.setState({ touchStart: touchPosition });
  }

  onTouchEnd() {
    this.resetVar();
  }

  toggleNotice = async () => {
    const callbackResolve = () => this.openSnack({ text: "Уведомления включены!" });
    const callbackReject = () => this.openSnack({
      text: "Ошибка, повторите попытку!",
      type: "danger"
    });

    await applyAllowMessagesFromGroup(callbackResolve, callbackReject);
  };

  render() {
    const { id, openFriend, dbUser } = this.props;
    const {
      loading,
      groupId,
      isMobile,
      snackbar,
      avatarLikeRef,
      avatarDislikeRef,
      stopClick,
      tabRef,
      fetchedCards,
      tinderCards,
    } = this.state;

    return (
      <Panel className="panel-min-height-100" id={id}>
        <PanelHeader>Анкеты</PanelHeader>
        <CardListContext.Provider value={{callBack: this.toggleNotice}}>
          {loading && <PanelSpinner height={150} size="medium" />}
          {stopClick && (
            <PanelSpinner
              size="large"
              height="100%"
              className="swipe-preloader"
            />
          )}
          {!loading && (
            <Group
              header={
                dbUser.isVkClient &&
                !dbUser.group_id && (
                  <Header>
                    <Caption weight="regular" level={2}>
                      В данном разделе будет показываться реклама.
                    </Caption>
                  </Header>
                )
              }
            >
              {dbUser.group_id && dbUser.group_ref && (
                <Tabs getRootRef={tabRef}>
                  <TabsItem
                    onClick={() => {
                      this.setState({
                        groupId: dbUser.group_id,
                        fetchedCards: [],
                        withoutUsersIds: [],
                      });
                    }}
                    selected={groupId}
                  >
                    {dbUser.group_ref.name}
                  </TabsItem>
                  <TabsItem
                    onClick={() => {
                      this.setState({
                        groupId: 0,
                        fetchedCards: [],
                        withoutUsersIds: [],
                      });
                    }}
                    selected={!groupId}
                  >
                    Все анкеты
                  </TabsItem>
                </Tabs>
              )}
              {fetchedCards.length > 0 && (
                <div
                  onTouchMove={this.onTouchMove}
                  onTouchStart={this.ontouchstart}
                  onTouchEnd={this.onTouchEnd}
                >
                  {isMobile && <MobileTinderCards
                    fetchedCards={tinderCards}
                    openFriend={openFriend}
                    onCardLeftScreen={this.onCardLeftScreen}
                    openChat={this.openChat}
                    openUserPage={this.openUserPage}
                    avatarLikeRef={avatarLikeRef}
                    avatarDislikeRef={avatarDislikeRef}
                  />}

                  {!isMobile && (
                    <DesktopTinderCards
                      fetchedCards={tinderCards}
                      openFriend={openFriend}
                      onCardLeftScreen={this.onCardLeftScreen}
                      openChat={this.openChat}
                      openUserPage={this.openUserPage}
                    />
                  )}
                </div>
              )}
              {fetchedCards.length === 0 && (
                <Placeholder
                  icon={<Icon56CameraOffOutline />}
                  header="Ожидаем новые анкеты!"
                  action={
                    <React.Fragment>
                      <Button
                        stretched
                        onClick={this.postStory}
                        mode="primary"
                        before={<Icon24Story />}
                        size="l"
                      >
                        Поделиться в истории
                      </Button>
                      <Spacing />
                      <Button
                        stretched
                        onClick={this.sendPostWall}
                        mode="secondary"
                        size="l"
                        before={<Icon24Reply />}
                      >
                        Поделиться на стене
                      </Button>
                      <Spacing />
                      <Text weight="regular">или если Вы создатель группы</Text>
                      <Spacing />
                      <Button
                        stretched
                        onClick={this.sendGroup}
                        mode="secondary"
                        size="l"
                        before={<Icon28Users3Outline />}
                      >
                        Подключить в группу
                      </Button>
                    </React.Fragment>
                  }
                >
                  Для того чтобы было больше анкет в{" "}
                  <b>г.{dbUser.userInfo.city_title}</b>, Вы можете:
                </Placeholder>
              )}
            </Group>
          )}
        </CardListContext.Provider>
        {snackbar}
      </Panel>
    );
  }
}

export default connect(mapStateToProps)(
  React.memo(withAdaptivity(Home, { viewWidth: true }))
);
