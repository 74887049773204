import "core-js";
import "core-js/features/map";
import "core-js/features/set";
import React from "react";
import ReactDOM from "react-dom";
import mVKMiniAppsScrollHelper from "@vkontakte/mvk-mini-apps-scroll-helper";
import { Provider } from "react-redux";
//import * as Sentry from "@sentry/react";
//import { BrowserTracing } from "@sentry/tracing";
import { AdaptivityProvider, ConfigProvider } from "@vkontakte/vkui";

import "@vkontakte/vkui/dist/components.css";
import "@vkontakte/vkui/dist/vkui.css";
import "./css/style.css";

import App from "./App";
import storeRedux from "./store";
//import registerServiceWorker from './sw';

/*if (process.env.NODE_ENV !== "development") {
  const RELEASE = '0.1.0';
  Sentry.init({
    dsn: "https://3739e26e02574c88abd6b7314fb76491@o1312314.ingest.sentry.io/6593294",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: RELEASE,
  });
}*/

// Корневой элемент приложения
const root = document.getElementById('root');

// Вызываем хелпер, передавая в аргумент корневой элемент приложения
mVKMiniAppsScrollHelper(root);

// Если вы хотите, чтобы ваше веб-приложение работало в оффлайне и загружалось быстрее,
// расскомментируйте строку с registerServiceWorker();
// Но не забывайте, что на данный момент у технологии есть достаточно подводных камней
// Подробнее про сервис воркеры можно почитать тут — https://vk.cc/8MHpmT
//registerServiceWorker();

ReactDOM.render(
      <ConfigProvider>
        <AdaptivityProvider>
          <Provider store={storeRedux}>
            <App />
          </Provider>
        </AdaptivityProvider>
      </ConfigProvider>
    , root
);
