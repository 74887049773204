import React, { useState } from "react";
import { default as Fade } from "react-fade";
import Card from "./Card";

export const  DesktopTinderCards = (props) => {
	const { fetchedCards, openFriend, onCardLeftScreen, openChat, openUserPage} = props;
	const [fadeOut, setFadeout] = useState(null);

	const clickIcon = (direction, userId) => {
		setFadeout(userId);
		setTimeout(() => {
			onCardLeftScreen(direction, userId);
		}, 750);
	}

	const fadeDuration = 0.5;

	return (
		<div className="swipe-wrapper">
			{fetchedCards.map((user) => (
					<Fade
						key={user.id}
						out={fadeOut === user.id}
						duration={fadeDuration}
						style={{ visibility: fadeOut === user.id ? "hidden" : "visibility" }}
						className='swipe'
					>
						<Card
							openUserPage={() => openUserPage(user.id)}
							userData={user}
							likeUser={() =>
								clickIcon("right", user.id)
							}
							dislikeUser={() =>
								clickIcon("left", user.id)
							}
							openFriend={() => openFriend(user.id)}
							openChat={openChat}
						/>
					</Fade>
			))}
		</div>
	);
}
