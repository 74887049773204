'use strict';
export const historyMiddleware = store => next => action => {
    let state = store.getState()
    if(action.type === 'SET_ACTIVE_PAGE'){
        /*Fix History Data*/
        let history = state.history
        if(action.activePage.historyAdd){
            action.history = [...history,{
                activeView:  action.activePage.activeView,
                activePanel: action.activePage.activePanel
            }]
            if(action.history[0].activeView === 'viewRegister' && action.activePage.activeView === 'viewFriend'){
                action.history[0] = {
                    activeView:  'viewHome',
                    activePanel: 'home'
                }
                window.history.pushState({total: history.length}, `panel`)
            }
            window.history.pushState({total: history.length}, `panel`)
        }
        if(action.activePage.historyDelete){
            history.pop()
            action.activePage = history[history.length - 1]
            action.history = history
        }
        if(action.activePage.historyNew){
            action.history = [
                {
                    activeView:  action.activePage.activeView,
                    activePanel: action.activePage.activePanel
                }
            ]
        }
        window.total_history = action.history ? action.history.length : 0

        /*Fix Edit Data*/
        let editData = state.editData || null
        if(['imagesList','GetCityUserEdit','editProfile','county','register'].indexOf( action.activePage.activePanel ) === -1){
            editData = null
        }
        action.editData = editData
    }
    next(action);
}
