import React, { useCallback, useEffect, useState } from "react";
import {
  appToGroups,
  saveFindOptions,
  saveStory,
  saveUserData,
  setAllowNotificationDb
} from "../components/RequestApi";
import RangeSlider from "../components/RangeSlider";
import { connect } from "react-redux";
import {
  Avatar,
  Button,
  Caption,
  CardGrid,
  CellButton,
  Checkbox,
  ContentCard,
  FormItem,
  FormLayoutGroup,
  Group,
  IconButton,
  Link,
  List,
  Panel,
  PanelHeader,
  PanelHeaderContext,
  PanelSpinner,
  Separator,
  SimpleCell,
  Snackbar,
  Spacing,
  Subhead
} from "@vkontakte/vkui";
import Icon24Write from "@vkontakte/icons/dist/24/write";
import Icon28User from "@vkontakte/icons/dist/28/user";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import {
  Icon24NotificationCheckOutline,
  Icon24NotificationOutline,
  Icon28AdvertisingOutline,
  Icon28FavoriteCircleFillGreen,
  Icon28LikeOutline,
  Icon28MessageAddBadgeOutline,
  Icon28MessagesOutline,
  Icon28MoneyCircleOutline,
  Icon28PlaceOutline,
  Icon28Smiles2Outline,
  Icon28Users3Outline,
  Icon28UserSquareOutline
} from "@vkontakte/icons";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import { setActivePage, setLoadingStatus, setUserData, setUserDbData } from "../actions/actions";
import Radio from "@vkontakte/vkui/dist/components/Radio/Radio";
import bridge from "@vkontakte/vk-bridge";
import { CounterDown } from "../components/counterDown";
import { useListenerEvent } from "../components/useListenerEvent";
import { vipConstants } from "../constants/vipConstants";
import { appConstants } from "../constants/appConstants";
import { ServiceConstants } from "../constants/ServiceConstants";

import { postStoryOpinions } from "../helpers/Helper";
import { applyAllowMessagesFromGroup } from "../userFunctions/VkFunctions";

const mapStateToProps = (state = {}) => {
  return { ...state };
};

const Profile = ({
  id,
  go,
  fetchedUser,
  openDeleteAlert,
  dbUser,
  dispatch,
  setModalMessage,
  loadingInner,
}) => {
  const [snackbar, setSnackbar] = useState(null);
  const [contextOpened, setContextOpened] = useState(false);
  const [contextNotifications, setContextNotifications] = useState(false);
  const [notifications, setNotifications] = useState({
    messages: 1,
    sympathies: 1,
    mutual_sympathies: 1,
    guests: 1,
  });
  const [settings, setSettings] = useState({
    age: [dbUser.userInfo.age_start || 18, dbUser.userInfo.age_end || 60],
    sex: dbUser.userInfo.find_sex || 2,
  });
  const [loading, setLoading] = useState({
    status: true,
    main: true,
  });

  const setSettingsData = useCallback(
    (e) => {
      settings[e.name] = e.name === "age" ? e.data : parseInt(e.data);
      setSettings(settings);
    },
    [settings]
  );

  useListenerEvent("send story", (e) =>
    setLoading({ ...loading, ...e.detail })
  );

  const changeNotifications = (e) => {
    notifications[e.currentTarget.name] = e.currentTarget.checked ? 1 : 0;
    setNotifications(notifications);
    setNotifications(notifications);
  };

  const callbackStory = (uid) => {
    saveStory({user_id: dbUser.userInfo.id, uid: uid})
      .then(() => {
        dispatch(setLoadingStatus(false))
      })
  };

  const postStory = async () => {
    dispatch(setLoadingStatus(true))
    const text = {
      title_top: "Я ТЕБЕ НРАВЛЮСЬ?",
      title_bottom: "",
      description: "симпатию",
    };
    await postStoryOpinions(dbUser, text, callbackStory, 'user_id');
  };

  useEffect(() => {
    if (dbUser.notifications) {
      setNotifications(() => dbUser.notifications);
    }
    let isMounted = true;
    if (isMounted) {
      setLoading({ ...loading, status: false });
    }
    return () => (isMounted = false);
  }, []);

  const postWall = useCallback(
    async (e) => {
      setLoading({
        status: true,
        main: false,
      });
      if (fetchedUser.access_token) {
        //получаем главное фото
        await bridge
          .send("VKWebAppCallAPIMethod", {
            method: "photos.get",
            params: {
              album_id: "profile",
              owner_id: fetchedUser.id,
              count: 1,
              v: ServiceConstants.VERSION_API,
              rev: 1,
              access_token: fetchedUser.access_token,
            },
          })
          .then(async (result) => {
            let href = `https://vk.com/app${process.env.REACT_APP_APP_ID}`;

            let userImageId = result.response.items[0].id;

            if (result.response.items[0]) {
              href = `photo${fetchedUser.id}_${userImageId},https://vk.com/app${process.env.REACT_APP_APP_ID}#user_id=${dbUser.userInfo.id}`;
            }

            await bridge
              .send("VKWebAppShowWallPostBox", {
                message: "Знакомься, общайся, действуй!",
                copyright: `https://vk.com/app${process.env.REACT_APP_APP_ID}`,
                attachments: href,
              })
              .then((result) => {
                if (result) {
                  openSnack({ text: "Запись успешно опубликована." });
                }
              })
              .catch((er) => {
                console.log(er);
              });
            setLoading({ status: false, main: true });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await bridge
          .send("VKWebAppGetAuthToken", {
            app_id: parseInt(process.env.REACT_APP_APP_ID),
            scope: "photos",
          })
          .then((result) => {
            if (result.access_token) {
              fetchedUser.access_token = result.access_token;
              dispatch(setUserData(fetchedUser));
              postWall();
            }
          })
          .catch((error) => {
            setLoading({ status: false, main: true });
          });
      }
    },
    [loading, fetchedUser.access_token]
  );

  const saveSetting = useCallback(() => {
    saveFindOptions({ fetchedUserId: fetchedUser.id, settings: settings })
      .then((result) => {
        if (result) setContextOpened(false);
        dispatch(
          setUserDbData({
            ...dbUser,
            userInfo: {
              ...dbUser.userInfo,
              age_start: settings.age[0],
              age_end: settings.age[1],
              find_sex: settings.sex,
            },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, settings, dbUser]);

  const sendGroup = async () => {
    setLoading({
      status: true,
      main: false,
    });
    await bridge
      .send("VKWebAppAddToCommunity")
      .then(async (r) => {
        if (r.group_id) openSnack({ text: "Сервис установлен в сообщество." });
        await appToGroups(r.group_id);
      })
      .catch((r) => {
        console.log(r);
      });
    setLoading({
      status: false,
      main: true,
    });
  };

  const setAllowNotification = (allow) => {
    const data = {
      fetchedUserId: dbUser.userInfo.id,
      allow: allow,
      notifications: allow === 1 && notifications,
    };
    setAllowNotificationDb(data)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AllowNotifications = useCallback(() => {
    bridge
      .send("VKWebAppAllowNotifications")
      .then((result) => {
        if (result.result === true || result.result === 1) {
          dispatch(setUserDbData({ ...dbUser, allowNotice: true }));
          setAllowNotification(1);
          openSnack({ text: "Уведомления включены!", type: "success" });
        }
      })
      .catch((error) => {
        dispatch(setUserDbData({ ...dbUser, allowNotice: false }));
        setAllowNotification(0);
        openSnack({ text: "Уведомления выключены!", type: "danger" });
      });
    setContextNotifications(false);
  }, [dbUser.allowNotice]);

  const AllowGeo = useCallback(
    (statusGeoForm) => {
      const data = {
        fetchedUserId: fetchedUser.id,
        data: {
          allow_geo: statusGeoForm,
        },
      };
      saveUserData(data)
        .then((result) => {
          if (result) {
            dispatch(
              setUserDbData({
                ...dbUser,
                userInfo: {
                  ...dbUser.userInfo,
                  allow_geo: statusGeoForm,
                },
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [dbUser.userInfo]
  );

  const openSnack = useCallback(
    (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={
            snack.type === "danger" ? (
              <Icon16Clear fill="var(--like_text_tint)" />
            ) : (
              <Icon16CheckCircle fill="var(--field_valid_border)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      );
    },
    [snackbar]
  );

  const openChatBoot = () => {
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewChatBoot",
        activePanel: "start",
      })
    );
  };

  const openOpinions = () => {
    dispatch(
      setActivePage({
        historyAdd: true,
        activeView: "viewOpinions",
        activePanel: "Opinions",
      })
    );
  };

  const goMonetization = () => {
    if (dbUser.group_role_admin && dbUser.group_id)
      dispatch(
        setActivePage({
          historyAdd: true,
          activeView: "viewMonetization",
          activePanel: "cabinetMonetization",
        })
      );
    else
      dispatch(
        setActivePage({
          historyAdd: true,
          activeView: "viewMonetization",
          activePanel: "startMonetization",
        })
      );
  };

  const toggleNotice = async () => {
    const callbackResolve = () => openSnack({ text: "Уведомления включены!" });
    const callbackReject = () => openSnack({
      text: "Ошибка, повторите попытку!",
      type: "danger"
    });

    await applyAllowMessagesFromGroup(callbackResolve, callbackReject);
  };

  return (
    <Panel id={id}>
      <PanelHeader>Профиль</PanelHeader>

      {loadingInner ? (
        <PanelSpinner height={150} size="medium" />
      ) : fetchedUser && dbUser.userInfo && !loading.status && loading.main ? (
        <>
          <PanelHeaderContext
            opened={contextOpened}
            onClose={() => {
              setSettings({
                age: [
                  dbUser.userInfo.age_start || 18,
                  dbUser.userInfo.age_end || 60,
                ],
                sex: dbUser.userInfo.find_sex || 2,
              });
              setContextOpened(false);
            }}
          >
            <List>
              <FormLayoutGroup mode="horizontal">
                <FormItem top="Выберите пол">
                  <Radio
                    onChange={(e) =>
                      setSettingsData({
                        name: "sex",
                        data: e.currentTarget.value,
                      })
                    }
                    defaultChecked={settings.sex === 2}
                    name="sex"
                    value="2"
                  >
                    Муж.
                  </Radio>
                  <Radio
                    onChange={(e) =>
                      setSettingsData({
                        name: "sex",
                        data: e.currentTarget.value,
                      })
                    }
                    defaultChecked={settings.sex === 1}
                    name="sex"
                    value="1"
                  >
                    Жен.
                  </Radio>
                </FormItem>
              </FormLayoutGroup>
              <FormItem top="Выберите возраст">
                <RangeSlider
                  userAge={settings.age}
                  setAge={(e) => setSettingsData(e)}
                />
              </FormItem>
              <FormItem>
                <Button size="l" stretched onClick={() => saveSetting()}>
                  Сохранить
                </Button>
              </FormItem>
            </List>
          </PanelHeaderContext>

          <PanelHeaderContext
            opened={contextNotifications}
            onClose={() => setContextNotifications(false)}
          >
            <List>
              <FormItem>
                <Checkbox
                  name="messages"
                  onChange={(e) => changeNotifications(e)}
                  defaultChecked={notifications.messages === 1 ? true : false}
                >
                  Новые сообщения
                </Checkbox>
                <Checkbox
                  name="sympathies"
                  onChange={(e) => changeNotifications(e)}
                  defaultChecked={notifications.sympathies === 1 ? true : false}
                >
                  Новые взаимные симпатии
                </Checkbox>
                <Checkbox
                  name="mutual_sympathies"
                  onChange={(e) => changeNotifications(e)}
                  defaultChecked={
                    notifications.mutual_sympathies === 1 ? true : false
                  }
                >
                  Новые симпатии
                </Checkbox>
                <Checkbox
                  name="guests"
                  onChange={(e) => changeNotifications(e)}
                  defaultChecked={notifications.guests === 1 ? true : false}
                >
                  Новые гости
                </Checkbox>
              </FormItem>
              <FormItem>
                <Button
                  stretched
                  size="l"
                  mode="primary"
                  onClick={() => AllowNotifications()}
                >
                  Сохранить
                </Button>
              </FormItem>
            </List>
          </PanelHeaderContext>
          <Group separator="show">
            <SimpleCell
              disabled
              before={
                <div
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    margin: "0.5em",
                    position: "relative",
                    background: `url(${dbUser.images.avatar.main}) center center / cover no-repeat`,
                  }}
                />
              }
              after={
                <IconButton
                  onClick={go}
                  data-panel="editProfile"
                  data-view="viewProfile"
                >
                  <Avatar size={48}>
                    <Icon24Write fill="var(--dynamic_blue)" />
                  </Avatar>
                </IconButton>
              }
              description={
                <React.Fragment>
                  {dbUser.userInfo.condition_title}
                  <br />
                  <b>{dbUser.userInfo.city_title}</b>
                </React.Fragment>
              }
            >
              {fetchedUser.first_name}, {dbUser.userInfo.age}
            </SimpleCell>
            {!dbUser.isIos && (
              <Div>
                <Button
                  stretched
                  onClick={() => setModalMessage({ modalId: "modalPay" })}
                  size="l"
                  before={<Icon28FavoriteCircleFillGreen />}
                >
                  {dbUser.userInfo.vip
                    ? vipConstants.TEXT_EXTEND_SUBSCRIBE
                    : vipConstants.TEXT_BY_SUBSCRIBE}
                </Button>
              </Div>
            )}
          </Group>

          {dbUser.userInfo.vip && dbUser.userInfo.vip_time && (
            <Group>
              <CounterDown date={dbUser.userInfo.vip_time * 1000} />
              <Subhead className="text-center" weight="semibold">
                {vipConstants.TEXT_END_SUBSCRIBE}
              </Subhead>
            </Group>
          )}

          <Group>
            <CardGrid size="l">
              <ContentCard
                style={{
                  backgroundColor: dbUser.userInfo.allow_messages ? 'var(--dynamic_green)' : 'var(--dynamic_red)',
                  color: 'var(--button_primary_foreground)',
                }}
                subtitle={dbUser.userInfo.allow_messages ? 'Уведомления в личные сообщения!' : 'У вас не включены уведомления!'}
                header={
                <>
                  <Spacing />
                  <Caption weight="regular" level="1">Получай уведомления в личные <br/>сообщения ВКонтакте о симпатиях, <br/>диалогах и просмотрах анкет</Caption>
                </>
              }
                caption={
                <>
                  <Separator style={{ margin: '12px -16px' }} />
                  <Button
                    style={{
                      backgroundColor: 'var(--button_primary_foreground)',
                      color: 'var(--text_primary)',
                    }}
                    mode="secondary"
                    onClick={toggleNotice}
                    size="m" before={dbUser.userInfo.allow_messages ? <Icon24NotificationOutline /> : <Icon24NotificationCheckOutline />}>
                    {dbUser.userInfo.allow_messages ? 'Проверить уведомления' : 'Включить'}
                  </Button>
                </>
                }
              />
            </CardGrid>
          </Group>

          <Group
            separator="show">
            {dbUser.platform !== "mobile_web" && (
              <CellButton
                onClick={postStory}
                before={
                  <Icon28LikeOutline
                    style={{ marginRight: "10px" }}
                    fill="var(--dynamic_red)"
                  />
                }
              >
                <div style={{ color: "var(--text_primary)" }}>
                  Собрать симпатии от друзей
                </div>
              </CellButton>
            )}
            <CellButton
              onClick={() => postWall()}
              before={
                <Icon28AdvertisingOutline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_blue)"
                />
              }
            >
              <div style={{ color: "var(--text_primary)" }}>
                Поделиться профилем на стене
              </div>
            </CellButton>
            <CellButton
              onClick={goMonetization}
              before={
                <Icon28MoneyCircleOutline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_orange)"
                />
              }
            >
              <div style={{ color: "var(--text_primary)" }}>
                {dbUser.group_role_admin && dbUser.group_id
                  ? appConstants.TEXT_MONETIZATION_CABINET
                  : appConstants.TEXT_MONETIZATION}
              </div>
            </CellButton>
            <CellButton
              onClick={openChatBoot}
              before={
                <Icon28MessageAddBadgeOutline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_green)"
                />
              }
            >
              <div style={{ color: "var(--text_primary)" }}>
                Установить чат-бот в свою группу
              </div>
            </CellButton>
            <CellButton
              onClick={(e) => document.getElementById("link_chat_bot").click()}
              before={
                <Icon28MessagesOutline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_green)"
                />
              }
            >
              <Link
                id="link_chat_bot"
                style={{ color: "var(--text_primary)" }}
                href="https://vk.com/im?sel=-38390656"
                target="_blank"
              >
                Чат-бот знакомств
              </Link>
            </CellButton>
            <CellButton
              onClick={openOpinions}
              before={
                <Icon28Smiles2Outline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_violet)"
                />
              }
            >
              <div style={{ color: "var(--text_primary)" }}>Мнения обо мне</div>
            </CellButton>
            <CellButton
              onClick={() => setContextOpened(!contextOpened)}
              before={
                <Icon28UserSquareOutline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_red)"
                />
              }
            >
              <div style={{ color: "var(--text_primary)" }}>
                Настройка поиска анкет
              </div>
            </CellButton>
            <CellButton
              onClick={() => AllowGeo(dbUser.userInfo.allow_geo === 1 ? 0 : 1)}
              before={
                <Icon28PlaceOutline
                  style={{ marginRight: "10px" }}
                  fill="var(--dynamic_purple)"
                />
              }
            >
              <div style={{ color: "var(--text_primary)" }}>
                {dbUser.userInfo.allow_geo === 1
                  ? "Отключить показ метки"
                  : "Включить показ метки"}
              </div>
            </CellButton>
            <CellButton
              onClick={(e) =>
                document.getElementById("link_group").click()
              }
              before={
                <Icon28Users3Outline
                  style={{ marginRight: "10px" }}
                />
              }
            >
              <Link
                id="link_group"
                style={{ color: "var(--text_primary)" }}
                href="https://vk.com/znvkapps"
                target="_blank"
              >
                Официальная группа приложения
              </Link>
            </CellButton>
          </Group>
          <Group separator="show">
            <CellButton
              onClick={(e) =>
                document.getElementById("link_send_error").click()
              }
              mode="danger"
            >
              <Link
                id="link_send_error"
                style={{ color: "var(--destructive)" }}
                href="https://vk.com/im?sel=-160113110"
                target="_blank"
              >
                Сообщить об ошибке
              </Link>
            </CellButton>
            <CellButton onClick={openDeleteAlert} mode="danger">
              Удалить анкету
            </CellButton>
          </Group>
        </>
      ) : (
        <>
          {loading.main ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "50% 0",
              }}
            >
              <h5 style={{ color: "var(--loader_track_value_fill)" }}>
                Загружаем Ваш профиль
              </h5>
              <br />
              <Icon28User
                className="top-animation"
                fill="var(--dynamic_blue)"
              />
            </div>
          ) : (
            <PanelSpinner height={150} size="medium" />
          )}
        </>
      )}
      {snackbar}
    </Panel>
  );
};

export default connect(mapStateToProps)(React.memo(Profile));
