import React, {useEffect, useState } from 'react';
import {setEditData} from '../actions/actions'
import {Panel, PanelHeader, PanelHeaderBack, Group, List, Cell, PanelSpinner } from '@vkontakte/vkui';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import {connect} from 'react-redux'
import bridge from '@vkontakte/vk-bridge';
import {loadCountries} from "../components/RequestApi";

const mapStateToProps = (state = {}) => {
    return {...state};
};

const Country = ({ id, go, dispatch, editData, fetchedUser}) => {

    const [countries, setCountries] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        let isMounted = true;
        if(isMounted) {
            loadCountries(fetchedUser.id)
                .then((r) => {
                    setCountries(r);
                    setLoading(false);
                });
        }
        return () => {
            isMounted = false
        };
    },[])

    return (
        <Panel
            separator={false}
            id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={go} data-prev={true} />}
            >Выбор страны</PanelHeader>
            {
                loading ?
                    (
                        <PanelSpinner size='medium' />
                    )
                    :
                    (
                        <Group>
                            <List>
                                {countries.map((item, index) =>
                                    <Cell
                                        key={index}
                                        onClick={async () => {
                                            await dispatch(setEditData({
                                                ...editData,
                                                userInfo: {
                                                    ...editData.userInfo,
                                                    country_id: item.id,
                                                    country_title: item.title,
                                                    city_id: 0,
                                                    city_title: ''
                                                }
                                            }))
                                            window.history.back();
                                        }}
                                        asideContent={editData.userInfo.country_id === item.id ? <Icon24Done fill="var(--accent)" /> : null}
                                    >
                                        {item.title}
                                    </Cell>
                                )
                                }
                            </List>
                        </Group>
                    )
            }
        </Panel>
    )
}
export default connect(mapStateToProps)(React.memo(Country))