'use strict';
import moment from "moment-timezone";
import { getImageStoryOpinion } from "./CanvasStory";
import bridge from "@vkontakte/vk-bridge";
import { ServiceConstants } from "../constants/ServiceConstants";
import { setUserData } from "../actions/actions";
const UIDGenerator = require('uid-generator');
const uidGen = new UIDGenerator();

export function date2str(x, y){
    let z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? '0' : '') + z[v.slice(-1).slice(-2)]);
    });

    return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length);
    });
}
export function getDateTimeZone(innerDate, format = 'DD.MM.YYYY, H:mm', addOffset = true){
    const offset = -(new Date().getTimezoneOffset())
    if(addOffset){
        const dateOffset = moment(new Date(innerDate)).tz('UTC').utcOffset(offset).format(format)
        return dateOffset
    } else {
        return moment(innerDate).format(format)
    }
}

export const dateFormat = (UNIX_TIME, format = "DD.MM.YYYY, H:mm") => {
    return moment.unix(UNIX_TIME).format(format);
}

export const  postStoryOpinions = async (dbUser, text, callback, prefix = 'user') => {
        const image = await getImageStoryOpinion(dbUser, text);
        const uid = await uidGen.generate();

        if (prefix === 'user_id'){
          prefix = '#user_id=' + dbUser.userInfo.id;
        } else {
          prefix = '#story_id='+uid;
        }

        return await bridge.send("VKWebAppShowStoryBox", {
            "background_type": "image",
            "locked": true,
            "blob": image,
            "attachment": {
                text: 'open',
                type: 'url',
                url: 'https://vk.com/app'+process.env.REACT_APP_APP_ID + prefix
            }
        }).then(() => {
            callback(uid);
        }).catch(() => {
            callback(false);
        })
}

export const hasFirstStory = () => {
  let dateToFirstVisit = localStorage.getItem("zn_app_date_to_first_visit");
  let nowWeekDay = 0;

  if (dateToFirstVisit) {
    nowWeekDay = moment.unix(parseInt(dateToFirstVisit)).isoWeekday();
  }

  //const hasDiffDates = moment().diff(dateToFirstVisit,'days'); //разница в днях
  const hasDiffDates = nowWeekDay !== moment().isoWeekday();

  if (hasDiffDates) {
    localStorage.setItem(
      "zn_app_date_to_first_visit",
      moment().unix().toString()
    );
  }

  return hasDiffDates;
};

export const postWall = async (fetchedUser, dbUser) => {
  try {
    const images = await bridge.send("VKWebAppCallAPIMethod", {
      method: "photos.get",
      params: {
        album_id: "profile",
        owner_id: fetchedUser.id,
        count: 1,
        v: ServiceConstants.VERSION_API,
        rev: 1,
        access_token: fetchedUser.access_token,
      },
    });

    let userImageId = images.response.items[0].id,
      href = `photo${fetchedUser.id}_${userImageId},https://vk.com/app${process.env.REACT_APP_APP_ID}#user_id=${dbUser.userInfo.id}`,
      dataPost = {
        message: "Знакомься, общайся, действуй!",
        copyright: `https://vk.com/app${process.env.REACT_APP_APP_ID}`,
        attachments: href,
      };

    if (dbUser.group_id) {
      await bridge
        .send("VKWebAppCallAPIMethod", {
          method: "groups.getById",
          params: {
            fields: "is_admin",
            v: ServiceConstants.VERSION_API,
            group_id: parseInt(dbUser.group_id),
            access_token: fetchedUser.access_token,
          },
        }).then((response) => {
          if (parseInt(response.response[0].is_admin) === 1) {
            dataPost.attachments = `https://vk.com/app${process.env.REACT_APP_APP_ID}_-${dbUser.group_id}`;
            Object.assign(dataPost, {
              signed: 0,
              from_group: 1,
              owner_id: -dbUser.group_id,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return await bridge.send("VKWebAppShowWallPostBox", dataPost);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAuthToken = async (scope, fetchedUser, dispatch) => {
    return await bridge.send("VKWebAppGetAuthToken", {
          app_id: parseInt(process.env.REACT_APP_APP_ID),
          scope: scope,
      })
      .then((result) => {
          if (result.access_token) {
              fetchedUser.access_token = result.access_token;
              dispatch(setUserData(fetchedUser));
              return fetchedUser.access_token;
          }
      })
      .catch((error) => {
          return false;
      });
}
