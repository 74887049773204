import React, {useEffect} from "react";

export const useListenerEvent = (type,handler) => {
    useEffect(
        () => {
            window.addEventListener(type, handler);
            return () => {
                window.removeEventListener(type, handler);
            };
        },
        [type]
    );
};