import React, { useCallback, useEffect, useState } from "react";
import {
  setUserDataDb,
  setUserBlackList,
  appToGroups,
} from "../components/RequestApi";
import { connect } from "react-redux";
import {
  initialSocket,
  setUserData,
  setUserDbData,
  setActivePage,
  setEditData,
} from "../actions/actions";
import bridge from "@vkontakte/vk-bridge";
import {
  Panel,
  Placeholder,
  PanelHeader,
  Button,
  Div,
  PanelSpinner,
  Snackbar,
} from "@vkontakte/vkui";
import Icon56InfoOutline from "@vkontakte/icons/dist/56/info_outline";
import { Icon28UsersCircleFillYellow } from "@vkontakte/icons";
import Icon16Clear from "@vkontakte/icons/dist/16/clear";
import Icon16CheckCircle from "@vkontakte/icons/dist/16/check_circle";
import eruda from "eruda";
import { RegisterForm } from "../components/Form/RegisterForm";
import { REASON_BLACKLIST, ServiceConstants } from "../constants/ServiceConstants";

let isMobile = false;
const ios = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
if (ios) {
  isMobile = navigator.plugins.length === 0;
} else {
  isMobile = navigator.plugins.length === 0 && navigator.maxTouchPoints > 1;
}

const mapStateToProps = (state = {}) => {
  return { ...state };
};

const Register = ({
  id,
  fetchedUser,
  dispatch,
  editData,
  setAlertImagesError,
  setAlertDefault,
  openFriend,
}) => {
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isBlackList, setIsBlackList] = useState(
    fetchedUser.blacklist || false
  );
  const [worked, setWorked] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!editData) {
        dispatch(
          setEditData({
            userInfo: {
              ...fetchedUser,
              city_title: fetchedUser.city.title,
              city_id: fetchedUser.city.id,
              country_title: fetchedUser.country.title,
              country_id: fetchedUser.country.id,
              condition_id: ServiceConstants.DEFAULT_CONDITION_ID,
            },
            images: {
              other: Array(4).fill(null),
              avatar: {
                image_id: null,
                main: null,
                preview: null,
              },
            },
          })
        );
      }
    }
    return () => (isMounted = false);
  }, []);


  const registerNewUser = useCallback(async () => {
    setWorked(true);
    if (worked) return false;
        let dataReason = null;

        if (fetchedUser.ageVK < 18 && fetchedUser.ageVK !== false) {
          dataReason = Object.assign(fetchedUser, {
            reason: REASON_BLACKLIST.IS_YOUNG.type,
            reasonData: REASON_BLACKLIST.IS_YOUNG,
          })
        }

        if (editData.userInfo.sex === editData.userInfo.find_sex) {
          dataReason = Object.assign(fetchedUser, {
            reason: REASON_BLACKLIST.IS_SEX.type,
            reasonData: REASON_BLACKLIST.IS_SEX,
          })
        }

        if (dataReason) {
          setUserBlackList(dataReason)
            .then((result) => {
              if (result) {
                dispatch(
                  setUserData({
                    ...fetchedUser,
                    blackListData: {
                      title: dataReason.reasonData.title,
                      description: dataReason.reasonData.description,
                    },
                  })
                );
                setIsBlackList(() => true);
              }
            })
            .catch((err) => console.log(err));
        } else {
          setUserDataDb({
            data: {
              ...editData,
            },
          })
            .then(async (result) => {
              if (result) {
                const searchParams = new URLSearchParams(
                  window.location.search
                );
                dispatch(initialSocket());

                let group_ref = null;
                if (searchParams.get("vk_group_id")) {
                  group_ref = await bridge
                    .send("VKWebAppGetGroupInfo", {
                      group_id: parseInt(searchParams.get("vk_group_id")),
                    })
                    .catch(() => {
                      return false;
                    });
                }

                const isVkClient =
                  searchParams.get("vk_platform") === "mobile_android" ||
                  searchParams.get("vk_platform") === "mobile_iphone";
                const is_dev = [
                  562351379,
                  358883282,
                  182925727,
                  69096836,
                  96429115,
                ].includes(fetchedUser.id);
                const isSafari =
                  ios &&
                  isMobile &&
                  searchParams.get("vk_platform") === "mobile_web";
                dispatch(
                  setUserDbData({
                    ...result,
                    allowNotice: parseInt(
                      searchParams.get("vk_are_notifications_enabled")
                    ),
                    isIos:
                      searchParams.get("vk_platform") === "mobile_iphone" ||
                      searchParams.get("vk_platform") ===
                        "mobile_iphone_messenger",
                    platform: searchParams.get("vk_platform"),
                    group_id: searchParams.get("vk_group_id"),
                    isVkClient:
                      searchParams.get("vk_platform") === "mobile_android" ||
                      searchParams.get("vk_platform") === "mobile_iphone",
                    group_ref:
                      group_ref && group_ref.result
                        ? group_ref.result
                        : group_ref,
                    is_dev: is_dev,
                    group_role_admin:
                      searchParams.get("vk_viewer_group_role") === "admin",
                    isSafari: isSafari,
                  })
                );

                if (is_dev && (isVkClient || isSafari)) {
                  eruda.init();
                }

                //установка переменной для карточек
                if (
                  (searchParams.get("vk_platform") === "mobile_android" ||
                    searchParams.get("vk_platform") === "mobile_iphone") &&
                  !searchParams.get("vk_group_id")
                ) {
                  document.documentElement.style.setProperty(
                    "--total_text_cards",
                    "27px"
                  );
                }

                const hash = new URLSearchParams(window.location.hash.slice(1));
                const hashUser = hash.get("user_id");

                dispatch(setEditData(null));

                const hashStory = hash.get("story_id");
                const page = hash.get("page");

                if (hashUser && hashUser != result.userInfo.id) {
                  openFriend(hashUser);
                } else if (hashStory) {
                  dispatch(
                    setActivePage({
                      activeView: "viewOpinions",
                      activePanel: "addOpinion",
                    })
                  );
                } else if (page) {
                  const pageParams = page.split(",");
                  dispatch(
                    setActivePage({
                      activeView: pageParams[0],
                      activePanel: pageParams[1],
                    })
                  );
                } else {
                  dispatch(
                    setActivePage({
                      historyNew: true,
                      activeView: "viewHome",
                      activePanel: "home",
                    })
                  );
                }
              }
            })
            .catch((err) => console.log(err));
        }

    setWorked(false);
  }, [fetchedUser, editData, worked]);



  const openSnack = useCallback(
    (snack) => {
      if (snackbar) return;
      setSnackbar(
        <Snackbar
          layout="vertical"
          onClose={() => setSnackbar(null)}
          before={
            snack.type === "danger" ? (
              <Icon16Clear fill="var(--like_text_tint)" />
            ) : (
              <Icon16CheckCircle fill="var(--field_valid_border)" />
            )
          }
        >
          {snack.text}
        </Snackbar>
      );
    },
    [snackbar]
  );

  const sendGroup = async () => {
    setLoading(true);
    await bridge
      .send("VKWebAppAddToCommunity")
      .then(async (r) => {
        if (r.group_id) openSnack({ text: "Сервис установлен в сообщество." });
        await appToGroups(r.group_id);
      })
      .catch((r) => {
        console.log(r);
      });
    setLoading(false);
  };

  if (isBlackList) {
    return (
      <Panel id={id}>
        {loading ? (
          <PanelSpinner height={150} size="medium" />
        ) : (
          <Placeholder
            icon={<Icon56InfoOutline />}
            action={
              <Button
                size="l"
                onClick={() =>
                  bridge.send("VKWebAppClose", {
                    status: "success",
                    payload: { name: "on close" },
                  })
                }
              >
                Закрыть приложение
              </Button>
            }
          >
            <h1>
              {(fetchedUser.blackListData && fetchedUser.blackListData.title) ||
                ""}
            </h1>
            {(fetchedUser.blackListData &&
              fetchedUser.blackListData.description) ||
              ""}
            <Div>
              <Button
                mode="secondary"
                onClick={sendGroup}
                size="xl"
                before={<Icon28UsersCircleFillYellow />}
              >
                Добавить себе в группу
              </Button>
            </Div>
          </Placeholder>
        )}
        {snackbar}
      </Panel>
    );
  }

  if (editData) {
    return (
      <Panel id={id}>
        <PanelHeader>Регистрация</PanelHeader>
        <RegisterForm
          sendData={registerNewUser}
          setAlertImagesError={setAlertImagesError}
          setAlertDefault={setAlertDefault}
        />
      </Panel>
    );
  } else {
    return null;
  }
};
export default connect(mapStateToProps)(React.memo(Register));
