import { isMessagesFromGroupAllowed } from "../components/RequestApi";
import bridge from "@vkontakte/vk-bridge";
import { setUserDbData } from "../actions/actions";
import storeRedux from "../store";

export const applyAllowMessagesFromGroup = async (callBackResolve, callBackReject) => {
	const response = await isMessagesFromGroupAllowed();
	const data = storeRedux.getState();
	const { dbUser } = data;

	if (response) {
		callBackResolve();
	} else {
		bridge.send("VKWebAppAllowMessagesFromGroup", {
			"group_id": parseInt(process.env.REACT_APP_GROUP_ID), "key": "allowMessagesFromApp"
		}).then((response) => {
			if (response.result) {
				callBackResolve();
				storeRedux.dispatch(
					setUserDbData({
						...dbUser,
						userInfo: {
							...dbUser.userInfo,
							allow_messages: 1
						}
					})
				);
			}
		}).catch(() => {
			callBackReject();
		});
	}
};
