export const setUserData = (data) => ({
    type: "SET_USER_DATA",
    data: data,
})
export const initialSocket = () => ({
    type: "INITIAL_SOCKET"
})
export const setActiveRoom = (room) => ({
    type: "SET_ACTIVE_ROOM",
    room: room,
})
export const setUserDbData = (dbUser) => ({
    type: "SET_USER_DB_DATA",
    data: dbUser,
})
export const setUserGroups = (groups) => ({
    type: "SET_USER_GROUPS",
    data: groups,
})
export const setActivePage = (data) => ({
    type: "SET_ACTIVE_PAGE",
    activePage: data,
})
export const setLoadingStatus = (data) => ({
    type: "SET_LOADING",
    data: data,
})
export const setFriendId = (friendId) => ({
    type: "SET_FRIEND_ID",
    friendId: friendId,
})
export const setEditData = (data) => ({
    type: "SET_EDIT_DATA",
    data: data,
})
export const setTotalCards = (data) => ({
    type: "SET_TOTAL_CARDS",
    data: data,
})
