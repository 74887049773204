import React, { useEffect, useState } from "react";
import {
	Group,
	Caption,
	Div,
	Headline,
	Panel,
	PanelHeader,
	Title,
	List,
	Radio,
	Footer,
	Button,
	Snackbar, Placeholder
} from "@vkontakte/vkui";
import Icon16CheckCircle from '@vkontakte/icons/dist/16/check_circle';
import { getOpinion, saveOpinion } from "../components/RequestApi";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../actions/actions";
import Icon24Story from "@vkontakte/icons/dist/24/story";
import Icon56InfoOutline from "@vkontakte/icons/dist/56/info_outline";

export const AddOpinion = (props) => {
	const { id, setAlert } = props;
	const hashStory = new URLSearchParams(window.location.hash.slice(1)).get('story_id');
	const { fetchedUser, activePage } = useSelector(state => state);
	const dispatch = useDispatch();

	const [data, setData] = useState(null);
	const [opinion, setOpinion] = useState(null);
	const [snackbar,setSnackbar] = useState(null);

	useEffect(() => {
		if(hashStory){
			getOpinion(hashStory)
				.then(result => {
					if(result)
					{
						setData(result);
					}
				});
		}
		return () => {
			setData(null);
		}
	},[]);

	useEffect(() => {
		if(data)
		{
			if(data.opinion){
				setOpinion(data.options.filter(i => i.id === data.opinion));
				setAlert();
			} else {
				setOpinion(data.options[0]);
			}
		}
		return () => {
			setSnackbar(null );
		}
	},[data]);

	const openSnackbar = () => {
		if(snackbar)return;
		setSnackbar(
			<Snackbar
				layout="vertical"
				duration={1000}
				onClose={() => {
					dispatch(setActivePage({
						activeView: 'viewOpinions',
						activePanel: 'Opinions',
					}))
				}}
				before={<Icon16CheckCircle fill="var(--field_valid_border)" />}
			>
				Мнение опубликовано, для того чтобы получить ответ от пользователя, Вы должны опубликовать историю
			</Snackbar>
		);
	}

	const sendOpinion = () => {
		const query = {
			user_from: fetchedUser.id,
			user_to: data.user_id,
			opinion_id: opinion.id,
			opinion_type: opinion.type,
			story_uid: hashStory
		};
		saveOpinion(query)
			.then(r => {
					if(r){
						openSnackbar();
					}
			});
	}

	if (!data) {
		return (
			<Panel id={id}>
				<Placeholder
					icon={<Icon56InfoOutline />}
					header="Внимание!"
					action={
						<Button
							size="l"
							onClick={() => {
								dispatch(
									setActivePage({
										...activePage,
										prevView: "viewHome",
										prevPanel: "home",
										activeView: "viewHome",
										activePanel: "home",
									})
								);
							}}
						>
							Перейти на главную
						</Button>
					}
				>
					Пользователь не найден или удален.
				</Placeholder>
			</Panel>
		);
	} else {
		return (
			<Panel id={id}>
				<PanelHeader>Мнения</PanelHeader>
				{
					data &&
					<Group separator='show'>
						<Div className='text-center'>
							<div className='avatarAround' style={{backgroundImage: `url(${data.image})`, width: 125, height: 125}} />
						</Div>
						<Div className='text-center'>
							<Title weight='heavy' level={3} >{data.first_name}, {data.age}</Title>
							<Caption weight='medium' level={1}>г. {data.city_title}</Caption>
							<Headline weight='regular'>Как ты ко мне относишься?</Headline>
						</Div>
					</Group>
				}
				<Group separator='show'>
					<List>
						{
							data && data.options &&
							data.options.map((i, k) => <Radio
								key={k}
								onChange={() => setOpinion(i)}
								defaultChecked={data.opinion ? data.opinion === i.id : k === 0}
								value={i.id}
								name='type'
							>{i.value}</Radio>)
						}
					</List>
					<Div>
						<Button
							before={<Icon24Story  />}
							mode='secondary'
							size='l'
							onClick={sendOpinion}>
							Отправить
						</Button>
					</Div>
				</Group>
				<Footer>
					Ваше отношение к человеку станет видимым,в случае, если человек оставит такое же мнение для Вас.
				</Footer>
				{snackbar}
			</Panel>
		);
	}
}
